import { Button, ThreePeopleCircle } from '@allinfra-ltd/allinfra-design-system'
import { useTranslation } from 'react-i18next'
import { Card } from 'src/components'

interface CreateOrganisationCardProps {
  onButtonClick: () => void
}

export default function CreateOrganisationCard({
  onButtonClick,
}: CreateOrganisationCardProps) {
  const { t } = useTranslation()

  return (
    <>
      <Card classes="bg-slate-100 border border-slate-200">
        <div className="flex flex-col justify-center items-center text-center m-auto w-96 align-middle">
          <ThreePeopleCircle />
          <h2 className="text-gray-900 text-lg font-bold my-4">
            {t('organizations.createOrganisation.title')}
          </h2>
          <p className="text-gray-500 text-sm font-normal mb-4">
            {t('organizations.createOrganisation.content')}
          </p>
          <Button
            type="button"
            classes="mt-4 w-full text-sm font-bold"
            onClick={onButtonClick}
          >
            {t('organizations.createOrganisation.actionButton')}
          </Button>
        </div>
      </Card>

      <p className="p-8 text-gray-500 text-sm">
        {t('organizations.createOrganisation.outerContent')}{' '}
        <a
          className="text-blue-500 font-bold"
          href="https://www.soas.ac.uk/cedep-demos/000_P531_MRD_K3736-Demo/unit1/page_14.htm"
        >
          {t('organizations.createOrganisation.outerAction')}
        </a>
      </p>
    </>
  )
}
