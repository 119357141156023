import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { UseQueryOptions, useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { getSingleDealQuery } from 'src/api/routes'
import { getKeyOfDealTypeData } from 'src/pages/Deals/utils'
import { Deal, DealStage } from 'src/types'

interface UseSingleDealQueryParams {
  id: number
  dealStage: DealStage
  options?: Partial<
    Omit<
      UseQueryOptions<unknown, unknown, Deal, (string | number)[]>,
      'queryKey' | 'queryFn'
    >
  >
}

export function useSingleDealQuery({
  id,
  dealStage,
  options,
}: UseSingleDealQueryParams) {
  const keyOfDealTypeData = getKeyOfDealTypeData(dealStage)
  const qb = RequestQueryBuilder.create()
  const queryString = qb
    .setJoin([
      {
        field: 'token',
      },
      {
        field: 'createdBy',
        select: ['id', 'firstName', 'lastName', 'updatedAt', 'organizationId'],
      },
      {
        field: keyOfDealTypeData,
        select: ['id', 'generalInfo', 'eligibleInvestors'],
      },
      {
        field: `${keyOfDealTypeData}.generalInfo`,
      },
      {
        field: `${keyOfDealTypeData}.specificDetails`,
      },
      {
        field: `${keyOfDealTypeData}.eligibleInvestors`,
      },
      {
        field: `${keyOfDealTypeData}.eligibleInvestors.eligibleInvestor`,
      },
      {
        field: `${keyOfDealTypeData}.eligibleInvestors.eligibleInvestor.currentWallet`,
      },
    ])
    .query()

  return useQuery(
    [queryKeys.singleDeal, id],
    () => getSingleDealQuery({ id, queryString }),
    { enabled: !!dealStage, ...options }
  )
}
