import { apiUrl } from 'src/api'
import { RecoverResponse, SignUpParam } from 'src/types'

import { api } from '../axios-base-instance'

export interface LoginPayload {
  email: string
  password: string
  code?: string
}

export interface AuthSignUpTokenRes {
  recoveryCodes: string[]
}

export const postSignUp = (token: string, body: SignUpParam) =>
  api.post(`${apiUrl.signUp}/${token}`, body).then(({ data }) => data)

export interface AuthSecretTokenRes {
  secret: string
  qrCodeImage: string
}

export const getSecret = (token: string) =>
  api
    .get<AuthSecretTokenRes>(`${apiUrl.twoFactorAuthSecret}/${token}`)
    .then(({ data }) => data)

export function login(payload: LoginPayload) {
  return api.post(apiUrl.login, payload)
}

export function logout() {
  return api.post(apiUrl.logout)
}

export function requestForgotPassword(email: string) {
  return api.post(apiUrl.forgotPassword, { email })
}

export function requestPasswordReset(password: string, token: string) {
  return api.post(`${apiUrl.resetPassword}/${token}`, { password })
}

export function requestRecover(email: string, password: string, code: string) {
  return api.post<RecoverResponse>(apiUrl.recoverAccount, {
    email,
    password,
    code,
  })
}

export function postResetTwoFactor(
  token: string,
  secret: string,
  code: string
) {
  return api
    .post(`${apiUrl.resetTwoFactor}/${token}`, { secret, code })
    .then(({ data }) => data)
}
