export const queryKeys = {
  loggedInUser: 'user-me',
  activeUsers: 'active-users',
  pendingUsers: 'pending-users',
  disabledUsers: 'disabled-users',
  orgUsers: 'org-users',
  avaliableRoles: 'avaliable-roles',
  roles: 'roles',
  myOrganization: 'my-organization',
  organizations: 'organizations',
  organizationsMembers: 'organizations-members',
  dealsList: 'deals-list',
  singleDeal: 'single-deal',
  dealStats: 'deal-stats',
  dealsCurrency: 'deals-currency',
  dealsRanking: 'deals-ranking',
  dealsCouponPeriod: 'coupon-period',
  dealsTransactions: 'deals-transactions',
  approvals: 'approvals',
  approvalUpdateStatus: 'approval-update-status',
  apiKeys: 'api-keys',
  apiKeyCreate: 'api-key-create',
  apiKeyRevoke: 'api-key-revoke',
}
