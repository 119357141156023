import { UseQueryOptions, useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { getDealStats } from 'src/api/routes'
import { DealStats } from 'src/types'

interface UseDealStatsQueryParams {
  id: number
  options?: Partial<
    Omit<
      UseQueryOptions<unknown, unknown, DealStats, (string | number)[]>,
      'queryKey' | 'queryFn'
    >
  >
}

export function useDealStatsQuery({ id, options }: UseDealStatsQueryParams) {
  return {
    ...useQuery([queryKeys.dealStats, id], () => getDealStats({ id }), options),
  }
}
