import { MediaAvatarBase } from '@allinfra-ltd/allinfra-design-system'
import { ReactNode, isValidElement } from 'react'

type Props = {
  primaryContent: ReactNode
  secondaryContent: ReactNode
  avatar: ReactNode
}

function UserDetailsAvatar({
  primaryContent,
  secondaryContent,
  avatar,
}: Props) {
  return (
    <MediaAvatarBase
      avatar={avatar}
      primaryContent={
        isValidElement(primaryContent) ? (
          primaryContent
        ) : (
          <p className="text-gray-900 text-sm truncate w-42">
            {primaryContent}
          </p>
        )
      }
      secondaryContent={
        isValidElement(secondaryContent) ? (
          secondaryContent
        ) : (
          <p className="text-gray-500 text-sm truncate w-42">
            {secondaryContent}
          </p>
        )
      }
    />
  )
}

export default UserDetailsAvatar
