import { useEffect, useRef } from 'react'
import { AnyTypeOfFunction } from 'src/types'

interface UseIntervalProps {
  callback: AnyTypeOfFunction
  delay: number | null
}

function useInterval({ callback, delay }: UseIntervalProps) {
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }

    return undefined
  }, [delay])
}
export default useInterval
