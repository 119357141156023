import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { postOrganizationInvite } from 'src/api/routes'
import { InviteRequestBody } from 'src/types/invite'

const createOrgInvitation =
  (orgId: number) => async (payload: InviteRequestBody) => {
    const { data } = await postOrganizationInvite(orgId, payload)
    return data
  }

export function useOrgInvitationMutation(orgId: number) {
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError<any>, InviteRequestBody, unknown>(
    createOrgInvitation(orgId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.orgUsers)
      },
    }
  )
}
