import { useEffect } from 'react'
import { callAll } from 'src/utils'

import useBoolean from './useBoolean'
import useCounter from './useCounter'
import useInterval from './useInterval'

interface UseCountdownProps {
  initial?: number
  interval?: number
  isIncrement?: boolean
  stopLimit?: number
  onEnd?: () => void
  onTick?: (count: number) => void
}

function useCountdown({
  initial = 0,
  interval = 1000,
  isIncrement = false,
  stopLimit = 0,
  onEnd = () => {},
  onTick = () => {},
}: UseCountdownProps) {
  const {
    count,
    increment,
    decrement,
    reset: resetCounter,
  } = useCounter(initial)

  const { value: isRunning, setTrue: start, setFalse: stop } = useBoolean(false)

  useEffect(() => {
    onTick(count)
    if (count <= stopLimit) {
      onEnd()
      stop()
    }
  }, [count])
  const reset = callAll(stop, resetCounter)

  useInterval({
    callback: isIncrement ? increment : decrement,
    delay: isRunning ? interval : null,
  })

  return {
    isRunning,
    count,
    start,
    stop,
    reset,
  }
}
export default useCountdown
