import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { apiUrl, publicApi } from 'src/api/api-paths'

interface QueueItem {
  resolve: any
  reject: any
}

interface TokenData {
  accessToken: string
  refreshToken: string
}

interface QueueRequestConfig extends AxiosRequestConfig {
  _retry: boolean
  _queued: boolean
}

interface QueueError extends AxiosError {
  config: QueueRequestConfig
}

const isUnauthorized = (error: QueueError) =>
  error.response && error.response.status === 401

export const isPublic = (url: string) =>
  publicApi.includes(url) ||
  publicApi.some((p) => url.startsWith(p.concat('/')))

const isPublicApi = (error: QueueError) =>
  error.config.url && isPublic(error?.config?.url)

const isProcessed = (error: QueueError) =>
  // eslint-disable-next-line no-underscore-dangle
  error.config._queued || error.config._retry

const shouldNotIntercept = (error: QueueError) =>
  !isUnauthorized(error) || isPublicApi(error) || isProcessed(error)

const setTokenData = (tokenData: TokenData) => {
  window.localStorage.setItem(
    'authToken',
    JSON.stringify(tokenData.accessToken)
  )
  window.localStorage.setItem(
    'refreshToken',
    JSON.stringify(tokenData.refreshToken)
  )
}

const handleTokenRefresh = (refreshToken: string) => {
  return axios
    .post(apiUrl.refresh, null, {
      headers: {
        Authorization: `Bearer ${JSON.parse(refreshToken)}`,
      },
    })
    .then(({ data }) => data)
}

const onRefreshTokenError = () => {
  window.localStorage.removeItem('authToken')
  window.localStorage.removeItem('refreshToken')
  window.location.reload()
}

const attachTokenToRequest = (request: QueueRequestConfig, token: string) => {
  if (request?.headers) {
    request.headers.Authorization = `Bearer ${token}`
  }
}

const setRefreshTokenInterceptor = (axiosClient: AxiosInstance) => {
  let isRefreshing = false
  let failedQueue: Array<QueueItem> = []

  const processQueue = (
    error: QueueError | null,
    token: string | null = null
  ) => {
    // process queue
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })

    failedQueue = []
  }

  const pushFailedQueue = (error: QueueError, config: QueueRequestConfig) => {
    return new Promise<string>((resolve, reject) => {
      // push to queue
      failedQueue.push({ resolve, reject })

      // eslint-disable-next-line no-underscore-dangle, no-param-reassign
      config._queued = true
    })
      .then((token) => {
        // resolve
        attachTokenToRequest(config, token)
        return axiosClient.request(config)
      })
      .catch(() => {
        // reject
        return Promise.reject(error)
      })
  }

  const interceptor = (error: QueueError) => {
    const refreshToken = window.localStorage.getItem('refreshToken')

    if (!refreshToken || shouldNotIntercept(error)) {
      return Promise.reject(error)
    }

    const originalRequestConfig = error.config

    if (isRefreshing) {
      return pushFailedQueue(error, originalRequestConfig)
    }

    // eslint-disable-next-line no-underscore-dangle
    originalRequestConfig._retry = true

    // assume will not get another token
    isRefreshing = true

    // is not refreshing try to get new token
    return handleTokenRefresh(refreshToken)
      .then((tokenData) => {
        setTokenData(tokenData)
        attachTokenToRequest(originalRequestConfig, tokenData.accessToken)
        // resolve the queue
        processQueue(null, tokenData.accessToken)
        return axiosClient.request(originalRequestConfig)
      })
      .catch((err) => {
        // reject the queue
        processQueue(err, null)
        onRefreshTokenError()
        return Promise.reject(error)
      })
      .finally(() => {
        isRefreshing = false
      })
  }

  axiosClient.interceptors.response.use(undefined, interceptor)
}

export default setRefreshTokenInterceptor
