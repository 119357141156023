import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import {
  Approval,
  ApprovalActionsContextValue,
  ApprovalActionsState,
} from 'src/types'

const ApprovalActionsContext =
  createContext<ApprovalActionsContextValue | null>(null)

const initialState = {
  isViewingApprovalDetails: false,
}

export const ApprovalActionsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [state, dispatch] = useReducer(
    (s: ApprovalActionsState, a: Partial<ApprovalActionsState>) => ({
      ...s,
      ...a,
    }),
    initialState
  )

  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  )

  return (
    <ApprovalActionsContext.Provider value={value}>
      {children}
    </ApprovalActionsContext.Provider>
  )
}

export const useApprovalActions = () => {
  const context = useContext(ApprovalActionsContext)

  if (!context) {
    throw new Error(
      'useApprovalActions must be used within a ApprovalActionsProvider'
    )
  }

  const { state, dispatch } = context

  const toggleViewApprovalDetails = useCallback(
    () =>
      dispatch({ isViewingApprovalDetails: !state.isViewingApprovalDetails }),
    [state, dispatch]
  )

  const setSelectedApprovalData = useCallback(
    (selectedApprovalData: Approval) => dispatch({ selectedApprovalData }),
    [dispatch]
  )

  const reset = useCallback(() => dispatch(initialState), [dispatch])

  return {
    state,
    selectedApprovalData: state.selectedApprovalData,
    isViewingApprovalDetails: state.isViewingApprovalDetails,
    dispatch,
    toggleViewApprovalDetails,
    setSelectedApprovalData,
    reset,
  }
}
