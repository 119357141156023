import { BadgeColor } from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import {
  getDealStatusLabelAndColor,
  getDealTypeData,
} from 'src/pages/Deals/utils'
import {
  CreateDealFlatDataKeys as DataKeys,
  Deal,
  DealDetailDataMapValue,
  DealState,
  FormattedDealDetails,
  GeneralInfoDataMap,
  SpecificDetailsDataMap,
} from 'src/types'

function formatGeneralInfoData(deal: Deal) {
  const {
    type,
    createdBy: { firstName, lastName },
  } = deal
  const { generalInfo } = getDealTypeData(deal)

  const enrichedGeneralInfo = {
    ...generalInfo,
    type,
    dealIssuer: `${firstName || ''} ${lastName || ''}`.trim(),
  }

  const generalInfoDataMap: GeneralInfoDataMap = {
    tickerSymbol: {
      key: `${t('deals.dealDetailsPreview.tickerSymbol')}`,
      id: DataKeys.TickerSymbol,
    },
    currency: {
      key: `${t('deals.dealDetailsPreview.currency')}`,
      id: DataKeys.Currency,
    },
    type: {
      key: `${t('deals.dealDetailsPreview.type')}`,
      id: DataKeys.Type,
    },
    dealIssuer: {
      key: `${t('deals.dealDetailsPreview.dealIssuer')}`,
      id: DataKeys.DealIssuer,
    },
    anticipatedIssuanceDate: {
      key: `${t('deals.dealDetailsPreview.anticipatedIssuanceDate')}`,
      id: DataKeys.AnticipatedIssuanceDate,
    },
  }

  const generalInfoData: FormattedDealDetails = Object.entries(
    enrichedGeneralInfo
  ).reduce((acc, [key, value]) => {
    const currentMapEntry = generalInfoDataMap[key]

    if (currentMapEntry) {
      return {
        ...acc,
        [key]: {
          key: currentMapEntry.key,
          value,
          id: currentMapEntry.id,
        },
      }
    }

    return acc
  }, {})

  return generalInfoData
}

const formatSpecificDetails = (deal: Deal) => {
  const { specificDetails } = getDealTypeData(deal)

  const specificDetailsDataMap: SpecificDetailsDataMap = {
    incrementAmountSize: {
      key: `${t('deals.dealDetailsPreview.incrementAmountSize')}`,
      id: DataKeys.IncrementAmountSize,
    },
    negativePledge: {
      key: `${t('deals.dealDetailsPreview.negativePledge')}`,
      id: DataKeys.NegativePledge,
    },
    tenor: {
      key: `${t('deals.dealDetailsPreview.tenor')}`,
      id: DataKeys.Tenor,
    },
    coupon: {
      key: `${t('deals.dealDetailsPreview.coupon')}`,
      id: DataKeys.Coupon,
    },
    couponPeriod: {
      key: `${t('deals.dealDetailsPreview.couponPeriod')}`,
      id: DataKeys.CouponPeriod,
    },
    ranking: {
      key: `${t('deals.dealDetailsPreview.ranking')}`,
      id: DataKeys.Ranking,
    },
    baseIssueSize: {
      key: `${t('deals.dealDetailsPreview.baseIssueSize')}`,
      id: DataKeys.BaseIssueSize,
    },
    maxIssueSize: {
      key: `${t('deals.dealDetailsPreview.maxIssueSize')}`,
      id: DataKeys.MaxIssueSize,
    },
    minInvestSize: {
      key: (
        <div>
          <div>{`${t(
            'deals.dealDetailsPreview.minInvestSize.primaryText'
          )}`}</div>
          <div className="text-xs text-gray-400">
            {`${t('deals.dealDetailsPreview.minInvestSize.secondaryText')}`}
          </div>
        </div>
      ),
      id: DataKeys.MinInvestSize,
    },
  }
  const specificDetailsData: FormattedDealDetails = Object.entries(
    specificDetails
  ).reduce((acc, [key, value]) => {
    const currentMapEntry: DealDetailDataMapValue | undefined =
      specificDetailsDataMap[key]
    if (currentMapEntry) {
      return {
        ...acc,
        [key]: {
          key: currentMapEntry.key,
          value,
          id: currentMapEntry.id,
        },
      }
    }

    return acc
  }, {})

  return specificDetailsData
}

const formatSmartContractDetails = (deal: Deal) => {
  const { token } = deal
  const smartContractDetailsData: FormattedDealDetails = {
    address: {
      key: `${t('deals.dealDetailsPreview.address')}`,
      value: <span className="text-blue-500">{token?.address}</span>,
    },
  }

  return smartContractDetailsData
}

export function formatDealDetails(deal: Deal) {
  return {
    generalInfo: formatGeneralInfoData(deal),
    specificDetails: formatSpecificDetails(deal),
    smartContractDetails: formatSmartContractDetails(deal),
  }
}

export const getStatusValueAndColor = (
  status: DealState = DealState.Unknown
) => {
  const textStylesInventory = {
    [BadgeColor.Disabled]: 'text-gray-500',
    [BadgeColor.Success]: 'text-emerald-500',
    [BadgeColor.Warning]: 'text-amber-500',
    [BadgeColor.Danger]: 'text-red-500',
    [BadgeColor.Info]: 'text-blue-500',
    default: '',
  }

  const { label, color } = getDealStatusLabelAndColor(status)

  return (
    <span className={textStylesInventory[color] || textStylesInventory.default}>
      {label}
    </span>
  )
}
