import { InputBase, InputBaseProps } from '@allinfra-ltd/allinfra-design-system'
import React, { ReactNode } from 'react'

export interface TrailingAddOnInputProps extends Partial<InputBaseProps> {
  addOn?: ReactNode
}

export default React.forwardRef<
  HTMLInputElement | null,
  TrailingAddOnInputProps
>(function TrailingAddOnInput({ addOn, ...props }, ref) {
  return (
    <InputBase
      {...props}
      ref={ref}
      trailingIcon={
        addOn ? (
          <div className="absolute inset-y-0 right-0 pr-2 z-10 flex items-center pointer-events-none">
            <span className="text-gray-900 bg-white px-3.5 py-2.5 border rounded-md">
              {addOn}
            </span>
          </div>
        ) : null
      }
    />
  )
})
