import decodeJWT, { JwtPayload } from 'jwt-decode'
import { createContext, useCallback, useContext, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { LoginPayload, login, logout } from 'src/api/routes'
import { useLocalStorageState } from 'src/hooks'
import useAsync from 'src/hooks/useAsync'
import { callAll } from 'src/utils'

const AuthContext = createContext<any>(null)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const {
    run: runLogin,
    data: loginData,
    isLoading: isLoadingLoginRequest,
    isError: isErrorOnLogin,
    reset: resetLogin,
  } = useAsync()
  const queryClient = useQueryClient()

  const [authTokenInLocalStorage, setAuthTokenInLocalStorage] =
    useLocalStorageState('authToken', '')

  const clearCache = () => queryClient.clear()

  const [refreshTokenInLocalStorage, setRefreshTokenInLocalStorage] =
    useLocalStorageState('refreshToken', '')
  const resetToken = () => {
    setTokens(null, null)
  }

  const setTokens = (authToken: string | null, refreshToken: string | null) => {
    setAuthTokenInLocalStorage(authToken)
    setRefreshTokenInLocalStorage(refreshToken)
  }

  const signIn = useCallback(
    (payload: LoginPayload) =>
      runLogin(login(payload)).then(
        (resp) => setTokens(resp.data.accessToken, resp.data.refreshToken),
        resetToken
      ),
    [login]
  )

  const signOut = useCallback(() => {
    logout().then(callAll(resetLogin, resetToken, clearCache))
  }, [logout, resetLogin, resetToken, clearCache])
  const register = useCallback(() => {}, [])

  const isLoggedIn = useMemo(() => {
    if (!refreshTokenInLocalStorage) return false

    try {
      const payload: JwtPayload = decodeJWT(refreshTokenInLocalStorage)

      if (!payload || !payload.exp) return false

      const expirationTimeInSeconds = payload.exp * 1000
      const now = new Date()
      return now.getTime() < expirationTimeInSeconds
    } catch (error) {
      console.error(error)
      return false
    }
  }, [refreshTokenInLocalStorage])

  const value = useMemo(
    () => ({
      signIn,
      signOut,
      loginData,
      register,
      isLoadingLoginRequest,
      isErrorOnLogin,
      isLoggedIn,
      authToken: authTokenInLocalStorage,
      refreshToken: refreshTokenInLocalStorage,
    }),
    [
      signIn,
      signOut,
      loginData,
      register,
      authTokenInLocalStorage,
      refreshTokenInLocalStorage,
      isLoadingLoginRequest,
      isErrorOnLogin,
      isLoggedIn,
    ]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined)
    throw new Error('useAuth must be used within an AuthProvider')

  return context
}
