import {
  Button,
  Modal,
  ModalOpenButton,
} from '@allinfra-ltd/allinfra-design-system'
import { useTranslation } from 'react-i18next'
import { Organization } from 'src/types'
import { coerceIntoExternalLinkFormat } from 'src/utils'

import { EditOrganisationModalContent } from './Modals'

function SectionTitle({
  orgData,
  isEditable = false,
}: {
  orgData?: Organization
  isEditable: boolean
}) {
  const { t } = useTranslation()

  return (
    <div>
      <h1>
        {t('organizations.sectionHeader.title')}
        {isEditable && orgData ? (
          <Modal key="Edit-Organisation">
            <ModalOpenButton>
              <Button
                variant="secondary"
                size="small"
                classes="m-4 font-bold text-blue-500"
              >
                {`${t('organizations.editOrganisationModal.openButton')}`}
              </Button>
            </ModalOpenButton>
            <EditOrganisationModalContent orgData={orgData} />
          </Modal>
        ) : null}
      </h1>
      <p className="text-sm flex flex-row items-center">
        <a
          className="text-blue-500 underline"
          href={
            orgData?.website
              ? coerceIntoExternalLinkFormat(orgData?.website)
              : ''
          }
          target="_blank"
          rel="noreferrer"
        >
          {orgData?.name}
        </a>
        <svg
          className="mx-1.5 h-2 w-2 text-gray-300"
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
        <span className="text-gray-500">{orgData?.address}</span>
      </p>
    </div>
  )
}

export default SectionTitle
