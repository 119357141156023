import { t } from 'i18next'
import { Approval, ApprovalsTypeEnum } from 'src/types'

export const getDeclineApprovalTextContent = (approval: Approval) => {
  const textContentPerApprovalType = {
    [ApprovalsTypeEnum.Deal]: {
      title: t('approvals.deals.decline.deal.title'),
      description: t('approvals.deals.decline.deal.description'),
    },
    [ApprovalsTypeEnum.TokenMint]: {
      title: t('approvals.deals.decline.tokenMint.title'),
      description: t('approvals.deals.decline.tokenMint.description'),
    },
    [ApprovalsTypeEnum.Organisation]: {
      title: t('approvals.organizations.decline.organization.title'),
      description: t(
        'approvals.organizations.decline.organization.description'
      ),
    },
    [ApprovalsTypeEnum.PreDeal]: {
      title: t('approvals.deals.decline.deal.title'),
      description: t('approvals.deals.decline.deal.description'),
    },
  }

  return textContentPerApprovalType[approval.type]
}
