import {
  InputBase,
  RadioPanelGroup,
} from '@allinfra-ltd/allinfra-design-system'
import { RadioPanelGroupOption } from '@allinfra-ltd/allinfra-design-system/dist/components/RadioGroups/RadioPanelGroup/RadioPanelGroup'
import { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const NegativePledge = ({ value, id }: { value: string; id: string }) => {
  const { t } = useTranslation()
  const { register, getValues, setValue } = useFormContext()

  const pledgeOptions: RadioPanelGroupOption[] = useMemo(
    () => [
      {
        id: 0,
        primaryContent: t('deals.create.specificDetails.pledgesYesLabel'),
        secondaryContent: ({ checked }) =>
          checked ? NegativePledgeInput : null,
      },
      {
        id: 1,
        primaryContent: t('deals.create.specificDetails.pledgesNoLabel'),
        secondaryContent: '',
      },
    ],
    []
  )

  const [selectedPledge, setSelectedPledge] = useState(pledgeOptions[1])

  useEffect(() => {
    if (getValues(id) === '' && !getValues(id)) {
      setSelectedPledge(pledgeOptions[1])
    } else {
      setSelectedPledge(pledgeOptions[0])
    }
  }, [getValues(id)])

  const NegativePledgeInput = useMemo(() => {
    return (
      <div className="w-full">
        <InputBase
          type="text"
          placeholder={t('deals.create.specificDetails.pledgesInputLabel')}
          autoFocus
          defaultValue={getValues(id) || value}
          {...register(id, {
            required: selectedPledge.id === 0,
            value: getValues(id) || value,
          })}
        />
      </div>
    )
  }, [register])

  return (
    <RadioPanelGroup
      options={pledgeOptions}
      selectedOption={selectedPledge}
      setSelectedOption={(radioGroupOption: RadioPanelGroupOption) => {
        setSelectedPledge(radioGroupOption)
        if (radioGroupOption.id === 1) setValue(id, '')
      }}
    />
  )
}

export default NegativePledge
