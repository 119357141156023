import {
  ChevronDownIcon,
  SelectBase,
} from '@allinfra-ltd/allinfra-design-system'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDealsRanking } from 'src/hooks'
import { rankingMock } from 'src/mocks'
import { capitalize } from 'src/utils'

const Ranking = ({ value, id }: { value: string; id: string }) => {
  const { data: rankings, isLoading: iaLoading } = useDealsRanking()
  const { t } = useTranslation()
  const { control, getValues } = useFormContext()
  return rankings ? (
    <Controller
      control={control}
      name={id}
      rules={{
        required: t('deals.create.specificDetails.rankingInputError') as string,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <SelectBase
          placeholderLabel={capitalize(getValues(id) || value)}
          placeholderTrailingIcon={<ChevronDownIcon />}
          options={(rankings || rankingMock).map((ranking) => ({
            primaryLabel: capitalize(ranking),
          }))}
          onChange={(index) => onChange((rankings || rankingMock)[index])}
          selectedIndex={(rankings || rankingMock).findIndex(
            (ranking) => ranking === (getValues(id) || value)
          )}
          error={error?.message}
          disabled={iaLoading}
        />
      )}
    />
  ) : null
}

export default Ranking
