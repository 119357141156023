import { t } from 'i18next'

export const activeDealTabs = [
  {
    id: '1',
    name: t('deals.tabNames.dealDetails'),
    isActive: true,
  },
  {
    id: '2',
    name: t('deals.tabNames.initialInvestors'),
  },
  {
    id: '3',
    name: t('deals.tabNames.currentHolders'),
  },
  {
    id: '4',
    name: t('deals.tabNames.transactions'),
  },
]
