import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { getOrganizationMembers } from 'src/api/routes'
import { defaultTablePageSize } from 'src/utils'

type OrgMembersRequestParams = { id: number; pageSize?: number }

export default function useOrganisationMembersPage({
  id,
  pageSize = defaultTablePageSize,
}: OrgMembersRequestParams) {
  const [page, setPage] = useState(1)
  const qb = RequestQueryBuilder.create()

  const queryString = qb
    .setPage(page)
    .setJoin([{ field: 'createdDeals' }])
    .setLimit(pageSize)
    .query()

  return {
    setPage,
    ...useQuery([queryKeys.organizationsMembers, queryString], () =>
      getOrganizationMembers({ id, queryString })
    ),
  }
}
