export type AnyFunction = (...args: any[]) => any
export function callAll(...fns: (AnyFunction | undefined)[]): AnyFunction {
  return (...args: any[]) => fns.forEach((fn) => fn && fn(...args))
}

export const pipe = (...fns: AnyFunction[]) =>
  fns.reduce(
    (acc, fn) =>
      (...args) =>
        fn(acc(...args))
  )

export const compose = (...fns: AnyFunction[]) =>
  fns.reduceRight(
    (acc, fn) =>
      (...args) =>
        fn(acc(...args))
  )
