import { api, apiUrl } from 'src/api'
import { UserStatus } from 'src/types'

export async function getUsersQuery(queryString: string) {
  const { data } = await api.get(apiUrl.usersQuery(queryString))

  return data
}

export async function getLoggedInUserData() {
  const { data } = await api.get(apiUrl.loggedInUser)

  return data
}

export async function postRegenerateUserWallet({ userId }: { userId: number }) {
  const { data } = await api.post(apiUrl.regenerateUserWallet(userId))

  return data
}

export interface PutUserStatusPayload {
  userId: string
  body: { status: UserStatus; resetPassword?: boolean }
}

export async function putUserStatus({ userId, body }: PutUserStatusPayload) {
  const { data } = await api.put(apiUrl.userStatus(userId), body)

  return data
}

export interface PutUserDetailsPayload {
  userId: string
  body: { email: string; firstName: string; lastName: string }
}

export async function putUserDetails({ userId, body }: PutUserDetailsPayload) {
  const { data } = await api.put(apiUrl.user(userId), body)

  return data
}
