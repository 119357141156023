import { useMemo } from 'react'
import {
  CreateDealFlatDataKeys as DataKeys,
  Deal,
  DealDetailsPreviewHeader,
} from 'src/types'

import DealDetailsGrid from './DealDetailsGrid'
import {
  formatDealDetails,
  getGridColumns,
  getStatusValueAndColor,
} from './utils'

function DealDetailsPreview({ selectedDealData }: { selectedDealData: Deal }) {
  const { state } = selectedDealData || {}
  const { generalInfo, specificDetails, smartContractDetails } = useMemo(
    () => formatDealDetails(selectedDealData),
    [selectedDealData]
  )
  const generalInfoData = useMemo(
    () => [
      {
        key: 'Status',
        value: getStatusValueAndColor(state),
        id: DataKeys.Status,
      },
      generalInfo.type,
      generalInfo.dealIssuer,
      generalInfo.currency,
      generalInfo.tickerSymbol,
      generalInfo.anticipatedIssuanceDate,
    ],
    [selectedDealData]
  )

  const smartContractDetailsData = useMemo(
    () => Object.values(smartContractDetails),
    [selectedDealData]
  )

  return (
    <div className="mt-8 space-y-10">
      {selectedDealData?.token ? (
        <DealDetailsGrid
          columns={getGridColumns(
            DealDetailsPreviewHeader.smartContractDetails
          )}
          data={smartContractDetailsData}
        />
      ) : null}
      <DealDetailsGrid
        columns={getGridColumns(DealDetailsPreviewHeader.generalInfo)}
        data={generalInfoData}
      />

      <DealDetailsGrid
        columns={getGridColumns(DealDetailsPreviewHeader.specificDetails)}
        data={Object.values(specificDetails)}
      />
    </div>
  )
}

export default DealDetailsPreview
