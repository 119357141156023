import {
  ArrowRightIcon,
  Badge,
  Button,
  ControlledPaginatedTable,
  Modal,
  ModalOpenButton,
  TernaryTreeIcon,
  TextAvatar,
} from '@allinfra-ltd/allinfra-design-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useAsyncDebounce } from 'react-table'
import { queryKeys } from 'src/api/query-keys'
import { SearchInput, SmallSpinner } from 'src/components'
import {
  useApprovalsOrgsPage,
  useUpdateApprovalStatus,
} from 'src/hooks/approvals'
import { Approval, ApprovalStatus } from 'src/types'
import { blue500, defaultTablePageSize } from 'src/utils'

import { mapBadgeToStatus } from './ApprovalBadgeUtil'
import ApprovalsEmptyState from './ApprovalsEmptyState'
import {
  ModalContentApprovalDecline,
  getDeclineApprovalTextContent,
} from './Modals'

export default function OrgApprovals() {
  const pageSize = defaultTablePageSize
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { isLoading: isLoadingStatus, mutate: submitMutate } =
    useUpdateApprovalStatus()

  const onDecline = (id: number) => {
    submitMutate(
      { id, status: ApprovalStatus.Declined },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.approvals)
        },
      }
    )
  }

  const onApprove = (id: number) => {
    submitMutate(
      { id, status: ApprovalStatus.Approved },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.approvals)
        },
      }
    )
  }

  const onSearch = useAsyncDebounce((value = '') => {
    const val = value.trim()
    setSearch(val)
  })

  const { data, isSuccess, isLoading, setPage, setSearch } =
    useApprovalsOrgsPage({
      pageSize,
    })
  const flattenData = ({ data }: { data: Approval[] } = { data: [] }) => data
  const tableData = flattenData(data)
  const columnData = useMemo(
    () =>
      tableData.map((approval: Approval) =>
        mapServerData({
          approval,
          onApprove,
          onDecline,
          disableApprove: isLoadingStatus,
          disableCancel: isLoadingStatus,
          t,
        })
      ),
    [isLoadingStatus, isSuccess, tableData, t]
  )
  const columns = [
    { Header: 'Approval type', accessor: 'type' },
    { Header: 'Description', accessor: 'description' },
    { Header: 'Creator', accessor: 'creator' },
    { Header: 'Status', accessor: 'status' },
    { Header: '', accessor: 'actions' },
  ]

  const renderList = () => {
    if (isSuccess) {
      return tableData.length ? (
        <ControlledPaginatedTable
          columns={columns}
          data={columnData}
          initialState={{ pageSize: defaultTablePageSize }}
          isLoading={isLoading}
          pageCount={data?.pageCount}
          fetchData={({ pageIndex }) => {
            setPage(pageIndex + 1)
          }}
        />
      ) : (
        <div className="h-3/4 flex justify-center items-center">
          <ApprovalsEmptyState
            title={t('approvals.emptyApprovalsOrgCard.title')}
            description={t('approvals.emptyApprovalsOrgCard.description')}
          />
        </div>
      )
    }
    return (
      <div className="h-3/4 flex justify-center items-center">
        <SmallSpinner />
      </div>
    )
  }

  return (
    <>
      <div className="w-2/4">
        <SearchInput
          autoFocus
          placeholder={t('approvals.orgs.searchPlaceholder')}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      {renderList()}
    </>
  )
}

const mapServerData = ({
  approval,
  onApprove,
  disableApprove,
  t,
}: {
  approval: Approval
  onDecline: (id: number) => void
  onApprove: (id: number) => void
  disableCancel: boolean
  disableApprove: boolean
  t: (val: string) => string
}) => {
  const { title: declineModalTitle, description: declineModalDescription } =
    getDeclineApprovalTextContent(approval)

  return {
    type: (
      <TextAvatar
        avatar={
          <div className="bg-blue-100 rounded-full p-2">
            <TernaryTreeIcon fill={blue500} />
          </div>
        }
        avatarShape="circular"
        primaryContent="Approval Type"
        secondaryContent={t(`approvals.orgs.types.${approval.entityType}`)}
        size="large"
      />
    ),
    description: (
      <div>
        <p className="text-sm font-medium leading-normal text-gray-700">
          {approval.organisation?.name}
        </p>
        <p className="text-sm font-medium leading-normal text-gray-500">
          {approval.organisation?.address}
        </p>
      </div>
    ),
    creator: (
      <div className="flex flex-col">
        <p className="text-sm font-medium leading-normal text-gray-700">
          {approval.createdBy?.firstName} {approval.createdBy?.lastName}
        </p>
        <p className="text-sm font-medium leading-normal text-gray-500">
          {approval.createdBy?.email}
        </p>
      </div>
    ),
    status: (
      <Badge color={mapBadgeToStatus(approval.status).color}>
        {t(mapBadgeToStatus(approval.status).label)}
      </Badge>
    ),
    actions: (
      <div className="w-full flex justify-end">
        {approval.status === ApprovalStatus.Pending ? (
          <Modal key={`${approval.id} decline`}>
            <ModalOpenButton>
              <Button
                key={`${approval.id} decline`}
                variant="alternative"
                size="small"
              >
                {t('approvals.buttons.decline')}
              </Button>
            </ModalOpenButton>
            <ModalContentApprovalDecline
              approvalId={approval.id}
              title={declineModalTitle}
              textContent={declineModalDescription}
            />
          </Modal>
        ) : null}
        {approval.status === ApprovalStatus.Pending ? (
          <Button
            variant="primary"
            size="small"
            onClick={() => {
              onApprove(approval.id)
            }}
            disabled={disableApprove}
            classes="mx-3"
          >
            Approve
          </Button>
        ) : null}
        <div className="flex items-baseline">
          <ArrowRightIcon className="my-auto cursor-pointer mx-3" />
        </div>
      </div>
    ),
  }
}
