import { InputBase, InputBaseProps } from '@allinfra-ltd/allinfra-design-system'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface EmailInputProps<T = FieldValues> extends Partial<InputBaseProps> {
  register: UseFormRegister<T & FieldValues>
}

export default function EmailInput<T = FieldValues>({
  register,
  ...props
}: EmailInputProps<T>) {
  const { t } = useTranslation()

  return (
    <InputBase
      {...register('email' as any, {
        required: t('input.email.required') as string,
        pattern: {
          value: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
          message: t('input.email.patternMessage'),
        },
      })}
      type="email"
      placeholder={t('input.email.placeholder')}
      label={t('input.email.label')}
      {...props}
    />
  )
}
