import { useCallback, useLayoutEffect, useRef } from 'react'

function useSafeDispatch(dispatch: (...args: any[]) => void) {
  const mountedRef = useRef(false)

  useLayoutEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  return useCallback(
    (...args: any[]) => (mountedRef.current ? dispatch(...args) : null),
    [dispatch]
  )
}

export default useSafeDispatch
