import { AxiosError } from 'axios'
import { useCallback } from 'react'
import {
  MutateOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { PutUserStatusPayload, putUserStatus } from 'src/api/routes'
import { User, UserStatus } from 'src/types'
import { ErrorResponse } from 'src/types/errors'

const updateStatus = async (updateUserStatusPayload: PutUserStatusPayload) =>
  putUserStatus(updateUserStatusPayload)

export type MutateUserStatusOptions = MutateOptions<
  unknown,
  AxiosError<ErrorResponse, any>,
  PutUserStatusPayload,
  User
>

export type UpdateUserStatusFunction = (
  props: UpdateUserStatusFunctionProps
) => void

export type MutateUserStatusMutationResult = UseMutationResult<
  User,
  AxiosError<ErrorResponse>,
  PutUserStatusPayload,
  User
> & {
  disableUser: UpdateUserStatusFunction
  enableUser: UpdateUserStatusFunction
}

export interface UpdateUserStatusFunctionProps {
  userId: PutUserStatusPayload['userId']
  isPasswordReset?: boolean
  mutationOptions?: MutateUserStatusOptions
}

export default function useUpdateUserStatus(): MutateUserStatusMutationResult {
  const queryClient = useQueryClient()

  const updateUserStatsMutation = useMutation<
    User,
    AxiosError<ErrorResponse>,
    PutUserStatusPayload,
    User
  >(updateStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.activeUsers)
      queryClient.invalidateQueries(queryKeys.disabledUsers)
    },
  })

  const disableUser = useCallback(
    ({ userId, mutationOptions }: UpdateUserStatusFunctionProps) =>
      updateUserStatsMutation.mutate(
        {
          userId,
          body: {
            status: UserStatus.Disabled,
          },
        },
        mutationOptions
      ),
    [updateUserStatsMutation]
  )

  const enableUser = useCallback(
    ({
      userId,
      isPasswordReset = false,
      mutationOptions,
    }: UpdateUserStatusFunctionProps) =>
      updateUserStatsMutation.mutate(
        {
          userId,
          body: {
            status: UserStatus.Enabled,
            resetPassword: isPasswordReset,
          },
        },
        mutationOptions
      ),
    [updateUserStatsMutation]
  )

  return { disableUser, enableUser, ...updateUserStatsMutation }
}
