import { Role } from './users'

export interface InviteRequestBody {
  email: string
  roleId?: number
}

export interface InviteFormFields {
  email: string
  roleIndex?: number
}

export enum InviteStatus {
  Success = 'success',
  Pending = 'pending',
  Failed = 'failed',
  Canceled = 'canceled',
}

export enum InviteType {
  Signup = 'signup',
  Organization = 'organization',
}

export interface Invite {
  id: number
  email: string
  createdAt: Date
  createdBy: number
  updatedAt: Date
  status: InviteStatus
  type: InviteType
  role: Role
}
