import { RoleEnum } from 'src/enum/role'
import { getStateMatchInAnyDeal } from 'src/pages/Deals/utils'
import { Role, RoleId, User, UserStatus } from 'src/types'

export const rolesMap = {
  1: 'Super Admin',
  2: 'Admin',
  3: 'Issuer',
  4: 'Operator',
  5: 'Regulator',
  6: 'Investor',
  7: 'Issuer',
  8: 'Issuer',
  default: 'Unknown',
}

export function getRoleName(role?: Role): string {
  return typeof role?.id === 'number'
    ? rolesMap[role.id as RoleId]
    : rolesMap.default
}

export function getUserIdentifier(user: User): string | number {
  const userFullName =
    user.firstName && user.lastName
      ? `${user?.firstName} ${user?.lastName}`
      : null
  const identifier = [userFullName, user.externalId].find(Boolean) || ''

  const identifierByRole = {
    1: identifier,
    2: identifier,
    3: identifier,
    4: identifier,
    5: identifier,
    6: user.externalId,
    7: identifier,
    8: identifier,
  }

  return identifierByRole[user.role.id]
}

export const getUserRoleMatch = ({ role: { id: roleId } }: User) => ({
  isSuperAdmin: roleId === RoleEnum.SuperAdmin,
  isAdmin: roleId === RoleEnum.Admin,
  isIssuer: roleId === RoleEnum.Issuer,
  isOperator: roleId === RoleEnum.Operator,
  isRegulator: roleId === RoleEnum.Regulator,
  isInvestor: roleId === RoleEnum.Investor,
  isIssuerOrgOwner: roleId === RoleEnum.IssuerOrgOwner,
  isIssuerOrgMember: roleId === RoleEnum.IssuerOrgMember,
})

export function getUserCreatedDealsMatch(user: User) {
  const { createdDeals } = user
  const { isAnyDealStateActive = false } = createdDeals
    ? getStateMatchInAnyDeal(createdDeals)
    : {}

  return {
    isAnyUserCreatedDealActive: isAnyDealStateActive,
  }
}

export const getUserStatusMatch = (user: User) => ({
  isUserEnabled: user.status === UserStatus.Enabled,
  isUserDisabled: user.status === UserStatus.Disabled,
  isUserPending: user.status === UserStatus.Pending,
})

export function getUserGuardMatch({ actions }: User) {
  return {
    isEnableUserGuardEnabled: !actions.enable,
    isDisableUserGuardEnabled: !actions.disable,
    isEditUserGuardEnabled: !actions.edit,
  }
}

export function getMatchInUser(user: User) {
  return {
    ...getUserRoleMatch(user),
    ...getUserCreatedDealsMatch(user),
    ...getUserStatusMatch(user),
    ...getUserGuardMatch(user),
  }
}
