import { Button } from '@allinfra-ltd/allinfra-design-system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { NavTabs, SmallSpinner } from 'src/components'
import { OrganizationInviteModal } from 'src/components/Modal'
import { SectionHeader } from 'src/components/SectionHeader'
import { useMyOrganization } from 'src/hooks/Invitation'
import { usePermissions } from 'src/hooks/auth'

import OrgEmptyState from './OrgEmptyState'
import SectionTitle from './SectionTitle'

function Organisation() {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { data: orgData, isSuccess, isError } = useMyOrganization()
  const { isOrganizationEditEnabled } = usePermissions()

  const renderInviteIssuerModal = () => {
    if (!orgData) return null

    return (
      <OrganizationInviteModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        orgId={orgData.id}
      />
    )
  }

  const renderTableContent = (
    <div className="flex flex-col h-full m-h-full">
      <SectionHeader
        actions={
          <Button
            size="small"
            onClick={() => setIsModalOpen(true)}
            disabled={!orgData}
          >
            {t('organizations.sectionHeader.invitationButton')}
          </Button>
        }
        sectionTitle={
          <div>
            <SectionTitle
              orgData={orgData}
              isEditable={isOrganizationEditEnabled}
            />
          </div>
        }
      >
        <NavTabs
          tabsConfig={[
            { content: t('navigation.status.active'), path: 'active' },
            { content: t('navigation.status.pending'), path: 'pending' },
          ]}
        />
      </SectionHeader>
      <div className="flex-1 overflow-y-scroll overflow-x-hidden">
        <Outlet context={{ orgData }} />
      </div>
      {isModalOpen && renderInviteIssuerModal()}
    </div>
  )

  const renderLoadingWheel = (
    <div className="space-x-2">
      <SmallSpinner />
      Loading organization data...
      <br />
      {isError ? (
        <span>
          There seem to be some issues loading your data. Please try again in a
          few minutes.
        </span>
      ) : null}
    </div>
  )

  if (isSuccess) {
    if (orgData && orgData.status === 'active') {
      return renderTableContent
    }

    return (
      <div className="h-screen flex items-center justify-center">
        <OrgEmptyState organization={orgData} />
      </div>
    )
  }

  return renderLoadingWheel
}

export default Organisation
