/* eslint-disable import/no-cycle */
import React, { ReactNode } from 'react'
import { MutateOptions } from 'react-query'
import {
  Row,
  TableInstance,
  UseGlobalFiltersInstanceProps,
  UseRowSelectInstanceProps,
} from 'react-table'
import { AnyEventObject } from 'xstate'

import { Approval } from './approvals'
import { User } from './users'

export interface CreateDealBase {
  type: DealType
  eligibleType: DealEligible
}

export interface DealMainSale {
  eligibleInvestorsIds: number[]
  generalInfo: MainSaleGeneralInfo
  specificDetails: MainSaleSpecificDetails
}

export interface CreateDealMainSale extends CreateDealBase {
  mainSale: DealMainSale
}

export interface MainSaleSpecificDetails extends DealSpecificDetailsBase {
  incrementAmountSize: number
  regulatorId?: number
  maxIssueSize: number
}

export enum KeyOfDealStageData {
  PreDeal = 'preDeal',
  MainSale = 'mainSale',
}

export interface DealSpecificDetailsBase {
  negativePledge: string
  tenor: number
  ranking: DealRanking
  coupon: number
  couponPeriod: DealCouponPeriod
  baseIssueSize: number
  minInvestSize: number
}

export interface MainSaleGeneralInfo extends DealGeneralInfoBase {
  tickerSymbol: string
}

export interface DealGeneralInfoBase extends BaseDto {
  useOfProceeds: string
  description: string
  currency: DealCurrency
  anticipatedIssuanceDate: Date
}

export interface DealToken {
  name: string
  symbol: string
  address: string
  totalIssuedAmount: number
  distributedSupply: number
  totalSupply: number
  burnedSupply: number
  createdAt: Date
  updatedAt: Date
}

export interface CreateDealFlatData {
  description: string
  type: DealType
  tickerSymbol: string
  currency: DealCurrency
  tenor: number
  useOfProceeds: string
  ranking: DealRanking
  negativePledge: string
  incrementAmountSize: number
  coupon: number
  couponPeriod: DealCouponPeriod
  baseIssueSize: number
  maxIssueSize: number
  minInvestSize: number
  anticipatedIssuanceDate: Date
  discountPrice: number
  responseDate: Date
  regulatorId: number
  stage: DealStage
  eligibleType: DealEligible
  eligibleInvestorsIds: number[]
  preDealInvestorsIds: number[]
}
export enum CreateDealFlatDataKeys {
  Status = 'status',
  DealIssuer = 'dealIssuer',
  Description = 'description',
  Type = 'type',
  TickerSymbol = 'tickerSymbol',
  Currency = 'currency',
  Tenor = 'tenor',
  UseOfProceeds = 'useOfProceeds',
  Ranking = 'ranking',
  NegativePledge = 'negativePledge',
  IncrementAmountSize = 'incrementAmountSize',
  Coupon = 'coupon',
  CouponPeriod = 'couponPeriod',
  BaseIssueSize = 'baseIssueSize',
  MaxIssueSize = 'maxIssueSize',
  MinInvestSize = 'minInvestSize',
  AnticipatedIssuanceDate = 'anticipatedIssuanceDate',
  ResponseDate = 'responseDate',
  DiscountPrice = 'discountPrice',
  RegulatorId = 'regulatorId',
  Stage = 'stage',
  EligibleType = 'eligibleType',
  EligibleInvestorsIds = 'eligibleInvestorsIds',
  PreDealInvestorsIds = 'preDealInvestorsIds',
}

export enum DealType {
  Bond = 'bond',
}

export enum DealCurrency {
  USD = 'usd',
  EUR = 'eur',
  JPY = 'jpy',
  HKD = 'hkd',
}

export enum DealRanking {
  SeniorUnsecured = 'senior-unsecured',
  Secured = 'secured',
  Subordinated = 'subordinated',
}

export enum DealCouponPeriod {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  SemiAnnual = 'semi-annual',
  Annual = 'annual',
}

export enum DealStage {
  MainSale = 'main-sale',
  PreDeal = 'pre-deal',
  EarlyBird = 'early-bird',
}

export enum DealState {
  Draft = 'draft',
  DealPublishPendingApproval = 'deal-publish-pending-approval',
  DealPublishRejected = 'deal-publish-rejected-approval',
  DealPublishInProgress = 'deal-publish-in-progress',
  DealPublishFailed = 'deal-publish-failed',
  DealPublished = 'deal-published',
  DealClosed = 'deal-closed',
  MintPendingApproval = 'mint-pending-approval',
  MintRejected = 'mint-rejected-approval',
  MintApproved = 'mint-approved',
  MintInProgress = 'mint-in-progress',
  MintFailed = 'mint-failed',
  DealActive = 'deal-active',
  Unknown = 'unknown',
}

export enum DealEligible {
  All = 'all',
  Auto = 'auto',
  Manual = 'manual',
}

export enum CreateDealMachineState {
  Idle = 'idle',
  SettingGeneralInfo = 'setting-general-info',
  SettingSpecificDetails = 'setting-specific-details',
  SettingPreDealInvestors = 'setting-pre-deal-investors',
  SettingEligibleInvestors = 'setting-eligible-investors',
  Created = 'created',
}

export enum CreateDealMachineEvent {
  Init = 'INIT',
  Next = 'NEXT',
  Previous = 'PREVIOUS',
  Reset = 'RESET',
  Submit = 'SUBMIT',
  Created = 'CREATED',
  GoIdle = 'GO_IDLE',
  UpdateContext = 'UPDATE_CONTEXT',
}

export enum CreateDealMachineAction {
  NavigateToRootDeals = 'navigateToRootDeals',
  SetFlatDealData = 'setFlatDealData',
  ResetDealMachineContext = 'resetDealMachineContext',
  UpdateDealMachineContext = 'updateDealMachineContext',
}

export interface CreateDealMachineContext {
  isPreDealActive: boolean
  activeDealStepIndex: number
  flatDealData: Partial<CreateDealFlatData>
  selectedEligibleInvestorsFlatRows: Array<Row<{}>>
}

export interface CreateDealEventWithPayload extends AnyEventObject {
  payload: Partial<CreateDealFlatData>
}

export interface UpdateDealMachineContextEvent extends AnyEventObject {
  payload: Partial<CreateDealMachineContext>
}

export interface InvestorListDataEntry {
  id: number
  name: string
  globalFilterMetadata: string
  user: JSX.Element
  email: string
}

export interface DealPreDeal {
  generalInfo: PreDealGeneralInfo
  specificDetails: PreDealSpecificDetails
  responseDate: Date
  eligibleInvestorsIds: number[]
  preDealInvestorsIds: number[]
}
export interface CreateDealPreDeal extends CreateDealBase {
  preDeal: DealPreDeal
}

export interface BaseDto {
  id: number
}

export interface PreDealFeedback {
  id: number
  min: number
  max: number
  user: User
}

export interface PreDealGeneralInfo extends DealGeneralInfoBase {
  tickerSymbol: string
}

export interface PreDealSpecificDetails extends DealSpecificDetailsBase {
  incrementAmountSize: number
  maxIssueSize: number
}

export interface Deal extends BaseDto {
  token: DealToken
  createdBy: User
  preDeal: DealPreDealResponse
  mainSale: DealMainSaleResponse
  eligibleType: DealEligible
  stage: DealStage
  state: DealState
  type: DealType
  approvals?: Approval[]
  updatedAt: string
  createdAt: string
}

export interface DealMainSaleResponse extends BaseDto {
  deal: Deal
  generalInfo: MainSaleGeneralInfo
  specificDetails: MainSaleSpecificDetails
  eligibleInvestors: MainSaleEligibleInvestor[]
}

export interface TokenHoldersGridRowData {
  id: number
  name: string
  globalFilterMetadata: string
  currentTokenHolder: EligibleInvestor
  user: JSX.Element
  address: JSX.Element | string
  tokenBalance: string
  token: string
  actions: JSX.Element | string
  isDisabled: boolean
}
export interface InitialInvestorGridRow {
  id: number
  name: string
  globalFilterMetadata: string
  user: React.ReactNode
  investmentSize: string
  tokenBalance: string
  isDisabled: boolean
  investor: EligibleInvestor
}

export interface MainSaleEligibleInvestor extends BaseDto {
  mainSaleId: number
  eligibleInvestorId: number
  fiat: number
  isInitial: boolean
  initialToken: number
  token: number
  transferAction: EligibleInvestorTransferAction
  burnStatus: EligibleInvestorBurnStatus
  actionStatus: EligibleInvestorActionStatus
  eligibleInvestor: User
  isInitialTransferDone: boolean
  burnedTokens: number
}

export enum EligibleInvestorBurnStatus {
  NOT_BURNED = 'not-burned',
  PARTIAL_BURNED = 'partial-burned',
  BURNED = 'burned',
}

export enum EligibleInvestorActionStatus {
  FINISHED = 'finished',
  PENDING = 'pending',
}

export enum EligibleInvestorTransferAction {
  NOT_TRANSFERRED = 'not-transferred',
  TRANSFERRED = 'transferred',
  PARTIALLY_FORCE_TRANSFERRED = 'partially-force-transferred',
  FORCE_TRANSFERRED = 'force-transferred',
  REISSUED = 'reissued',
  BURNED = 'burned',
}

export type MainSaleInvestorMetadata = Exclude<
  MainSaleEligibleInvestor,
  'eligibleInvestor'
>

export interface EligibleInvestor extends User {
  investorMetadata: MainSaleInvestorMetadata | null
}

export interface DealPreDealResponse extends BaseDto {
  generalInfo: PreDealGeneralInfo
  preDealFeedbacks?: PreDealFeedback[]
  specificDetails: PreDealSpecificDetails
  responseDate: Date
  eligibleInvestors: User[]
  preDealInvestors: User[]
}

export interface Deals {
  data: Deal[]
  count: number
  total: number
  page: number
  pageCount: number
}

export interface DealPublishRequestBody {
  type: string
  eligibleType: DealEligible
  mainSale?: DealMainSale
}

export interface RequestDealMintRequestBody {
  investors: { userId: number; amount: number }[]
}

export interface DealActionsState {
  selectedDealData?: Deal
  isDealMinting: boolean
  isViewingDealDetails: boolean
}
export interface DealActionsContextValue {
  state: DealActionsState
  dispatch: React.Dispatch<Partial<DealActionsState>>
}

export interface ActiveDealState {
  isActionsModalOpen: boolean
  selectedTokenHolders?: EligibleInvestor[] | null
  selectedDeal?: Deal | null
  selectedInitialInvestors?: InitialInvestorGridRow[] | null
  modalType: ActiveDealActionModalType | null
}

export interface ActiveDealContextValue {
  state: ActiveDealState
  dispatch: React.Dispatch<Partial<ActiveDealState>>
}
export interface PublishRejectState {
  eligibleInvestorSelectedList: number[] | null
}
export interface PublishRejectContextValue {
  state: PublishRejectState
  dispatch: React.Dispatch<Partial<PublishRejectState>>
}
export interface RequestMintTokenBody {
  investors: { userId: number; amount: number }[]
}

export type DealDetailsInputType = string | number
export interface DealDetailDataMapValue {
  key: ReactNode
  id: string
}
export type GeneralInfoDataMap = Record<string, DealDetailDataMapValue> &
  Record<'type' | 'dealIssuer', DealDetailDataMapValue>

export type SpecificDetailsDataMap = Record<string, DealDetailDataMapValue>

export type FormattedDealDetails = Record<
  string,
  { key: ReactNode; value: ReactNode }
>

export interface BurnTokenAmountProps {
  dealId: number
  userId: number
  amount: number
}

export type BurnTokenAmountOptions = MutateOptions<
  any,
  unknown,
  {
    dealId: number
    body: {
      investors: {
        userId: number
        amount: number
      }[]
    }
  },
  unknown
>

export type BurnTokenAmount = (
  props: BurnTokenAmountProps,
  options?: BurnTokenAmountOptions
) => void
export type BurnTokenCallback = ({
  id,
  token,
}: {
  id: number
  token: number
}) => void

export type RefetchDealData = () => void

export enum ActiveDealActionModalType {
  TransferAdditionalTokens = 'transferAdditionalTokens',
  TransferTokensToInitialInvestor = 'transferTokensToInitialInvestor',
  TransferAddNewInvestor = 'transferAddNewInvestor',
  ForceTransferTokens = 'forceTransferTokens',
  BurnPartialTokens = 'burnPartialTokens',
  BurnMultipleTokens = 'burnMultipleTokens',
  ReissueTokens = 'reissueTokens',
  MintTokens = 'mintTokens',
}

export interface ActiveDealActionsProps {
  selectedDealData: Deal
  investor: EligibleInvestor
  modalType: ActiveDealActionModalType
}
export type ToggleActiveDealActions = (props: ActiveDealActionsProps) => void

export interface ActiveDealModalContentBaseProps {
  refetchDealData: RefetchDealData
}

export interface DealStats {
  totalInvestmentAmount: number
  maxInvestmentAmount: number
  tokenHolders: number
  totalSupply: number
  circulatingSupply: number
  burnedSupply: number
}

export type DealDetailsPreviewRowEditing = {
  rowId: string
  headerName: DealDetailsPreviewHeader
}

export interface DealDetailsPreviewState {
  editingRows: DealDetailsPreviewRowEditing[] | []
}
export interface DealDetailsPreviewContextValue {
  state: DealDetailsPreviewState
  dispatch: React.Dispatch<Partial<DealDetailsPreviewState>>
}

export enum DealDetailsPreviewHeader {
  generalInfo = 'generalInfo',
  specificDetails = 'specificDetails',
  smartContractDetails = 'smartContractDetails',
}

export interface DealDetailDataMapOriginalValue
  extends Pick<DealDetailDataMapValue, 'id' | 'key'> {
  value: string
}
export type EligibleInvestorTableInstanceType = TableInstance<{}> &
  UseRowSelectInstanceProps<{}> &
  UseGlobalFiltersInstanceProps<{}>
