import {
  CopyToClipboardIcon,
  TooltipBase,
} from '@allinfra-ltd/allinfra-design-system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { truncateHash } from 'src/utils'

export interface CopyToClipboardCellProps {
  value?: string | null
}

function CopyToClipboardCell({ value }: CopyToClipboardCellProps) {
  const { t } = useTranslation()
  const [showTooltip, setShowTooltip] = useState(false)
  const [content, setContent] = useState('Copy to clipboard')
  return (
    <div className="flex items-center">
      <span>{value ? truncateHash(value) : ' - '}</span>
      {value ? (
        <span
          role="button"
          tabIndex={0}
          onBlur={() => {}}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => {
            setShowTooltip(false)
            setTimeout(
              () =>
                setContent(t('shared.copyToClipboardMessage.copyToClipboard')),
              200
            )
          }}
          onClick={() => {
            navigator.clipboard.writeText(value)
            setContent(t('shared.copyToClipboardMessage.copied'))
          }}
          onKeyDown={() => {}}
          className="px-1"
        >
          <TooltipBase
            content={content}
            isTooltipVisible={showTooltip}
            placement="top"
            wrapperClasses="color-white"
            className="z-40 -translate-x-1/2 before:bg-black bg-black"
          >
            <CopyToClipboardIcon
              className={showTooltip ? 'fill-gray-900' : 'fill-gray-400'}
              fill=""
            />
          </TooltipBase>
        </span>
      ) : null}
    </div>
  )
}

export default CopyToClipboardCell
