import {
  Button,
  ErrorAlert,
  Modal,
  ModalOpenButton,
  PanelCards,
  UnderlineTabs,
  UnderlineTabsProvider,
} from '@allinfra-ltd/allinfra-design-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PaperBand,
  PaperBandOffset,
  PaperContentAligner,
  PaperOverlay,
  SmallSpinner,
} from 'src/components'
import { useDealStatsQuery, useSingleDealQuery } from 'src/hooks'
import { useUpdateApprovalStatus } from 'src/hooks/approvals'
import { getTokenPanelCards } from 'src/pages/Deals/ViewDeal/ActiveDeal/utils'
import { getDealStatsData, getDealTypeData } from 'src/pages/Deals/utils'
import { ApprovalsBaseOverlayProps, DealState } from 'src/types'

import {
  ModalContentApprovalDecline,
  getDeclineApprovalTextContent,
} from '../../Modals'
import { useApprovalActions } from '../../context'
import TabsContent from './TabsContent'
import { pendingApprovalTabs } from './utils'

function PendingApproval({ selectedApprovalData }: ApprovalsBaseOverlayProps) {
  const { t } = useTranslation()
  const { isViewingApprovalDetails, toggleViewApprovalDetails } =
    useApprovalActions()
  const { generalInfo } = getDealTypeData(selectedApprovalData.deal)
  const { data: dealData, isError: isErrorLoadingDealData } =
    useSingleDealQuery({
      id: selectedApprovalData.deal.id,
      dealStage: selectedApprovalData.deal.stage,
    })
  const {
    approveDeal,
    isLoading,
    isError: isErrorUpdatingDealStatus,
  } = useUpdateApprovalStatus()

  const { data: dealStats } = selectedApprovalData.deal.id
    ? useDealStatsQuery({
        id: selectedApprovalData.deal.id,
      })
    : { data: getDealStatsData() }

  const tokenCards = useMemo(
    () => getTokenPanelCards(getDealStatsData(dealStats), dealData),
    [dealData, dealStats]
  )

  const { title: declineModalTitle, description: declineModalDescription } =
    getDeclineApprovalTextContent(selectedApprovalData)

  return (
    <PaperOverlay
      isOpen={isViewingApprovalDetails}
      onClose={toggleViewApprovalDetails}
      title={generalInfo.useOfProceeds}
      description={generalInfo.description}
    >
      <PaperContentAligner>
        {dealData && tokenCards.length > 0 ? (
          <PanelCards
            className={
              [DealState.MintPendingApproval].includes(dealData.state)
                ? 'border md:grid-cols-1 gap-px bg-gray-200'
                : undefined
            }
            cards={tokenCards}
          />
        ) : null}

        <UnderlineTabsProvider tabs={pendingApprovalTabs}>
          <UnderlineTabs />
          {dealData ? (
            <TabsContent deal={dealData} />
          ) : (
            <div className="p-4">
              <SmallSpinner />
            </div>
          )}
          {isErrorLoadingDealData ? (
            <div className="py-4">
              <ErrorAlert>{t('approvals.errors.dealDetails')}</ErrorAlert>
            </div>
          ) : null}
        </UnderlineTabsProvider>
      </PaperContentAligner>
      <PaperBand fullWidthWrapperClasses="fixed bottom-0">
        <div className="w-full flex justify-between bg-none">
          <Modal key={`${selectedApprovalData.id} decline`}>
            <ModalOpenButton>
              <Button size="medium" variant="alternative">
                {t('approvals.buttons.decline')}
              </Button>
            </ModalOpenButton>
            <ModalContentApprovalDecline
              onClickCloseButton={toggleViewApprovalDetails}
              approvalId={selectedApprovalData.id}
              title={declineModalTitle}
              textContent={declineModalDescription}
            />
          </Modal>
          {isErrorUpdatingDealStatus ? (
            <Button
              loading={isLoading}
              size="medium"
              variant="danger"
              onClick={() =>
                approveDeal(selectedApprovalData.id, {
                  onSuccess: toggleViewApprovalDetails,
                })
              }
            >
              {t('approvals.buttons.approve')}
            </Button>
          ) : (
            <Button
              loading={isLoading}
              size="medium"
              variant="primary"
              onClick={() =>
                approveDeal(selectedApprovalData.id, {
                  onSuccess: toggleViewApprovalDetails,
                })
              }
            >
              {t('approvals.buttons.approve')}
            </Button>
          )}
        </div>
      </PaperBand>
      <PaperBandOffset />
    </PaperOverlay>
  )
}

export default PendingApproval
