import { AlertBase, Button } from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import { Card } from 'src/components'
import { useMyOrganization } from 'src/hooks'

import { getMostRecentApproval } from '../Approvals/utils'

interface DeclinedApplicationCardProps {
  onButtonClick: () => void
}

export default function DeclinedApplicationCard({
  onButtonClick,
}: DeclinedApplicationCardProps) {
  const { data: myOrganizationData } = useMyOrganization()
  if (!myOrganizationData) return null

  const latestApproval = getMostRecentApproval(myOrganizationData.approvals)

  return (
    <Card classes="bg-white border border-slate-200">
      <div className="flex flex-col justify-center items-left text-left m-auto w-96 align-middle">
        <h2 className="text-gray-900 text-lg font-bold my-4">
          {`${t('organizations.declinedOrganisation.title')}`}
        </h2>
        <p className="text-gray-500 text-sm font-normal mb-4">
          {`${t('organizations.declinedOrganisation.content')}`}
        </p>

        <AlertBase variant="error">
          <p className="line-clamp-6">
            {latestApproval?.reason ||
              `${t('organizations.declinedOrganisation.defaultReason')}`}
          </p>
        </AlertBase>
        <Button
          type="button"
          classes="mt-4 w-full text-sm font-bold"
          onClick={onButtonClick}
        >
          {t('organizations.declinedOrganisation.actionButton')}
        </Button>
      </div>
    </Card>
  )
}
