import dayjs from 'dayjs'
import { t } from 'i18next'
import { FieldValues } from 'react-hook-form'
import {
  CreateDealFlatData,
  CreateDealMainSale,
  CreateDealFlatDataKeys as DataKeys,
  Deal,
  DealDetailsInputType,
  RecursivePartial,
  RequestDealMintRequestBody,
} from 'src/types'
import {
  addThousandsSeparator,
  capitalize,
  isValidDate,
  turnToUpperCase,
} from 'src/utils'

import { getPredefinedInvestorId } from '../../utils'

export const formatDate = (value: DealDetailsInputType) =>
  isValidDate(value) ? dayjs(value).format('YYYY-MM-DD') : value
const appendPercentageToValue = (value: DealDetailsInputType) => `${value} %`
const capitalizeString = (value: DealDetailsInputType) => capitalize(`${value}`)
const appendCurrency = (value: DealDetailsInputType) =>
  `${addThousandsSeparator(`${value}`)}`

export const formatCellData = (value: DealDetailsInputType, id: string) => {
  switch (id) {
    case DataKeys.Currency:
    case DataKeys.TickerSymbol:
      return turnToUpperCase(value as string)
    case DataKeys.Type:
    case DataKeys.CouponPeriod:
    case DataKeys.Ranking:
      return capitalizeString(value)
    case DataKeys.AnticipatedIssuanceDate:
      return formatDate(value)
    case DataKeys.IncrementAmountSize:
    case DataKeys.BaseIssueSize:
    case DataKeys.MaxIssueSize:
    case DataKeys.MinInvestSize:
      return appendCurrency(value)
    case DataKeys.NegativePledge:
      return value ? (
        <div>
          <div>{`${t('shared.capitalizedYes')}`}</div>
          <p className="text-xs text-gray-400 block break-all whitespace-normal">
            {value}
          </p>
        </div>
      ) : (
        t('shared.notApplicable')
      )
    case DataKeys.Tenor:
      return `${value} ${t('shared.lowerCaseYears')}`
    case DataKeys.Coupon:
      return appendPercentageToValue(value)
    default:
      return value
  }
}
export const composeUpdateMintDto = (
  selectedDealData: Deal,
  data: FieldValues
): RequestDealMintRequestBody => {
  return {
    investors: selectedDealData.mainSale.eligibleInvestors.map((investor) => {
      const investorAmount =
        data[getPredefinedInvestorId(investor.eligibleInvestor.id)]

      return {
        userId: investor.eligibleInvestor.id,
        amount: investorAmount ? Number(investorAmount) : 0,
      }
    }),
  }
}
export const composeUpdateMainDealDto = (
  createDealFlatData: Partial<CreateDealFlatData>,
  originalDeal: Deal
): RecursivePartial<CreateDealMainSale> => {
  return {
    type: createDealFlatData.type ?? originalDeal.type,
    eligibleType: createDealFlatData.eligibleType ?? originalDeal.eligibleType,
    mainSale: {
      eligibleInvestorsIds:
        createDealFlatData.eligibleInvestorsIds ??
        originalDeal.mainSale.eligibleInvestors.map(
          (investor) => investor.eligibleInvestor.id
        ),
      generalInfo: {
        useOfProceeds:
          createDealFlatData.useOfProceeds ??
          originalDeal.mainSale.generalInfo.useOfProceeds,
        description: createDealFlatData.description,
        currency:
          createDealFlatData.currency ??
          originalDeal.mainSale.generalInfo.currency,
        tickerSymbol:
          createDealFlatData.tickerSymbol ??
          originalDeal.mainSale.generalInfo.tickerSymbol,
        anticipatedIssuanceDate:
          createDealFlatData.anticipatedIssuanceDate ??
          originalDeal.mainSale.generalInfo.anticipatedIssuanceDate,
      },
      specificDetails: {
        tenor: createDealFlatData.tenor
          ? Number(createDealFlatData.tenor)
          : originalDeal.mainSale.specificDetails.tenor,
        ranking:
          createDealFlatData.ranking ??
          originalDeal.mainSale.specificDetails.ranking,
        regulatorId:
          createDealFlatData.regulatorId ??
          originalDeal.mainSale.specificDetails.regulatorId,
        coupon: createDealFlatData.coupon
          ? Number(createDealFlatData.coupon)
          : originalDeal.mainSale.specificDetails.coupon,
        couponPeriod: createDealFlatData.couponPeriod,
        baseIssueSize: createDealFlatData.baseIssueSize
          ? Number(createDealFlatData.baseIssueSize)
          : originalDeal.mainSale.specificDetails.baseIssueSize,
        minInvestSize: createDealFlatData.minInvestSize
          ? Number(createDealFlatData.minInvestSize)
          : originalDeal.mainSale.specificDetails.minInvestSize,
        negativePledge:
          createDealFlatData.negativePledge ??
          originalDeal.mainSale.specificDetails.negativePledge,
        incrementAmountSize: createDealFlatData.incrementAmountSize
          ? Number(createDealFlatData.incrementAmountSize)
          : originalDeal.mainSale.specificDetails.incrementAmountSize,
        maxIssueSize: createDealFlatData.maxIssueSize
          ? Number(createDealFlatData.maxIssueSize)
          : originalDeal.mainSale.specificDetails.maxIssueSize,
      },
    },
  }
}
