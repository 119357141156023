import {
  MediaAvatarBase,
  classNames,
} from '@allinfra-ltd/allinfra-design-system'
import { ReactNode, isValidElement } from 'react'

export interface AvatarCardProps {
  primaryContent: ReactNode
  secondaryContent: ReactNode
  endContent: ReactNode
  avatar: ReactNode
  className: string
}

function AvatarCard({
  className,
  primaryContent,
  secondaryContent,
  endContent,
  avatar,
}: Partial<AvatarCardProps>) {
  return (
    <div
      className={classNames(
        'relative flex items-center justify-between rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm',
        className
      )}
    >
      <div className="flex-shrink-0">
        <MediaAvatarBase
          avatar={avatar}
          primaryContent={
            isValidElement(primaryContent) ? (
              primaryContent
            ) : (
              <p className="text-gray-900 text-md truncate w-48">
                {primaryContent}
              </p>
            )
          }
          secondaryContent={
            isValidElement(secondaryContent) ? (
              secondaryContent
            ) : (
              <p className="text-gray-500 text-sm truncate w-48">
                {secondaryContent}
              </p>
            )
          }
        />
      </div>
      <div>{endContent ?? null}</div>
    </div>
  )
}

export default AvatarCard
