import { lazy } from 'react'
import { Navigate, RouteObject } from 'react-router-dom'
import { RequireAuth } from 'src/components'
import { Approvals, DealsApprovals, OrgsApprovals } from 'src/pages/Approvals'
import { ApprovalActionsProvider } from 'src/pages/Approvals/context'
import { DashboardLayout } from 'src/pages/DashboardLayout'
import {
  CreateDealProvider,
  DealActionsProvider,
} from 'src/pages/Deals/context'
import {
  ActiveOrgUsersList,
  Organisation,
  PendingOrgUsersList,
} from 'src/pages/Organisation'
import { BrowserRouterSubPaths } from 'src/types'

const Users = lazy(() => import('src/pages/Users/Users'))
const ActiveUsersList = lazy(() => import('src/pages/Users/ActiveUsersList'))
const PendingUsersList = lazy(() => import('src/pages/Users/PendingUsersList'))
const DisabledUsersList = lazy(
  () => import('src/pages/Users/DisabledUsersList')
)
const Deals = lazy(() => import('src/pages/Deals/Deals'))

export function getPrivateRoutes(
  subPaths: BrowserRouterSubPaths
): RouteObject[] {
  const privateRoutes: RouteObject[] = [
    {
      element: <RequireAuth />,
      children: [
        {
          path: subPaths.Root,
          element: <DashboardLayout />,
          children: [
            { index: true, element: <Navigate to={subPaths.RootUsers} /> },
            {
              path: subPaths.RootApprovals,
              element: <Approvals />,
              children: [
                {
                  path: subPaths.RootApprovalsOrganizations,
                  element: <OrgsApprovals />,
                },
                {
                  path: subPaths.RootApprovalsDeals,
                  element: (
                    <ApprovalActionsProvider>
                      <DealsApprovals />
                    </ApprovalActionsProvider>
                  ),
                },
                {
                  index: true,
                  element: (
                    <Navigate
                      to={subPaths.RootApprovalsOrganizations}
                      replace
                    />
                  ),
                },
              ],
            },
            {
              path: subPaths.RootUsers,
              element: <Users />,
              children: [
                {
                  path: subPaths.RootUsersActive,
                  element: <ActiveUsersList />,
                },
                {
                  path: subPaths.RootUsersPending,
                  element: <PendingUsersList />,
                },
                {
                  path: subPaths.RootUsersDisabled,
                  element: <DisabledUsersList />,
                },
                {
                  index: true,
                  element: <Navigate to={subPaths.RootUsersActive} replace />,
                },
              ],
            },
            {
              path: subPaths.RootOrganizations,
              element: <Organisation />,
              children: [
                {
                  index: true,
                  element: <Navigate to={subPaths.RootOrganizationsActive} />,
                },
                {
                  path: subPaths.RootOrganizationsActive,
                  element: <ActiveOrgUsersList />,
                },
                {
                  path: subPaths.RootOrganizationsPending,
                  element: <PendingOrgUsersList />,
                },
              ],
            },
            {
              path: subPaths.RootDeals,
              element: (
                <DealActionsProvider>
                  <CreateDealProvider>
                    <Deals />
                  </CreateDealProvider>
                </DealActionsProvider>
              ),
            },
          ],
        },
      ],
    },
  ]

  return privateRoutes
}
