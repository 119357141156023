import {
  Overlay,
  OverlayAlignedContent,
  OverlayContentAligner,
  OverlayOpenButton,
} from '@allinfra-ltd/allinfra-design-system'
import { useTranslation } from 'react-i18next'
import DisclaimerContent from 'src/pages/DashboardLayout/DashboardFooter/DisclaimerContent'
import PrivacyContent from 'src/pages/DashboardLayout/DashboardFooter/PrivacyContent'
import { companyContactEmail, companyDisplayName } from 'src/utils'

function SplitScreenFooter() {
  const { t } = useTranslation()

  return (
    <div className="px-12">
      <div className="h-14 flex flex-row items-center">
        <div className="flex items-center justify-between py-2 w-full">
          <p className="text-sm text-gray-400">{t('footer.copyRight')}</p>

          <div className="flex justify-between space-x-6">
            <Overlay>
              <OverlayOpenButton>
                <p className="text-sm text-gray-400 hover:text-gray-500 cursor-pointer">
                  {t('footer.privacy')}
                </p>
              </OverlayOpenButton>
              <OverlayAlignedContent title={t('privacy.title')}>
                <OverlayContentAligner>
                  <div className="overflow-y-scroll flex-1 h-[80vh] rounded-md scroll-smooth">
                    <PrivacyContent
                      companyName={companyDisplayName}
                      contacts={{ email: companyContactEmail }}
                    />
                  </div>
                </OverlayContentAligner>
              </OverlayAlignedContent>
            </Overlay>
            <Overlay>
              <OverlayOpenButton>
                <p className="text-sm text-gray-400 hover:text-gray-500 cursor-pointer">
                  {t('footer.disclaimer')}
                </p>
              </OverlayOpenButton>
              <OverlayAlignedContent title={t('disclaimer.title')}>
                <OverlayContentAligner>
                  <div className="overflow-y-scroll flex-1 h-[80vh] rounded-md scroll-smooth">
                    <DisclaimerContent companyName={companyDisplayName} />
                  </div>
                </OverlayContentAligner>
              </OverlayAlignedContent>
            </Overlay>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplitScreenFooter
