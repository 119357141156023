import { AllinfraDigitalBlue } from '@allinfra-ltd/allinfra-design-system'
import { ReactNode } from 'react'

import SplitScreenFooter from './SplitScreenFooter'

export default function SplitScreen({
  children,
  rightContent,
}: {
  children: ReactNode
  rightContent?: ReactNode
}) {
  return (
    <div className="min-h-screen flex justify-center">
      <div className="lg:w-1/2 sm:w-full flex flex-col items-center justify-between py-12 px-4 sm:px-6 lg:px-20 xl:px-24 relative">
        <div className="mx-auto w-full max-w-sm">
          <AllinfraDigitalBlue />
        </div>
        <div className="mx-auto w-full max-w-sm lg:w-96">{children}</div>
        <div className="invisible" />
        <div className=" absolute w-full bottom-0">
          <SplitScreenFooter />
        </div>
      </div>
      <div className="hidden lg:flex lg:justify-center lg:items-center lg:w-1/2 bg-gradient-to-bl from-blue-600 to-blue-800">
        {rightContent}
      </div>
    </div>
  )
}
