import {
  ChevronDownIcon,
  SelectBase,
  SelectBaseProps,
} from '@allinfra-ltd/allinfra-design-system'

const Select = ({
  placeholderLabel,
  placeholderTrailingIcon,
  ...props
}: SelectBaseProps) => {
  return (
    <SelectBase
      placeholderLabel={placeholderLabel || 'Select'}
      placeholderTrailingIcon={placeholderTrailingIcon || <ChevronDownIcon />}
      {...props}
    />
  )
}

export default Select
