import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { BrowserRouterSubPaths } from 'src/types'

const CheckInbox = lazy(() => import('src/pages/ForgotPassword/CheckInbox'))
const CreateNewPassword = lazy(
  () => import('src/pages/ForgotPassword/CreateNewPassword')
)
const ForgotPassword = lazy(
  () => import('src/pages/ForgotPassword/ForgotPassword')
)
const RecoverAccount = lazy(
  () => import('src/pages/RecoverAccount/RecoverAccount')
)
const Login = lazy(() => import('src/pages/Login/Login'))
const SignUp = lazy(() => import('src/pages/SignUp/SignUp'))

export function getPublicRoutes(
  subPaths: BrowserRouterSubPaths
): RouteObject[] {
  const publicRoutes: RouteObject[] = [
    { path: subPaths.RootLogin, element: <Login /> },
    { path: subPaths.RootForgotPassword, element: <ForgotPassword /> },
    { path: subPaths.RootCheckInbox, element: <CheckInbox /> },
    { path: subPaths.RootResetPassword, element: <CreateNewPassword /> },
    { path: subPaths.RootCreateAccount, element: <SignUp /> },
    { path: subPaths.RootRecoverAccount, element: <RecoverAccount /> },
  ]

  return publicRoutes
}
