function DualRowPanelCard({
  topRowElement,
  bottomRowElement,
}: {
  topRowElement?: React.ReactNode
  bottomRowElement?: React.ReactNode
}) {
  return (
    <div className="flex items-center">
      <div className="ml-5 w-0 flex-1">
        <div className="space-y-2">
          <div className="text-md font-medium text-gray-500 truncate">
            {topRowElement}
          </div>
          <div className="text-xl font-bold text-gray-900 tracking-wide">
            {bottomRowElement}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DualRowPanelCard
