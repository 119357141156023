import { BadgeColor } from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import { Deal, DealStage, DealState } from 'src/types'

export const getDealStatusLabelAndColor = (status: DealState) => {
  const data: Record<DealState, { color: BadgeColor; label: string }> = {
    [DealState.DealClosed]: {
      color: BadgeColor.Disabled,
      label: t('deals.dealsList.dealStates.dealClosed'),
    },
    [DealState.DealPublishPendingApproval]: {
      color: BadgeColor.Warning,
      label: t('deals.dealsList.dealStates.publishPendingApproval'),
    },
    [DealState.DealPublished]: {
      color: BadgeColor.Info,
      label: t('deals.dealsList.dealStates.dealPublished'),
    },
    [DealState.DealActive]: {
      color: BadgeColor.Success,
      label: t('deals.dealsList.dealStates.activeDeal'),
    },
    [DealState.DealPublishRejected]: {
      color: BadgeColor.Danger,
      label: t('deals.dealsList.dealStates.dealPublishDeclined'),
    },
    [DealState.DealPublishInProgress]: {
      color: BadgeColor.Warning,
      label: t('deals.dealsList.dealStates.dealPublishProgress'),
    },
    [DealState.DealPublishFailed]: {
      color: BadgeColor.Danger,
      label: t('deals.dealsList.dealStates.dealPublishFailed'),
    },
    [DealState.MintPendingApproval]: {
      color: BadgeColor.Warning,
      label: t('deals.dealsList.dealStates.pendingMintApproval'),
    },
    [DealState.MintApproved]: {
      color: BadgeColor.Info,
      label: t('deals.dealsList.dealStates.mintApproved'),
    },
    [DealState.MintRejected]: {
      color: BadgeColor.Danger,
      label: t('deals.dealsList.dealStates.mintRejected'),
    },
    [DealState.MintInProgress]: {
      color: BadgeColor.Warning,
      label: t('deals.dealsList.dealStates.mintInProgress'),
    },
    [DealState.MintFailed]: {
      color: BadgeColor.Danger,
      label: t('deals.dealsList.dealStates.mintFailed'),
    },
    [DealState.Draft]: {
      color: BadgeColor.Warning,
      label: t('deals.dealsList.dealStates.draft'),
    },
    [DealState.Unknown]: {
      color: BadgeColor.Warning,
      label: t('deals.dealsList.dealStates.unknown'),
    },
  }

  return data[status] || data[DealState.Unknown]
}

export const getDealTitleAndDescription = (deal: Deal | undefined) => {
  const isPreDealStage = deal?.stage === DealStage.PreDeal
  const isMainSaleStage = deal?.stage === DealStage.MainSale
  const dealStageType = isMainSaleStage ? 'mainSale' : 'preDeal'

  return {
    title:
      isPreDealStage || isMainSaleStage
        ? deal[dealStageType]?.generalInfo.useOfProceeds
        : null,
    description:
      isPreDealStage || isMainSaleStage
        ? deal[dealStageType]?.generalInfo.description
        : null,
  }
}
