import { ReactNode } from 'react'

import Card from './Card'

interface CardProps {
  title: string
  text: string
  svgImage?: JSX.Element
  children?: ReactNode
}

function EmptyStateCard({ children, title, text, svgImage }: CardProps) {
  return (
    <Card classes="bg-slate-100 border border-slate-200 mt-8">
      <div className="m-auto w-96 align-middle">
        <div
          className={`
          flex flex-col justify-center items-center text-center`}
        >
          {svgImage}
          <h2 className="text-gray-900 text-lg font-bold my-4">{title}</h2>
          <p className="text-gray-500 text-sm font-normal mb-4">{text}</p>

          {children}
        </div>
      </div>
    </Card>
  )
}

export default EmptyStateCard
