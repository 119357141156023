import {
  ChevronDownIcon,
  SelectBase,
} from '@allinfra-ltd/allinfra-design-system'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCouponPeriod } from 'src/hooks'
import { couponPeriodMock } from 'src/mocks'
import { capitalize } from 'src/utils'

const CouponPeriod = ({ value, id }: { value: string; id: string }) => {
  const { data: couponPeriod, isLoading: isLoadingCouponPeriod } =
    useCouponPeriod()
  const { t } = useTranslation()
  const { control, getValues } = useFormContext()
  return couponPeriod ? (
    <Controller
      control={control}
      name={id}
      rules={{
        required: t(
          'deals.create.specificDetails.couponPeriodInputError'
        ) as string,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <SelectBase
          placeholderLabel={capitalize(getValues(id) || value)}
          placeholderTrailingIcon={<ChevronDownIcon />}
          options={(couponPeriod || couponPeriodMock).map((occurrence) => ({
            primaryLabel: capitalize(occurrence),
          }))}
          onChange={(index) =>
            onChange((couponPeriod || couponPeriodMock)[index])
          }
          selectedIndex={(couponPeriod || couponPeriodMock).findIndex(
            (occurrence) => occurrence === (getValues(id) || value)
          )}
          error={error?.message}
          disabled={isLoadingCouponPeriod}
        />
      )}
    />
  ) : null
}

export default CouponPeriod
