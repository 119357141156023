export enum RegisterOptionsKey {
  Required = 'required',
  Min = 'min',
  Max = 'max',
  MaxLength = 'maxLength',
  MinLength = 'minLength',
  Pattern = 'pattern',
  Validate = 'validate',
  ValueAsNumber = 'valueAsNumber',
  ValueAsDate = 'valueAsDate',
  Value = 'value',
  SetValueAs = 'setValueAs',
  ShouldUnregister = 'shouldUnregister',
  OnChange = 'onChange',
  OnBlur = 'onBlur',
  Disabled = 'disabled',
  Deps = 'deps',
}
