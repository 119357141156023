import {
  Avatar,
  ChevronDownIcon,
  DropdownBase,
  classNames,
} from '@allinfra-ltd/allinfra-design-system'
import React from 'react'
import { Dot } from 'src/components'
import { useAuth } from 'src/context'
import { useUser } from 'src/hooks/auth'
import { getRoleName } from 'src/pages/Users/utils'
import { isAccountSettingsEnabled } from 'src/utils'

import UserDetailsAvatar from './UserDetailsAvatar'

function UserDropdownAvatar() {
  const { data: userData, isSuccess } = useUser()
  const { signOut } = useAuth()

  const firstName = userData?.firstName
  const lastName = userData?.lastName
  const initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`
  const userAvatar = React.useMemo(
    () => <Avatar>{initials}</Avatar>,
    [initials]
  )

  return isSuccess ? (
    <DropdownBase
      classes="bg-white z-10"
      button={
        <div className="flex items-center space-x-2">
          {userAvatar}
          <ChevronDownIcon />
        </div>
      }
    >
      <div className="p-6 truncate max-w-96">
        <UserDetailsAvatar
          avatar={userAvatar}
          primaryContent={
            <div className="w-44 truncate">
              <span className="text-gray-900 text-sm">{`${firstName} ${lastName}`}</span>
            </div>
          }
          secondaryContent={
            <span className="flex items-center space-x-1 truncate">
              <span className="flex items-center space-x-1 text-gray-500 text-sm">
                {getRoleName(userData.role)}
              </span>{' '}
              {userData.organizationId ? (
                <span className="flex items-center space-x-1 text-gray-500 text-sm w-36">
                  <Dot size="small" color="disabled" />
                  <span className="truncate">In an organisation</span>
                </span>
              ) : null}
            </span>
          }
        />
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
      </div>
      <div
        className={classNames(
          'block  px-8 py-3 text-sm ',
          isAccountSettingsEnabled
            ? 'hover:bg-gray-100 hover:text-gray-900 cursor-pointer text-gray-700'
            : 'text-gray-300 cursor-not-allowed'
        )}
      >
        Account Settings
      </div>
      <div
        role="button"
        tabIndex={0}
        onClick={signOut}
        onKeyPress={signOut}
        className="block hover:bg-gray-100 hover:text-gray-900 cursor-pointer px-8 py-3 text-sm text-gray-700"
      >
        Log Out
      </div>
    </DropdownBase>
  ) : null
}

export default UserDropdownAvatar
