// eslint-disable-next-line import/no-cycle
import { Approval } from './approvals'

export interface Organization {
  id: number
  name: string
  status: OrganizationStatus
  address: string
  website: string
  ownerId: number
  createdAt: string
  updatedAt: string
  approvals: Approval[]
}

export enum OrganizationStatus {
  Active = 'active',
  Pending = 'pending',
  Declined = 'declined',
}
