import { Approval, ApprovalsTypeEnum } from 'src/types'

export const getApprovalType = (approval: Approval) => {
  const approvalType = approval.type

  return {
    isOrganisationType: approvalType === ApprovalsTypeEnum.Organisation,
    isDealType: approvalType === ApprovalsTypeEnum.Deal,
    isTokenMintType: approvalType === ApprovalsTypeEnum.TokenMint,
  }
}

export function getMostRecentApproval(approvals: Approval[]) {
  const [latestApproval] = [...approvals].sort((a, b) => {
    const timeA = new Date(a.createdAt)
    const timeB = new Date(b.createdAt)

    return +timeB - +timeA
  })

  return latestApproval
}
