import {
  Button,
  CheckedDocument,
  ErrorAlert,
  InputBase,
  ModalCloseButton,
  useModal,
} from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { ModalContent } from 'src/components/Modal/InviteModal/ModalContent'
import { useEditOrganisation } from 'src/hooks/Invitation'
import { Organization } from 'src/types'

function EditOrganisationModalContent({ orgData }: { orgData: Organization }) {
  const { enqueueOnCloseCallback } = useModal()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => enqueueOnCloseCallback(() => reset()), [])

  const {
    isLoading,
    isSuccess,
    error,
    mutate: editOrganisation,
  } = useEditOrganisation()

  const onSubmit = ({ name, address, website }: FieldValues) => {
    editOrganisation({ id: orgData.id, name, address, website })
  }

  return (
    <ModalContent title={`${t('organizations.editOrganisationModal.title')}`}>
      {isSuccess ? (
        <>
          <div className="ml-auto my-4 flex w-full flex-row justify-between">
            <div className=" invisible w-10" />
            <div className="mx-auto flex items-center justify-center h-26 w-26 rounded-full">
              <CheckedDocument aria-hidden="true" />
            </div>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-bold text-gray-900">
              {`${t('organizations.editOrganisationModal.successTitle')}`}
            </h3>
            <div className="mt-5 sm:mt-6">
              <ModalCloseButton>
                <Button classes="w-full">{`${t(
                  'organizations.editOrganisationModal.closeButton'
                )}`}</Button>
              </ModalCloseButton>
            </div>
          </div>
        </>
      ) : (
        <form>
          <h2 className="mt-4 text-gray-700 text-lg font-normal">
            {`${t('organizations.editOrganisationModal.description')}`}
          </h2>
          <div className="my-8">
            <InputBase
              label={t('organizations.createOrganisationModal.inputNameLabel')}
              placeholder={t(
                'organizations.createOrganisationModal.inputNamePlaceholder'
              )}
              {...register('name', {
                required: `${t(
                  'organizations.createOrganisationModal.requiredName'
                )}`,
                value: orgData.name,
                validate: (value) =>
                  !!value.trim() ||
                  `${t('organizations.createOrganisationModal.requiredName')}`,
              })}
              type="text"
              error={!!errors.name}
              inputInfo={`${errors.name?.message || ''}`}
            />
          </div>

          <div className="my-8">
            <InputBase
              label={t(
                'organizations.createOrganisationModal.inputAddressLabel'
              )}
              placeholder={t(
                'organizations.createOrganisationModal.inputAddressPlaceholder'
              )}
              {...register('address', {
                required: `${t(
                  'organizations.createOrganisationModal.requiredAddress'
                )}`,
                value: orgData.address,
                validate: (value) =>
                  !!value.trim() ||
                  `${t(
                    'organizations.createOrganisationModal.requiredAddress'
                  )}`,
              })}
              type="text"
              error={!!errors.address}
              inputInfo={`${errors.address?.message || ''}`}
            />
          </div>

          <div className="my-8">
            <InputBase
              label={t(
                'organizations.createOrganisationModal.inputWebsiteLabel'
              )}
              placeholder={t(
                'organizations.createOrganisationModal.inputWebsitePlaceholder'
              )}
              {...register('website', {
                value: orgData.website,
              })}
              cornerOption={`${t(
                'organizations.createOrganisationModal.inputWebsiteCorner'
              )}`}
              type="text"
              error={!!errors.website}
            />
          </div>
          {!!error?.response?.data?.message.length && (
            <ErrorAlert>
              {error?.response?.data?.message.map((mess) => (
                <p key={mess}>{mess}</p>
              ))}
            </ErrorAlert>
          )}
          <div className="my-4 flex flex-row justify-between align-center">
            <Button
              type="submit"
              classes="text-sm font-bold"
              onClick={handleSubmit(onSubmit)}
              size="large"
              disabled={isLoading}
            >
              {`${t('organizations.editOrganisationModal.saveButton')}`}
            </Button>
            <Button variant="alternative" size="large" onClick={() => reset()}>
              {`${t('organizations.editOrganisationModal.discardButton')}`}
            </Button>
          </div>
        </form>
      )}
    </ModalContent>
  )
}

export default EditOrganisationModalContent
