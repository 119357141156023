export const users = {
  sectionHeader: {
    title: 'Users',
    invitationButton: 'Invite user',
  },
  activeUsersTable: {
    header: {
      name: 'Name/Email',
      role: 'Role',
      date: 'Creation date',
      actions: 'Actions',
    },
    rowActions: {
      disable: 'Disable',
      edit: 'Edit',
      disableUserTooltip: `The user still has an open Deal or one <br /> of user's
    invitees has an open Deal.`,
    },
    disableUserModal: {
      title: 'Disable user',
      description: `Are you sure you want to disable the user below? You have the option to re-activate the account later if necessary. `,
      cancel: 'Cancel',
      disable: 'Disable user',
    },
  },
  activeUsersEmptyCard: {
    title: 'No active users',
    content: `There are no active users at the moment. It's either the person you
    invited did not see the invitation email yet, they declined it, or you
    did not invite anyone yet.`,
  },
  editUserPopup: {
    title: 'Edit user',
    successTitle: 'User successfully edited!',
  },
  pendingUsersTable: {
    header: {
      email: 'Email',
      role: 'Role',
      date: 'Invited on',
      actions: 'Actions',
    },
    rowActions: { remove: 'Remove invitation' },
    removeInvitationModal: {
      title: 'Remove invitation',
      description: `Are you sure you want to remove the invitation? <br /> The invited user
      will no longer be able to create an account.`,
      cancel: 'Cancel',
      remove: 'Remove invitation',
    },
  },
  pendingUserEmptyCard: {
    title: 'No pending users',
    content: `There are no pending users at the moment. All the users you invited accepted your invitation, you don’t have any active invitations.`,
  },
  disabledUsersTable: {
    header: {
      name: 'Name/Email',
      type: 'Type',
      role: 'Role',
      date: 'Creation date',
      actions: 'Actions',
    },
    rowActions: { enable: 'Enable' },
    enableUserModal: {
      title: 'Enable user',
      description: `Are you sure you want to enable the user below?`,
      cancel: 'Cancel',
      enable: 'Enable user',
      twoFactorAuthTitle: 'Reset user two-factor authentication',
      twoFactorAuthDescription:
        'Use this option if the user no longer has access to the two-factor authentication',
    },
  },
  disabledUsersEmptyCard: {
    title: 'No disabled users',
    content: `There are no disabled users at the moment.`,
  },
  apiKeys: {
    openButton: 'API key',
    overlayTitle: 'API key',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
    emptyKeyCard: {
      title: 'No key generated',
      description: 'You have not generated any key yet',
      actionButton: 'Generate API key',
    },
    edit: {
      inputLabel: 'Key name',
      placeholder: 'What is this key used for?',
      errorRequired: 'Please enter an API key name',
      errorMinLength: 'Please enter at least one valid character',
      close: 'Close',
      generate: 'Generate API Key',
    },
    copyKey: {
      warning:
        'This is the only time you will see the API key. Please store it in a safe place. You will always have the option to revoke access to the key.',
      close: 'Close',
      copied: 'Key copied',
      copyToClipboard: 'Copy to clipboard',
    },
    revokeKey: {
      expiresLabel: 'Expires on',
      revoke: 'Revoke access',
    },
  },
  common: {
    loadingError:
      'There was an error loading users data. Try to revisit the Users tab later.',
    close: 'Close',
    saveChanges: 'Save changes',
  },
}
