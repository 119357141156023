export const approvals = {
  emptyApprovalsOrgCard: {
    title: 'No organisation approvals',
    description: 'There are no organisation approvals at this time.',
  },
  emptyApprovalsDealCard: {
    title: 'No deals approvals',
    description: 'There are no deals approvals at this time.',
  },

  orgs: {
    searchPlaceholder: 'Search approval',

    types: {
      organization: 'Organisation',
    },
  },

  deals: {
    types: {
      deal: 'Publish Deal',
      'pre-deal': 'Pre-Deal',
      'token-mint': 'Mint tokens',
    },
    badges: {
      approved: 'Approved',
      warning: 'Pending',
      danger: 'Declined',
    },
    decline: {
      deal: {
        title: 'Decline deal',
        description:
          'Please provide a reason to help the creator understand why their deal was declined.',
      },
      tokenMint: {
        title: 'Decline mint',
        description:
          'Please provide a reason to help the creator understand why their deal was declined.',
      },
      reason: 'Reason',
      placeholderReason: 'Provide a reason for decline',
    },
  },
  organizations: {
    decline: {
      organization: {
        title: 'Decline organisation',
        description:
          'Please provide a reason to help the creator understand why their organisation was declined.',
      },
    },
  },
  buttons: {
    approve: 'Approve',
    decline: 'Decline',
    declineWithReason: 'Decline & send reason',
    retry: 'Retry',
    cancel: 'Cancel',
    view: 'View',
  },

  errors: {
    dealDetails: 'There was an error loading deal details.',
  },
}
