import { apiUrl } from 'src/api'
import { Role } from 'src/api/routes/role'
import { InviteRequestBody } from 'src/types/invite'

import { api } from '../axios-base-instance'

export interface InviteTokenRes {
  email: string
  status: string
}

export const getUserInvitation = (token: string) =>
  api.get<InviteTokenRes>(`${apiUrl.invites}${token}`).then(({ data }) => data)

export const postUserInvite = (body: InviteRequestBody) =>
  api.post(apiUrl.invites, body)

export async function getInvites(queryString: string) {
  const { data } = await api.get(apiUrl.invitesQuery(queryString))

  return data
}

export const getAvailableRoles = () => api.get<Role[]>(apiUrl.availableRoles)
