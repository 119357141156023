import { classNames } from '@allinfra-ltd/allinfra-design-system'
import React from 'react'

enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  XLarge = 'x-large',
}

enum Color {
  Disabled = 'disabled',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Info = 'info',
}

enum Variant {
  Outlined = 'outlined',
  Contained = 'contained',
}

interface DotProps {
  children?: React.ReactNode
  size: `${Size}`
  color: `${Color}`
  variant: `${Variant}`
  classes?: string
}

const defaultProps = {
  size: Size.Small,
  color: Color.Disabled,
  variant: Variant.Contained,
  classes: '',
}
function Dot({ children, ...props }: Partial<DotProps>) {
  const { size, color, variant, classes } = { ...defaultProps, ...props }
  const styles = React.useMemo(
    () => useDotStyles({ variant, color, size, classes }),
    [variant, color, size, classes]
  )
  return <span className={styles}>{children}</span>
}

function useDotStyles(args: Partial<DotProps>) {
  const { variant, color, size, classes } = { ...defaultProps, ...args }
  const stylesInventory = {
    [Variant.Contained]: {
      [Color.Success]: {
        style: 'bg-emerald-500',
      },
      [Color.Warning]: {
        style: 'bg-orange-500',
      },
      [Color.Danger]: {
        style: 'bg-red-500',
      },
      [Color.Info]: {
        style: 'bg-blue-500',
      },
      [Color.Disabled]: {
        style: 'bg-gray-300',
      },
      [Size.Small]: {
        style: 'p-1 h-2 w-2',
      },
      [Size.Medium]: {
        style: 'p-1.5 h-4 w-4',
      },
      [Size.Large]: {
        style: 'p-2 h-6 w-6',
      },
      [Size.XLarge]: {
        style: 'p-3 h-8 w-8',
      },
    },
    [Variant.Outlined]: {
      [Color.Success]: {
        style: 'border-emerald-500',
      },
      [Color.Warning]: {
        style: 'border-orange-500',
      },
      [Color.Danger]: {
        style: 'border-red-500',
      },
      [Color.Info]: {
        style: 'border-blue-500',
      },
      [Color.Disabled]: {
        style: 'border-gray-500',
      },
      [Size.Small]: {
        style: 'border h-2 w-2',
      },
      [Size.Medium]: {
        style: 'border-2 h-4 w-4',
      },
      [Size.Large]: {
        style: 'border-2 h-6 w-6',
      },
      [Size.XLarge]: {
        style: 'border-2 h-8 w-8',
      },
    },
    base: {
      style: 'inline-flex rounded-full items-center justify-center',
    },
  }

  return classNames(
    stylesInventory[variant][color].style,
    stylesInventory[variant][size].style,
    stylesInventory.base.style,
    classes
  )
}

export default Dot
