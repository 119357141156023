import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { useCallback, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { getApprovalsType, updateApproval } from 'src/api/routes/approvals'
import {
  ApprovalStatus,
  ApprovalsEntityType,
  UpdateApprovalStatusProps,
  UpdateApprovalStatusQueryOptions,
} from 'src/types'
import { defaultTablePageSize } from 'src/utils'

interface UseApprovals {
  pageSize: number
}

export function useApprovalsOrgsPage({
  pageSize = defaultTablePageSize,
}: UseApprovals) {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const qb = RequestQueryBuilder.create()

  const queryString = qb
    .setPage(page)
    .setLimit(pageSize)
    .setJoin([
      {
        field: 'approvers',
      },
      {
        field: 'createdBy',
      },
      {
        field: 'organisation',
      },
    ])
    .search({
      $and: [
        {
          entityType: {
            $eq: ApprovalsEntityType.Organization,
          },
          $or: [
            {
              'createdBy.firstName': {
                $cont: search,
              },
            },
            {
              'createdBy.lastName': {
                $cont: search,
              },
            },
          ],
        },
      ],
    })

    .query()

  return {
    setPage,
    setSearch,
    ...useQuery([queryKeys.approvals, queryString], () =>
      getApprovalsType(queryString)
    ),
  }
}

export function useApprovalsDealsPage({
  pageSize = defaultTablePageSize,
}: UseApprovals) {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const qb = RequestQueryBuilder.create()

  const queryString = qb
    .setPage(page)
    .setLimit(pageSize)

    .setJoin([
      {
        field: 'approvers',
      },
      {
        field: 'createdBy',
        select: ['id', 'firstName', 'lastName', 'email'],
      },
      {
        field: 'deal',
        select: ['id', 'mainSale', 'stage', 'state'],
      },
      {
        field: 'deal.token',
      },
      {
        field: 'deal.mainSale',
        select: ['id', 'generalInfo'],
      },
      {
        field: 'deal.mainSale.generalInfo',
      },
      {
        field: 'deal.mainSale.specificDetails',
      },
      {
        field: 'deal.mainSale.eligibleInvestors',
      },
      {
        field: 'deal.mainSale.eligibleInvestors.eligibleInvestor',
      },
    ])
    .search({
      $and: [
        {
          entityType: {
            $ne: ApprovalsEntityType.Organization,
          },
          $or: [
            {
              'createdBy.firstName': {
                $cont: search,
              },
            },
            {
              'createdBy.lastName': {
                $cont: search,
              },
            },
          ],
        },
      ],
    })
    .query()

  return {
    setPage,
    setSearch,
    ...useQuery(
      [queryKeys.approvals, queryString],
      () => getApprovalsType(queryString),
      {
        keepPreviousData: true,
      }
    ),
  }
}

const updateApprovalStatus = async (props: UpdateApprovalStatusProps) => {
  const { data } = await updateApproval(props)
  return data
}

export function useUpdateApprovalStatus() {
  const queryClient = useQueryClient()

  const { mutate, ...rest } = useMutation(updateApprovalStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.approvals)
    },
  })

  const approveDeal = useCallback(
    (id: number, options?: UpdateApprovalStatusQueryOptions) =>
      mutate({ id, status: ApprovalStatus.Approved }, options),
    [mutate]
  )

  const declineDeal = useCallback(
    ({
      id,
      reason,
      options,
    }: {
      id: number
      reason?: string
      options?: UpdateApprovalStatusQueryOptions
    }) => mutate({ id, status: ApprovalStatus.Declined, reason }, options),
    [mutate]
  )

  return {
    mutate,
    approveDeal,
    declineDeal,
    ...rest,
  }
}
