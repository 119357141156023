import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { Role, getAvailableRoles } from 'src/api/routes'

const queryAvailableRoles = async () => {
  const { data } = await getAvailableRoles()
  return data
}

export function useAvailableRoles() {
  return useQuery<Role[], AxiosError<any>, Role[], string[]>(
    [queryKeys.avaliableRoles],
    queryAvailableRoles
  )
}
