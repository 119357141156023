import { TextAvatar } from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import {
  getDealTypeData,
  normalizeEligibleInvestorData,
} from 'src/pages/Deals/utils'
import { Deal, DealState } from 'src/types'
import { addThousandsSeparator, turnToUpperCase } from 'src/utils'

export enum InvestorListPreviewAccessor {
  Identifier = 'identifier',
  InvestmentSize = 'investmentSize',
  TokenBalance = 'tokenBalance',
  Email = 'email',
}

export const getColumnsAndData = (deal: Deal) => {
  const dealTypeData = getDealTypeData(deal)
  const dealCurrency = turnToUpperCase(dealTypeData.generalInfo.currency)

  const columnHeaders = [
    {
      match: true,
      Header: t('deals.investorListPreview.identifier'),
      accessor: InvestorListPreviewAccessor.Identifier,
    },
    {
      match: [
        DealState.DealActive,
        DealState.DealClosed,
        DealState.MintPendingApproval,
        DealState.MintApproved,
      ].includes(deal.state),
      Header: `${t(
        'deals.investorListPreview.investmentSize'
      )} - ${dealCurrency}`,
      accessor: InvestorListPreviewAccessor.InvestmentSize,
    },
  ]

  const rowData = normalizeEligibleInvestorData(deal).map(
    ({ externalId, investorMetadata }) => ({
      [InvestorListPreviewAccessor.Identifier]: (
        <TextAvatar primaryContent={`${externalId}`} />
      ),
      [InvestorListPreviewAccessor.InvestmentSize]: investorMetadata?.fiat
        ? `${addThousandsSeparator(investorMetadata?.fiat)} ${dealCurrency}`
        : '-',
      [InvestorListPreviewAccessor.TokenBalance]: investorMetadata?.token
        ? `${addThousandsSeparator(investorMetadata?.token)} ${turnToUpperCase(
            deal.token?.symbol
          )}`
        : '-',
    })
  )

  return {
    columnHeaders: columnHeaders.filter((header) => header.match),
    rowData,
  }
}
