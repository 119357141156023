import { classNames } from '@allinfra-ltd/allinfra-design-system'
import { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'

export interface Tab {
  content: ReactNode | string
  path: string
}

export interface NavTabsProps {
  tabsConfig: Tab[]
}

function NavTabs({ tabsConfig, ...props }: NavTabsProps) {
  return (
    <nav className="flex space-x-8" {...props}>
      {tabsConfig.map((tab: Tab) => (
        <NavLink
          key={tab.path}
          to={tab.path}
          className={({ isActive }) =>
            classNames(
              isActive
                ? 'border-blue-500'
                : 'border-transparent text-gray-500 hover:text-gray-900 font-bold hover:border-gray-300',
              'whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm'
            )
          }
        >
          {tab.content}
        </NavLink>
      ))}
    </nav>
  )
}

export default NavTabs
