import {
  DataGrid,
  DataGridProps,
  StyleTemplates,
} from '@allinfra-ltd/allinfra-design-system'
import { TableOptions } from 'react-table'

function DealDetailsGrid(props: DataGridProps) {
  const { columns, data, ...rest } = props as Pick<
    TableOptions<{}>,
    'columns' | 'data'
  >

  return (
    <DataGrid
      columns={columns}
      data={data}
      styleTemplates={[StyleTemplates.StripedRows]}
      customStyles={{ th: 'w-1/2' }}
      {...rest}
    />
  )
}

export default DealDetailsGrid
