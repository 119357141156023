import {
  CreateDealMachineAction as Action,
  CreateDealMachineContext,
  CreateDealMachineEvent as Event,
  CreateDealMachineState as State,
} from 'src/types'
import { assign, createMachine } from 'xstate'

export const initialContextState: CreateDealMachineContext = {
  activeDealStepIndex: 0,
  isPreDealActive: false,
  flatDealData: {},
  selectedEligibleInvestorsFlatRows: [],
}
export const createDealMachine = createMachine({
  id: 'createDeal',
  initial: State.Idle,
  context: initialContextState,
  states: {
    [State.Idle]: {
      entry: [Action.NavigateToRootDeals],
      on: {
        [Event.Init]: {
          target: State.SettingGeneralInfo,
        },
      },
    },
    [State.SettingGeneralInfo]: {
      entry: [assign({ activeDealStepIndex: 0 })],
      on: {
        [Event.Next]: {
          actions: [Action.SetFlatDealData],
          target: State.SettingSpecificDetails,
        },
        [Event.Previous]: {
          target: State.Idle,
        },
      },
    },
    [State.SettingSpecificDetails]: {
      entry: [assign({ activeDealStepIndex: 1 })],
      on: {
        [Event.Next]: {
          actions: [Action.SetFlatDealData],
          target: State.SettingEligibleInvestors,
        },
        [Event.Previous]: {
          target: State.SettingGeneralInfo,
        },
      },
    },
    [State.SettingEligibleInvestors]: {
      entry: [assign({ activeDealStepIndex: 2 })],
      on: {
        [Event.Next]: {
          actions: [Action.SetFlatDealData],
          target: State.SettingPreDealInvestors,
        },
        [Event.Previous]: {
          target: State.SettingSpecificDetails,
        },
        [Event.Created]: {
          target: State.Created,
        },
      },
    },
    [State.SettingPreDealInvestors]: {
      entry: [assign({ activeDealStepIndex: 3 })],
      on: {
        [Event.Next]: {
          actions: [Action.SetFlatDealData],
          target: State.Created,
        },
        [Event.Previous]: {
          target: State.SettingEligibleInvestors,
        },
        [Event.Created]: {
          target: State.Created,
        },
      },
    },
    [State.Created]: {
      entry: [Action.ResetDealMachineContext],
      always: [{ target: State.Idle }],
    },
  },
  on: {
    [Event.Reset]: {
      actions: [Action.ResetDealMachineContext],
    },
    [Event.GoIdle]: {
      target: State.Idle,
    },
    [Event.UpdateContext]: {
      actions: [Action.UpdateDealMachineContext],
    },
  },
})
