import { User, UserWallet } from './users'

export enum TransactionAction {
  Mint = 'mint',
  Transfer = 'transfer',
  ForceTransfer = 'force-transfer',
  Burn = 'burn',
}

export enum TransactionStatus {
  Pending = 'pending',
  Success = 'success',
  Failed = 'failed',
}

export enum TransactionUserType {
  Sender = 'sender',
  Recipient = 'recipient',
  Signer = 'signer',
}
export interface Transaction {
  id: number
  status: TransactionStatus
  action: TransactionAction
  amount: number
  transactionHash: string
  confirmedAt: Date
  signer?: User
  signerWallet?: UserWallet
  recipient?: User
  recipientWallet?: UserWallet
  sender?: User
  senderWallet?: UserWallet
}
