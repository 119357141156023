import { Outlet } from 'react-router-dom'
import { NavTabs, SectionHeader } from 'src/components'

export default function Approvals() {
  return (
    <div className="flex flex-col h-full m-h-full">
      <SectionHeader sectionTitle={<div>Approvals</div>}>
        <NavTabs
          tabsConfig={[
            { content: 'Organisations', path: 'organizations' },
            { content: 'Deals', path: 'deals' },
          ]}
        />
      </SectionHeader>
      <div className="flex-1 overflow-y-scroll overflow-x-hidden">
        <Outlet />
      </div>
    </div>
  )
}
