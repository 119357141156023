import { api, apiUrl } from 'src/api'
import { ApiKey, CreateApiKeyBody } from 'src/types/api-key'

export async function getApiKeys(queryString: string) {
  const { data } = await api.get(apiUrl.apiKeysQuery(queryString))

  return data
}

export async function createApiKey({ name, expireAt }: CreateApiKeyBody) {
  const { data } = await api.post<ApiKey>(apiUrl.apiKeys, { name, expireAt })

  return data
}

export async function putApiKey({ id }: { id: number }) {
  const { data } = await api.put(apiUrl.apiKeyRevoke(id))

  return data
}
