import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { deleteOrganizationInvite } from 'src/api/routes'
import { ErrorResponse } from 'src/types/errors'

const deleteInvite = async ({
  orgId,
  inviteId,
}: {
  orgId: number
  inviteId: number
}) => deleteOrganizationInvite({ orgId, inviteId })

export default function useUserInvitationDelete() {
  const queryClient = useQueryClient()
  return useMutation<
    unknown,
    AxiosError<ErrorResponse>,
    { orgId: number; inviteId: number },
    unknown
  >(deleteInvite, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.orgUsers)
    },
  })
}
