export const deals = {
  toBeDone: 'To Be Done ...',
  reasonForDecline: 'Reason for decline',
  noReason: 'No reason has been provided',
  totalInvestmentSize: 'Total investment size',
  maxInvestmentSize: 'Max. investment size',
  currentTokenHolders: 'Current token holders',
  totalSupply: 'Total supply',
  tokensInCirculation: 'Tokens in circulation',
  burnedTokens: 'Burned tokens',
  close: 'Close',
  retry: 'Retry',
  mintTokens: 'Mint tokens',
  transferTokens: 'Transfer tokens',
  burnTokens: 'Burn Tokens',
  mint: 'Mint',
  burnMultiple: 'Burn multiple',
  forceTransfer: 'Force Transfer',
  transferAdditionalTokens: 'Transfer Additional Tokens',
  reissueTokens: 'Reissue Tokens',

  alerts: {
    transactionsInProgress:
      'Transactions are in progress. Some details may be outdated until the process is completed.',
  },

  currencies: {
    usd: 'USD',
    eur: 'EUR',
    jpy: 'JPY',
    hkd: 'HKD',
  },

  createDealCard: {
    title: 'No deals created',
    content: 'There are currently no deals created.',
    actionButton: 'Create deal',
  },

  initialInvestors: {
    listDescription: 'Select investors to transfer tokens',
    transferInProgress:
      'Transfers are in progress. You will see the updated balances when the process finishes.',
    actions: {
      transferToken: {
        title: 'Transfer Tokens',
        actionButtonText: 'Transfer Tokens',
        descriptions: `You will send a total of <strong>{{tokens}}</strong> to a number of <strong>{{Investors}} investors</strong>. Do you want to do the transfer now?`,
        success: {
          title: 'Transaction Sent',
          actionButtonText: 'View Current Investors',
          descriptions:
            'You successfully sent a transaction to transfer a total of <strong>{{tokens}}</strong> to a number of <strong>{{Investors}} investors</strong>.The transaction may still be pending when you go back to the current investors, but it will be confirmed soon.',
        },
      },
    },
  },

  currentHolders: {
    listDescription: 'Select investors to burn tokens',
    emptyState: {
      primaryText: 'No token holders',
      secondaryText:
        'There are no current token holders to load at the moment.',
    },
    actions: {
      transferAdditionalTokens: {
        title: 'Transfer additional tokens',
        description:
          'You are about to add more tokens to this investor wallet and change it’s investment size',
        balanceLabel: 'Current account balance',
        transferActionButton: 'Transfer tokens',
        successTitle: 'Transaction sent',
        successDescription:
          'You successfully sent a transaction to add <strong>{{token}}</strong> to <strong>{{externalId}}</strong> wallet.The transaction may still be pending when you go back to the current investors, but it will be confirmed soon.',
        successActionButton: 'View current investors',
        errorMultiplier: 'Amount must be a multiplier of increment amount size',
      },
      forceTransferTokens: {
        title: 'Force transfer tokens',
        description:
          'This action will remove the tokens from the investor’s wallet and send them to your wallet. Please give a reason for this transfer',
        reasonLabel: 'Reason',
        reasonPlaceholder:
          'Describe why you decided to force transfer the tokens',
        transferActionButton: 'Force transfer tokens',
        successDescription:
          'You successfully sent a transaction to force transfer <strong>{{token}}</strong> from <strong>{{externalId}}</strong> wallet to your wallet.The transaction may still be pending when you go back to the current investors, but it will be confirmed soon.',
      },
      addNewInvestor: {
        title: 'Add new investor',
        description:
          'Add new investor to this deal and send the corresponding number of tokens to the investor’s address',
        selectInvestorLabel: 'Choose Investor',
        errorMultiplier: 'Amount must be a multiplier of',
        errorSelectInvestor: 'Please select one investor',
        errorMinInvestmentSize:
          'Amount should be at least the Minimum investment size',
        errorRemainingAmount:
          'Amount should not exceed the undistributed amount of tokens',
        submitActionButton: 'Add investor & transfer tokens',
        successTitle: 'Investor added',
        successDescription:
          'You successfully added investor <strong>{{externalId}}</strong> as an investor to this deal.',
        successActionButton: 'View current investors',
        openActionButton: 'Add investor',
      },
      burnTokens: {
        title: 'Burn tokens',
        description:
          'You are going to burn tokens from the wallet of the user below. Please choose the amount you want to burn.',
        successDescription:
          'You successfully sent a transaction to burn <strong>{{token}}</strong> from <strong>{{externalId}}</strong>.The transaction may still be pending when you go back to the current investors, but it will be confirmed soon.',
        successMultipleDescription:
          'You successfully sent a transaction to burn a total <strong>{{tokenSum}}</strong> <strong>{{symbol}}</strong> from <strong>{{totalInvestors}}</strong> investors wallets.The transaction may still be pending when you go back to the current investors, but it will be confirmed soon.',
        warningMessage:
          'Burning tokens will remove the tokens from this wallet forever.',
        warningMultipleMessage:
          'Burning tokens will remove the tokens from the wallets forever.',
        burnActionButton: 'Burn Tokens',
      },
      reissueTokens: {
        title: 'Reissue tokens',
        description:
          'Reissuing tokens will result in transferring the entire token balance to a newly generated wallet, while access to the old wallet will be lost.',
        warningMessage:
          'This action cannot be undone. The current Investor wallet address will be lost.',
        reissueActionButton: 'Reissue tokens',
        successDescription:
          'You successfully sent the transaction to reissue  the tokens for  <strong>{{externalId}}</strong>. The transaction may still be pending when you go back to the current investors, but it will be confirmed soon.',
      },
      mint: {
        title: 'Mint tokens',
        description: 'This action will mint tokens for the investor below.',
        mintActionButton: 'Mint',
        errorAmountBurnedTokens:
          'Amount cannot be greater than the number of burned tokens',
        successDescription:
          'You successfully sent a transaction to mint <strong>{{tokenSum}}</strong> <strong>{{symbol}}</strong> to <strong>{{externalId}}</strong>’s wallet.The transaction may still be pending when you go back to the current investors, but it will be confirmed soon.',
      },
      shared: {
        amountLabel: 'Amount',
        errorRequired: 'Amount is required',
        errorExceedsBalance: 'Amount exceeds maximum available balance',
        errorPositiveAmount: 'Amount must be a positive value',
        successTitle: 'Transaction sent',
        viewInvestorsActionButton: 'View current Investors',
        cancelActionButton: 'Cancel',
      },
    },
  },

  tabNames: {
    dealDetails: 'Deal details',
    currentHolders: 'Current holders',
    transactions: 'Transactions',
    initialInvestors: 'Initial investors',
    investors: 'Investors',
  },

  investorListPreview: {
    identifier: 'Investor',
    investmentSize: 'Investment size',
    tokenBalance: 'Token balance',
    email: 'Email',
  },

  dealsList: {
    dealStates: {
      publishPendingApproval: 'Pending publish approval',
      dealPublished: 'Deal published',
      activeDeal: 'Active deal',
      dealPublishDeclined: 'Deal publish declined',
      dealPublishProgress: 'Deal publish in progress',
      dealPublishFailed: 'Deal publish failed',
      pendingMintApproval: 'Pending mint approval',
      mintApproved: 'Mint approved',
      mintRejected: 'Mint declined',
      mintInProgress: 'Mint in progress',
      mintFailed: 'Mint failed',
      draft: 'Draft',
      unknown: 'Unknown Deal State',
      dealClosed: 'Deal closed',
    },
    headers: {
      name: 'Name/Description',
      symbol: 'Ticker symbol',
      status: 'Status',
      actions: 'Actions',
    },
    actionButtons: {
      view: 'View',
      mint: 'Mint',
      requestMint: 'Request Mint',
      publish: 'Publish',
    },
  },

  dealDetailsPreview: {
    tickerSymbol: 'Ticker symbol',
    currency: 'Deal currency',
    type: 'Deal type',
    dealIssuer: 'Issuer',
    ranking: 'Ranking',
    anticipatedIssuanceDate: 'Anticipated issuance date',
    incrementAmountSize: 'Increment amount size',
    negativePledge: 'Negative pledge',
    tenor: 'Tenor',
    coupon: 'Coupon % per annum',
    couponPeriod: 'Coupon payment period',
    baseIssueSize: 'Base issue size',
    maxIssueSize: 'Maximum issue size',
    minInvestSize: {
      primaryText: 'Minimum investment size',
      secondaryText: 'per investor',
    },
    address: 'Address',
  },

  dealDetailsTransactionList: {
    pending: 'Pending',
    emptyStateTitle: 'No transactions',
    emptyStateMessage: 'There are no transactions available at this time',
    listHeaderActions: {
      action: 'Action',
      user: 'User',
      from: 'From',
      to: 'To',
      amount: 'Amount',
      date: 'Date',
      hash: 'Tx hash',
    },
    actionType: {
      mint: 'Mint',
      transfer: 'Transfer',
      burn: 'Burn',
      'force-transfer': 'Force Transfer',
    },
  },

  create: {
    overlay: { title: 'New deal' },
    generalInfo: {
      title: 'General Information',
      description: `Define your deal's use of proceeds. Give it a short description to help investors and regulators. Add a pre-deal phase to gather feedback from the investors.`,
      proceedsInputLabel: 'Use of proceeds',
      proceedsInputError: 'Please enter use of proceeds',
      descriptionInputLabel: 'General Description',
      descriptionInputError: 'Please enter description',
      descriptionPlaceHolder: 'Describe the deal in a few words',
      currencySelectLabel: 'Deal currency',
      currencySelectError: 'Please select currency',
      currencyPlaceholder: 'Choose deal currency',
      symbolInputLabel: 'Ticker Symbol',
      symbolPlaceholder: 'Symbol used to trade the bond',
      symbolInputError: 'Please enter symbol',
      anticipatedIssuanceDateLabel: 'Anticipated issuance date',
      anticipatedIssuanceDatePlaceholder:
        'Choose an approximate date for bond issuance',
      togglePreDealLabel: 'Add a pre-deal phase',
      togglePreDealDescription: `Checking this box will add an additional phase to you deal. This will help you gather feedback from investors before publishing the deal. After this phase ends, you will be able to update deal parameters based on the received feedback`,
      preDealInputLabel: 'Pre-deal response date limit',
      preDealPlaceholder: 'Choose a deadline for pre-deal responses',
      cancelActionButton: 'Cancel',
      submitButton: 'Next step',
      datePicker: {
        required: 'Please choose a deadline for pre-deal response',
        dateValidation: 'Date needs to be later than today',
        requiredAnticipated: 'Please choose the anticipated issuance date',
      },
    },
    specificDetails: {
      title: 'Deal specific details',
      description: `This step lets you customise your deal in detail. Make sure you duble
      check all the parameters. If this is a regulated deal, you may check
      the option at the end.`,
      rankingSubtitle: `Tenor, Ranking & Negative pledge`,
      tenorInputLabel: 'Tenor',
      tenorPlaceholder: 'Period in number of years',
      tenorInputError: 'Please select period in number of years',
      rankingInputLabel: 'Ranking',
      rankingPlaceholder: 'Choose ranking',
      rankingInputError: 'Please choose ranking',
      pledgesLabel: 'Negative Pledges',
      pledgesInputLabel: 'Describe negative pledge',
      pledgesYesLabel: 'Yes',
      pledgesNoLabel: 'No',
      couponSubtitle: 'Coupon configuration',
      couponInputLabel: '% per annum',
      couponInputError: 'Please input number',
      couponPlaceholder: 'Set coupon percentage',
      couponPeriodInputLabel: 'Coupon payment period',
      couponPeriodPlaceholder: 'Choose period',
      couponPeriodInputError: 'Please choose coupon payment period',
      bondSubtitle: 'Bond & Investment configuration',
      bondInputLabel: 'Increment amount size per bond',
      bondInputError: 'Please input Increment amount size per bond',
      bondPlaceholder: '0.00',
      baseIssueInputLabel: 'Base issue size',
      baseIssueInputError: 'Please input Base Issue size',
      baseIssuePlaceholder: '0.00',
      maxIssueInputLabel: 'Maximum Issue size',
      maxIssuePlaceholder: '0.00',
      maxIssueInputError: 'Please input Maximum Issue size',
      minInvestInputLabel: 'Minimum investment size (per Investor)',
      minInvestPlaceholder: '0.00',
      minInvestInputError: 'Please input Minimum investment size',
      minValueInputError: 'No negative values allowed',
      multipleOfAmount: 'Value must be a multiple of amount size per bond',
      toggleRegulatedDealLabel: 'This is a regulated deal',
      regulatedDealMessage:
        'If you check this box, aside for an administrator, a regulator will need to approve your deal before being published.',
      regulatorIdLabel: 'Regulator',
      regulatorIdPlaceholder: 'Choose regulator',
      regulatorIdInputError: 'Please choose regulator',
      backActionButton: 'Back',
    },
    eligibility: {
      title: 'Deal eligibility',
      backButtonText: 'Back',
      submitButtonText: {
        isCreateMainDealSuccess: 'Request created. Closing in ',
        isPreDealActive: 'Next step',
        isCreateMainDealError: 'There was an error. Please try again in ',
      },
      secondsLowerCase: ' seconds',
      description: 'Select all the investors that are eligible for the deal.',
      userLowerCase: 'user',
      usersLowerCase: 'users',
      usersCapitalized: 'Users',
      usersColumnHeader: 'User Id',
      selectedLowerCase: 'selected',
      emailLowerCase: 'email',
      emailUpperCase: 'Email',
    },
    solicitation: {
      title: 'Pre-deal solicitation',
      description:
        'Out of the eligible investors, you can choose which ones get a pre-deal solicitation. Gather feedback through the pre-deal phase and update the deal terms accordingly.',
      backButtonText: 'Back',
      submitButtonText: {
        default: 'Create pre-deal',
        isSuccess: 'Request created. Closing in ',
        isError: 'There was an error. Please try again in ',
      },
    },
    smartContractDetails: { title: 'Smart Contract Details' },
  },

  update: {
    updateButtonText: 'update',
    submitButtonText: 'Submit Change',
  },

  modals: {
    error: {
      title: 'Something went wrong',
      description:
        'An error occured while sending your transaction. Please try again later.',
    },
  },

  requestMint: {
    errors: {
      investmentSizeExceeded: 'The total investment size must not exceed',
      investmentSizesNotFilled: 'Please fill in the investment sizes.',
      incrementAmountSizeMultiple:
        'Amount must be a multiple of the increment amount size',
      minimumInvestmentSize: 'Amount must be at least',
    },
    investmentColumnHeaders: {
      identifier: 'Name/Email',
      investment: 'Investment size',
    },
    dealDetailsColumnHeaders: {
      generalInfo: 'General Information',
      specificDetails: 'Deal specific details',
    },
  },

  errorCode: {
    badRequestDealTokensLeft:
      'Value exceeds the remaining amount of transferable tokens',
  },
}
