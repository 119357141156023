import { ErrorAlert, TooltipBase } from '@allinfra-ltd/allinfra-design-system'
import { useState } from 'react'
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PasswordInput } from 'src/components'

export interface TooltipValidationInputModel {
  text: string
  pattern: RegExp
}

export interface Props {
  isError: boolean
  inputValue: string
  register: UseFormRegister<FieldValues>
  fieldErrors: FieldErrors
  errorAlert?: string
  validationData: TooltipValidationInputModel[]
}

function TooltipValidatedInput({
  isError,
  register,
  fieldErrors,
  errorAlert,
  inputValue = '',
  validationData,
  ...props
}: Props) {
  const [showTooltip, setShowTooltip] = useState(false)

  const { t } = useTranslation()
  const validateRegex = (pattern: RegExp) => {
    const matches = inputValue.match(pattern)
    return !!inputValue && !!matches && matches.length
  }

  const renderContent = (isError: boolean) => {
    return (
      <div className="relative z-10 rounded-xl text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">
        {validationData.map((val) => {
          const isActive = validateRegex(val.pattern)
          const errorTextStyles = isError ? 'text-red-600' : 'text-white'
          const errorBulletStyles = isError
            ? 'bg-red-600 border-red-600'
            : 'bg-gray-900 border-2 border-gray-500'
          return (
            <p
              key={val.text}
              className={` ${
                isActive ? 'text-green-500' : errorTextStyles
              } text-base flex items-center justify-left`}
            >
              <span
                className={`w-3 h-3 mr-2.5 rounded-full ${
                  isActive ? 'bg-green-500' : errorBulletStyles
                } `}
              />
              {val.text}
            </p>
          )
        })}
      </div>
    )
  }

  const handleFocusInput = () => {
    setShowTooltip(true)
  }

  const handleBlur = () => {
    setShowTooltip(false)
  }

  const validationRules = validationData?.reduce((acc, curr) => {
    acc[curr.text] = (value) => curr.pattern.test(value)
    return acc
  }, {} as { [key: string]: (value: string) => boolean })

  return (
    <div>
      <TooltipBase
        content={renderContent(!!(fieldErrors && fieldErrors.password))}
        isTooltipVisible={showTooltip}
        placement="right"
        wrapperClasses="w-full"
      >
        <PasswordInput
          label={t('input.tooltipValidation.passwordLabel')}
          register={register}
          error={!!fieldErrors.password}
          inputInfo={
            fieldErrors.password && t('input.password.validationError')
          }
          validationRules={validationRules}
          onFocus={handleFocusInput}
          onBlur={handleBlur}
          {...props}
        />
      </TooltipBase>
      {isError && (
        <ErrorAlert>
          <p>{errorAlert}</p>
        </ErrorAlert>
      )}
    </div>
  )
}

export default TooltipValidatedInput
