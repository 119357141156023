import {
  Button,
  CheckedDocument,
  CloseIcon,
  ErrorAlert,
  ModalBase,
} from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'
import useUserInvitationDelete from 'src/hooks/Invitation/useUserInvitationDelete'
import { Invite } from 'src/types/invite'

export interface RemoveInvitationModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  selectedUser: Invite
  orgId: number
}

function RemoveInvitationModal({
  isOpen,
  setIsOpen,
  selectedUser,
  orgId,
}: RemoveInvitationModalProps) {
  const { handleSubmit } = useForm({ mode: 'onTouched' })

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    mutate: deleteInvitation,
  } = useUserInvitationDelete()

  const handleRemoveInvitation = () => {
    if (selectedUser && selectedUser.id)
      deleteInvitation({ orgId, inviteId: selectedUser.id })
  }

  return (
    <ModalBase {...{ isOpen, setIsOpen }}>
      {isSuccess ? (
        <>
          <div className="ml-auto flex w-full flex-row justify-between">
            <div className=" invisible w-10" />
            <div className="mx-auto flex items-center justify-center h-26 w-26 rounded-full">
              <CheckedDocument aria-hidden="true" />
            </div>
            <div className="h-10 w-10 cursor-pointer rounded-full  p-2 hover:shadow-sm active:shadow-inner">
              <CloseIcon onClick={() => setIsOpen(false)} />
            </div>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-bold text-gray-900">
              {`${t('invite.removeInvite.success.title')}`}
            </h3>
            <div className="mt-5 sm:mt-6">
              <Button classes="w-full" onClick={() => setIsOpen(false)}>
                {`${t('invite.success.button')}`}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="px-4 pt-3 pb-4">
          <div className="ml-auto flex w-full flex-row justify-between items-center">
            <h4 className="text-gray-900 text-lg font-bold">
              {`${t('invite.removeInvite.title')}`}
            </h4>
            <div className="h-10 w-10 cursor-pointer rounded-full  p-2 hover:shadow-sm active:shadow-inner">
              <CloseIcon
                onClick={() => {
                  setIsOpen(false)
                }}
              />
            </div>
          </div>
          <p className="my-8 text-gray-500 text-sm font-normal">
            {`${t('invite.removeInvite.description')}`}
          </p>
          {isError ? (
            <ErrorAlert>{error?.response?.data?.message}</ErrorAlert>
          ) : null}
          <form onSubmit={handleSubmit(handleRemoveInvitation)} noValidate>
            <div className="my-4 flex flex-row justify-between">
              <Button
                size="medium"
                variant="alternative"
                onClick={() => setIsOpen(false)}
              >
                {`${t('invite.removeInvite.cancel')}`}
              </Button>
              <Button
                size="medium"
                variant="danger"
                classes="w-2/3"
                disabled={isLoading}
                type="submit"
              >
                {`${t('invite.removeInvite.disable')}`}
              </Button>
            </div>
          </form>
        </div>
      )}
    </ModalBase>
  )
}

export default RemoveInvitationModal
