import { useActor, useInterpret } from '@xstate/react'
import React, { createContext, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { createDealMachine, getActions } from 'src/pages/Deals/context/machine'
import { subPaths } from 'src/routes/subpath-schema-tree'
import {
  CreateDealFlatData,
  CreateDealMachineContext,
  CreateDealMachineEvent as Event,
  CreateDealMachineState as State,
} from 'src/types'
import { InterpreterFrom } from 'xstate'

export const CreateDealContext = createContext({
  createDealService: {} as InterpreterFrom<typeof createDealMachine>,
})

export const CreateDealProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const navigate = useNavigate()
  const createDealService = useInterpret(createDealMachine, {
    actions: getActions({ navigate, subPaths }),
  })
  const value = React.useMemo(
    () => ({ createDealService }),
    [createDealService]
  )

  return (
    <CreateDealContext.Provider value={value}>
      {children}
    </CreateDealContext.Provider>
  )
}

export function useCreateDeal() {
  const createDealContext = React.useContext(CreateDealContext)
  const isContextDefaultValue = !Object.keys(
    createDealContext.createDealService
  ).length

  if (isContextDefaultValue) {
    throw new Error('useCreateDeal must be used within a CreateDealProvider')
  }
  const [currentState, send] = useActor(createDealContext.createDealService)

  const goIdle = useCallback(() => send(Event.GoIdle), [send])
  const goToFirstStep = useCallback(() => send(Event.Init), [send])
  const goBack = useCallback(() => send(Event.Previous), [send])
  const goNextAndSave = useCallback(
    (payload?: Partial<CreateDealFlatData>) => {
      send({ type: Event.Next, payload })
    },
    [send]
  )
  const goToCreated = useCallback(() => send(Event.Created), [send])
  const resetState = useCallback(() => send(Event.Reset), [send])
  const updateDealMachineContext = useCallback(
    (payload: Partial<CreateDealMachineContext>) =>
      send({ type: Event.UpdateContext, payload }),
    [send]
  )

  return {
    currentState,
    context: currentState.context,
    send,
    isIdle: currentState.matches(State.Idle),
    goIdle,
    goToFirstStep,
    goBack,
    goNextAndSave,
    goToCreated,
    resetState,
    updateDealMachineContext,
  }
}
