import { useCallback, useReducer, useRef } from 'react'

import useSafeDispatch from './useSafeDispatch'

enum Status {
  Idle = 'IDLE',
  Loading = 'LOADING',
  Rejected = 'REJECTED',
  Resolved = 'RESOLVED',
}
interface StateShape<T, E> {
  status: Status
  data: T | null
  error: E | null
}

function useAsync<TData = unknown, TError = unknown>(initialState?: any) {
  const defaultInitialState: StateShape<TData, TError> = {
    status: Status.Idle,
    data: null,
    error: null,
  }

  const initialStateRef = useRef({
    ...defaultInitialState,
    ...initialState,
  })

  const [{ status, data, error }, setState] = useReducer(
    (
      state: StateShape<TData, TError>,
      action: (
        state: Partial<StateShape<TData, TError>>
      ) => StateShape<TData, TError>
    ) => ({
      ...state,
      ...action,
    }),
    initialStateRef.current
  )

  const setStateSafely = useSafeDispatch(setState) // only setState if component is *still* mounted

  const setData = useCallback(
    (data: any) => setStateSafely({ data, status: Status.Resolved }),
    [setStateSafely]
  )
  const setError = useCallback(
    (error: any) => setStateSafely({ error, status: Status.Rejected }),
    [setStateSafely]
  )
  const reset = useCallback(
    () => setStateSafely(defaultInitialState),
    [setStateSafely]
  )

  const run = useCallback(
    (promise: Promise<any>) => {
      if (!promise || !promise.then) {
        throw new Error('The argument passed to useAsync.run must be a promise')
      }

      setStateSafely({ status: Status.Loading })
      return promise.then(
        (data) => {
          setData(data)
          return data
        },
        (error) => {
          setError(error)
          return Promise.reject(error)
        }
      )
    },
    [setStateSafely, setData, setError]
  )

  return {
    // using the same names as react-query, for convenience
    isIdle: status === Status.Idle,
    isLoading: status === Status.Loading,
    isError: status === Status.Rejected,
    isSuccess: status === Status.Resolved,

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  }
}

export default useAsync
