export const invite = {
  form: {
    title: 'Invite user',
    description:
      'The user will receive an email with an invitation to join Allinfra Digital.',
    role: {
      required: 'Please select a role',
      placeholder: 'Select a role',
    },
    submit: 'Send Invite',
  },
  success: {
    title: 'Invitation sent!',
    descriptionFirstLine: 'You successfully sent the invitation to',
    descriptionSecondLine: 'Check the pending users to see the invitation.',
    button: 'Go to pending users',
  },
  removeInvite: {
    title: 'Remove invitation',
    description:
      'Are you sure you want to remove the invitation? The invited user will no longer be able to create an account if they don’t already have one.',
    cancel: 'Cancel',
    disable: 'Disable user',
    success: {
      title: 'Invitation successfully removed!',
    },
  },
}
