import { Button, CheckboxInput } from '@allinfra-ltd/allinfra-design-system'
import { useState } from 'react'
import { CSVLink } from 'react-csv'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

interface IRecoveryCodeView {
  recoveryCodes: string[]
  onFinish: () => void
  submitBtnText: string
}

const RecoveryCodeView = ({
  recoveryCodes,
  onFinish,
  submitBtnText,
}: IRecoveryCodeView) => {
  const { t } = useTranslation()
  const [isCheckedStored, setIsCheckedStored] = useState(false)
  const onToggleCheckbox = () => setIsCheckedStored(!isCheckedStored)

  const getPrintPageMargins = () => {
    return `@page { margin: 0; }`
  }

  return (
    <>
      <Helmet>
        <style type="text/css" media="print">
          {getPrintPageMargins()}
        </style>
      </Helmet>
      <p className="my-4">{t('auth.signUp.stepThree.instructions')}</p>

      <div className="my-4 p-2 rounded-lg border border-gray-300 bg-gray-100 shadow-sm grid grid-cols-1 font-semibold">
        {recoveryCodes?.map((code: string) => (
          <div className="text-gray-900 text-center m-1" key={code}>
            {code}
          </div>
        ))}
      </div>
      <div className="flex justify-between my-4">
        <CSVLink
          filename="Allinfra_Digital_Recovery_Codes.csv"
          data={recoveryCodes.map((code) => [code])}
          className="text-center bg-transparent text-gray-900 font-bold py-3 px-4 border border-gray-300 rounded w-5/12"
        >
          {t('auth.signUp.stepThree.downloadButton')}
        </CSVLink>

        <Button
          variant="alternative"
          type="button"
          classes="w-5/12"
          onClick={() => window.print()}
        >
          {t('auth.signUp.stepThree.printButton')}
        </Button>
      </div>
      <div className="my-4 text-gray-900">
        <CheckboxInput
          label={t('auth.signUp.stepThree.checkBoxInputLabel')}
          onChange={onToggleCheckbox}
        />
      </div>
      <Button
        type="submit"
        classes="w-full my-4"
        disabled={!isCheckedStored}
        onClick={() => onFinish()}
      >
        {submitBtnText}
      </Button>
    </>
  )
}

export default RecoveryCodeView
