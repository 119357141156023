import {
  CloseIcon,
  ModalCloseButton,
  ModalContentBase,
  ModalContentBaseProps,
} from '@allinfra-ltd/allinfra-design-system'
import { ReactNode } from 'react'

import ModalContentAligner from './ModalContentAligner'

export interface ModalContentProps extends ModalContentBaseProps {
  title?: ReactNode
}
function ModalContent({ title, children, ...props }: ModalContentProps) {
  return (
    <ModalContentBase {...props}>
      <ModalContentAligner>
        <div className="flex justify-between">
          {title ? (
            <h4 className="text-gray-900 text-lg font-bold">{title}</h4>
          ) : null}
          <div className="ml-auto h-10 w-10 cursor-pointer rounded-full p-2 hover:shadow-sm active:shadow-inner">
            <ModalCloseButton>
              <CloseIcon />
            </ModalCloseButton>
          </div>
        </div>
        {children}
      </ModalContentAligner>
    </ModalContentBase>
  )
}

export default ModalContent
