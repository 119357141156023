import { InputBase } from '@allinfra-ltd/allinfra-design-system'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  getMinInvestmentSizeErrorMessage,
  validateMinInvestmentSize,
} from 'src/pages/Deals/CreateDeal/utils'
import { useDealActions } from 'src/pages/Deals/context'
import { getDealTypeData } from 'src/pages/Deals/utils'
import { CreateDealFlatDataKeys as DataKeys, Deal } from 'src/types'

const MinInvestSize = ({ value, id }: { value: string; id: string }) => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext()

  const { state } = useDealActions()
  const { generalInfo, specificDetails } = getDealTypeData(
    state.selectedDealData as Deal
  )

  return (
    <InputBase
      label={t('deals.create.specificDetails.minInvestInputLabel')}
      placeholder={t('deals.create.specificDetails.minInvestPlaceholder')}
      {...register(id, {
        required: t(
          'deals.create.specificDetails.minInvestInputError'
        ) as string,
        min: 0,
        value,
        validate: (val) =>
          validateMinInvestmentSize(
            Number(val),
            Number(getValues(DataKeys.IncrementAmountSize)) ||
              specificDetails.incrementAmountSize
          ),
      })}
      autoFocus
      defaultValue={value}
      error={!!errors[id]}
      inputInfo={errors[id] && getMinInvestmentSizeErrorMessage(errors[id]!)}
      type="number"
      trailingIcon={
        <div className="bg-white px-4 py-2 border rounded-md">
          {t(
            `deals.currencies.${
              getValues(DataKeys.Currency)?.toLowerCase() ||
              generalInfo.currency
            }`
          )}
        </div>
      }
    />
  )
}

export default MinInvestSize
