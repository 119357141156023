/* eslint-disable import/no-cycle */
import { MutateOptions } from 'react-query'

import { Deal } from './deals'
import { Organization } from './organizations'
import { User } from './users'

export enum ApprovalsTypeEnum {
  Organisation = 'organisation',
  Deal = 'deal',
  PreDeal = 'pre-deal',
  TokenMint = 'token-mint',
}

export enum ApprovalsEntityType {
  Organization = 'organization',
  Deal = 'deal',
  PreDeal = 'pre-deal',
}

export enum ApprovalStatus {
  Approved = 'approved',
  Pending = 'pending',
  Declined = 'declined',
}

export interface Approval {
  id: number
  type: ApprovalsTypeEnum
  entityType: ApprovalsEntityType
  entityObjectId: number
  status: ApprovalStatus
  reason?: string
  createdAt: string
  updatedAt: string
  createdBy: User
  organisation: Organization
  deal: Deal
}

export interface Approvals {
  data: Approval[]
  count: number
  total: number
  page: number
  pageCount: number
}

export interface ApprovalActionsState {
  isViewingApprovalDetails: boolean
  selectedApprovalData?: Approval
}
export interface ApprovalActionsContextValue {
  state: ApprovalActionsState
  dispatch: React.Dispatch<Partial<ApprovalActionsState>>
}

export interface ApprovalsBaseOverlayProps {
  selectedApprovalData: Approval
}

export type UpdateApprovalStatusQueryOptions =
  | MutateOptions<
      any,
      unknown,
      {
        id: number
        status: ApprovalStatus
      },
      unknown
    >
  | undefined

export interface UpdateApprovalStatusProps {
  id: number
  status: ApprovalStatus
  reason?: string
}
