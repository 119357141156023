import {
  Avatar,
  Button,
  CheckedDocument,
  ControlledPaginatedTable,
  TextAvatar,
} from '@allinfra-ltd/allinfra-design-system'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { SmallSpinner } from 'src/components'
import { EmptyStateCard } from 'src/components/Card'
import { useOrgUsersPage } from 'src/hooks'
import { Organization } from 'src/types'
import { Invite } from 'src/types/invite'
import { defaultTablePageSize } from 'src/utils'

import { getRoleName } from '../Users/utils'
import RemoveInvitationModal from './RemoveInvitationModal'

const columns = [
  {
    Header: t('organizations.pendingUsersTable.header.email'),
    accessor: 'name',
  },
  {
    Header: t('organizations.pendingUsersTable.header.role'),
    accessor: 'role',
  },
  {
    Header: (
      <span className="ml-2">{`${t(
        'users.pendingUsersTable.header.date'
      )}`}</span>
    ),
    accessor: 'creationDate',
  },
  {
    Header: (
      <span className="mx-auto">
        {`${t('organizations.pendingUsersTable.header.actions')}`}
      </span>
    ),

    accessor: 'actions',
  },
]

function PendingOrgUsersList() {
  const { orgData } = useOutletContext<{ orgData: Organization }>()
  const [selectedUser, setSelectedUser] = useState<Invite>()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const { data, isLoading, setPage, isSuccess } = useOrgUsersPage({
    id: orgData.id,
    status: 'pending',
  })
  const flattenData = ({ data } = { data: [] }) => data
  const tableData = flattenData(data)
  const columnData = useMemo(
    () =>
      tableData.map((user: Invite) =>
        mapServerData({ user, setSelectedUser, setIsOpenModal })
      ),
    [tableData]
  )

  if (isLoading) return <SmallSpinner />
  if (isSuccess) {
    return tableData.length ? (
      <>
        <ControlledPaginatedTable
          columns={columns}
          data={columnData}
          initialState={{ pageSize: defaultTablePageSize }}
          fetchData={({ pageIndex }) => {
            setPage(pageIndex + 1)
          }}
          isLoading={isLoading}
          pageCount={data?.pageCount}
        />
        {selectedUser ? (
          <RemoveInvitationModal
            orgId={orgData.id}
            selectedUser={selectedUser}
            isOpen={isOpenModal}
            setIsOpen={setIsOpenModal}
          />
        ) : null}
      </>
    ) : (
      <div className="flex justify-center items-center">
        <EmptyStateCard
          svgImage={<CheckedDocument />}
          title={t('users.pendingUserEmptyCard.title')}
          text={t('users.pendingUserEmptyCard.content')}
        />
      </div>
    )
  }
  return <SmallSpinner />
}

const mapServerData = ({
  user,
  setSelectedUser,
  setIsOpenModal,
}: {
  user: Invite
  setSelectedUser: (user: Invite) => void
  setIsOpenModal: (val: boolean) => void
}) => {
  return {
    name: (
      <TextAvatar
        avatar={<Avatar>{user?.email?.[0]}</Avatar>}
        secondaryContent={`${user.email}`}
      />
    ),
    role: getRoleName(user.role),
    creationDate: (
      <TextAvatar
        avatar={<div />}
        primaryContent={dayjs(user.createdAt).format('MMMM D, YYYY')}
        secondaryContent={dayjs(user.createdAt).format('h:mm A')}
      />
    ),
    actions: (
      <div className="space-x-2 flex justify-center w-full">
        <Button
          variant="text"
          size="small"
          onClick={() => {
            setSelectedUser(user)
            setIsOpenModal(true)
          }}
        >
          {t('organizations.pendingUsersTable.actions.removeInvitation')}
        </Button>
        <Button variant="text" disabled size="small">
          {t('organizations.pendingUsersTable.actions.view')}
        </Button>
      </div>
    ),
  }
}

export default PendingOrgUsersList
