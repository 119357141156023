interface UserInfoParam {
  password: string
  firstName: string
  lastName: string
}

interface TwoFactorParam {
  code: string
  secret?: string
}

export type SignUpParam = UserInfoParam & TwoFactorParam

export interface Secret {
  secret: string
  qrCodeImage: string
}

export enum SignUpSteps {
  PasswordForm = 0,
  TwoFactorForm,
  RecoveryCodeView,
}
