import { BadgeColor } from '@allinfra-ltd/allinfra-design-system'

export interface BadgeProps {
  color: BadgeColor
  label: string
}

export const dataMap: { [key: string]: BadgeProps } = {
  approved: {
    color: BadgeColor.Success,
    label: 'approvals.deals.badges.approved',
  },
  pending: {
    color: BadgeColor.Warning,
    label: 'approvals.deals.badges.warning',
  },
  declined: {
    color: BadgeColor.Danger,
    label: 'approvals.deals.badges.danger',
  },
}

export const mapBadgeToStatus = (status: string): BadgeProps => {
  return dataMap[status] || { color: BadgeColor.Disabled, label: 'Unknown' }
}
