import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { postCreateDealMainSale } from 'src/api/routes'
import { CreateDealMainSale } from 'src/types'
import { RecursivePartial } from 'src/types/utils'

export function useCreateMainDeal() {
  const queryClient = useQueryClient()
  return useMutation(
    (mainDealData: RecursivePartial<CreateDealMainSale>) =>
      postCreateDealMainSale(mainDealData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.dealsList)
      },
    }
  )
}
