import { CheckedDocument } from '@allinfra-ltd/allinfra-design-system'
import { Card } from 'src/components'

interface Props {
  title: string
  description: string
}

function ApprovalsEmptyState({ title, description }: Props) {
  return (
    <Card classes="bg-slate-100 border border-slate-200">
      <div className="flex flex-col justify-center items-center text-center m-auto w-96 align-middle">
        <CheckedDocument />
        <h2 className="text-gray-900 text-lg font-bold my-4">{title}</h2>
        <p className="text-gray-500 text-sm font-normal mb-4">{description}</p>
      </div>
    </Card>
  )
}

export default ApprovalsEmptyState
