import { TruncateStringOptions } from 'src/types'

export const capitalize = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

export const addThousandsSeparator = (
  targetValue: string | number,
  separator = ','
) => targetValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)

export const turnToUpperCase = (string: string) => (string || '').toUpperCase()

export function truncateString(
  string: string,
  options: Partial<TruncateStringOptions>
) {
  const {
    firstSubstringIndexStart,
    clipFiller,
    firstSubstringIndexEnd,
    secondSubstringIndexStart,
    secondSubstringIndexEnd,
  }: TruncateStringOptions = {
    firstSubstringIndexStart: 0,
    firstSubstringIndexEnd: string.length,
    secondSubstringIndexStart: string.length,
    secondSubstringIndexEnd: string.length,
    clipFiller: '...',
    ...options,
  }

  const firstSubstring = string.slice(
    firstSubstringIndexStart,
    firstSubstringIndexEnd
  )

  const secondSubstring = string.slice(
    secondSubstringIndexStart,
    secondSubstringIndexEnd
  )

  return `${firstSubstring}${clipFiller}${secondSubstring}`
}

export function coerceIntoExternalLinkFormat(url: string): string {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  }
  return `//${url}`
}

export function truncateHash(hash: string) {
  return truncateString(hash, {
    firstSubstringIndexEnd: 6,
    secondSubstringIndexStart: -4,
  })
}

export const trim = (string: string) => String.prototype.trim.call(string)

export const toUpperCase = (string: string) =>
  String.prototype.toUpperCase.call(string)
