import { InfoAlert } from '@allinfra-ltd/allinfra-design-system'
import { InfoAlertProps } from '@allinfra-ltd/allinfra-design-system/dist/components/Alerts/InfoAlert'
import { Transition } from '@headlessui/react'
import React from 'react'

interface PendingActionAlertProps extends InfoAlertProps {
  isVisible: boolean
  className?: string
  children: React.ReactNode
}

function PendingActionAlert({
  isVisible,
  children,
  className,
  ...props
}: PendingActionAlertProps) {
  return (
    <Transition
      show={isVisible}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={className}
    >
      <InfoAlert {...props}>{children}</InfoAlert>
    </Transition>
  )
}

export default PendingActionAlert
