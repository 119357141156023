export const privacy = {
  title: 'Privacy Policy',
  policyDescription:
    '<strong>{{companyName}}</strong> and its related entities ("<strong>we</strong>" or “<strong>us</strong>” or “<strong>{{companyName}}</strong>”) recognise the importance of protecting the privacy and the rights of individuals in relation to their personal data. This privacy policy (“Privacy Policy”) tells you how we will collect, use or disclose your personal data.',
  collectionOfInformation: {
    title: 'Collection of Your Information',
    typesOfDataListDescriptor:
      'We may collect the following types of personal data:',
    typesOfDataList: [
      'Name.',
      'Residential and mailing address.',
      'Email address.',
      'Personal identification document copies (e.g. identity card number or passport number).',
      'Telephone contact number.',
      'Age or birth date.',
      'Bank and brokerage account details including statements.',
      'Professional or job title or occupation.',
      'Usage information, including website browsing data.',
      'Other identification information, including IP address.',
    ],
    infoAlert:
      '{{companyName}} collects personal data directly from you unless it is unreasonable or impracticable to do so.',
    dataCollectionMethodsListDescriptor:
      'When collecting personal data, we may collect it through:',
    dataCollectionMethodsList: [
      'Your access and use of our website.',
      'your company’s purchase of any securities or products offered by {{companyName}}.',
    ],
    warningAlert:
      'If you do not provide us with the personal data described above, we may not be able to provide the requested services to you or issue any tokens to you.',
  },
  purposeOfCollection: {
    title: 'Purpose of Collection, Use and Disclosure',
    listDescriptor:
      'We collect, use and disclose your personal data for the following purposes:',
    list: [
      'To administer any pre-sale of tokens, any token offering and/or the issuance of tokens to you or your company pursuant to any such pre-sale or token offering should they occur.',
      'To provide you with access to certain areas of our website.',
      'To answer enquiries and provide information or advice on our products and services.',
      'To update our records and keep your contact details up to date.',
      'To process and respond to any enquiries made by you.',
      'To conduct the marketing activities set out in paragraph 4 below.',
      'To analyse and conduct research on your use of our website which will then be used to determine customer service and website needs.',
      'To comply with legal processes such as to detect and prevent any fraudulent activity or the like; and',
      'To comply with any law, rule, regulation, lawful and binding determination, decision or direction of a regulator, or to co-operate with any governmental authority.',
    ],
    infoAlert:
      'We will not be sharing, selling, renting or disclosing your personal data other than as described in this Privacy Policy.',
  },
  disclosureOfPersonalData: {
    title: 'Disclosure of Personal Data',
    disclosureDataRecipientsListDescriptor: `We may disclose your personal data to:`,
    disclosureDataRecipientsList: {
      item1: {
        description:
          'Our affiliates, contractors or third party services providers (including telecommunication companies, telemarketing and direct sales agents, call centres, data processing companies, information technology companies and research agencies) for the purposes set out in <strong><0>Purpose of Collection</0></strong> and the purposes of:',
        nestedList: [
          'Operating our website.',
          'Fulfilling requests by you.',
          'Processing and verification to enable us comply with our internal policies.',
          'Otherwise providing our products and services to you or your company.',
        ],
      },
      item2:
        'If the ownership or control of our project or asset changes, to the new owner of that project or asset.',
      item3:
        'Law enforcement agencies, regulatory authorities or other persons where we are required to do so by law.',
      item4: 'Relevant parties where it is necessary for legal proceedings; or',
      item5:
        'Any other person or organisation for any authorised purpose with your express consent.',
    },
    addition1:
      'We may also disclose your personal data to our affiliates, contractors or third party service providers overseas for some of the purposes listed above.',
    addition2:
      'We may store your personal data using a cloud solution. The use of this technology may result in your personal data being stored overseas. The recipients of your personal data may not reside in countries that have data protection laws comparable to Singapore, however the technology is designed so that only we may access and use the personal data stored using it.',
  },
  marketing: {
    title: 'Marketing',
    paragraph1:
      'We may send you direct marketing communications and information about our products and services that we consider may be of interest to you. These communications are sent in various forms, including mail, SMS, fax, telephone calls and email, in accordance with applicable laws. If you indicate a preference in regards to the method of communication, we will endeavour to use that method whenever practicable to do so.',
    paragraph2:
      'We may use your name, contact details, website usage information collected in accordance with this privacy policy and demographic data in marketing, and the classes of products and services that may be marketed to you include financial, investment, securities and related services and products as well as non-financial services and products that are offered by us or our affiliates (in each case subject to applicable laws and regulations governing the solicitation, marketing and/or offering of such financial or non-financial services and products).',
    paragraph3:
      'We may also use your personal data to target customers who have a similar profile to you as well as for internal analytics on users’ demographics, interests, and behaviour to perform more focused marketing of our products and services through tools such as Facebook’s ‘Custom Audience’ or ‘Lookalike Audience’, as well as other products and services such as <strong>Google Analytics</strong> and <strong>HotJar</strong>.',
    paragraph4: {
      description:
        'You may at any time opt-out of your personal data being used for marketing purposes (as set out in this paragraph 4) by contacting us at the details below and requesting for your account to be closed. For third party tools, such as Google Analytics or HotJar, please see the information further below:',
      item1:
        '<strong>Google Analytics</strong>: You may exercise choices regarding the use of cookies from Google Analytics by going to <0>https://tools.google.com/dlpage/gaoptoutand</0> downloading the Google Analytics Opt-out Browser Add-on.',
      item2:
        '<strong>HotJar</strong>: To opt-out of HotJar cookies see <0>https://www.hotjar.com/legal/compliance/opt-out</0>',
    },
  },
  accessAndCorrection: {
    title: 'Access and Correction',
    paragraph1:
      'You may access or correct any personal data we hold about you at any time by getting in touch with us at the details below to request for access and correction of personal data that we hold. If you are entitled to access the personal data, we will try to provide you with suitable means of accessing it. We may charge you a fee to cover our administrative and other costs in providing the personal data to you. We do not charge for making any corrections to your personal data.',
  },
  security: {
    title: 'Security',
    paragraph1:
      'We take reasonable steps to ensure your personal data is protected from misuse or loss, and unauthorised access, modification or disclosure. In particular, to ensure the security of your personal data, the cloud solution that we use to store your personal data complies with industry standard access controls.',
  },
  cookies: {
    title: 'Cookies',
    paragraph1: {
      description:
        'We may send “cookies”, which are unique identifiers, to your computer or device to enable our systems to recognise your computer or device. We may also use cookies for additional purposes, including:',
      list: [
        'Identifying you during the login process to our site.',
        'Keeping track of any preferences specified in your account.',
        'Conducting research and diagnostics.',
        'Helping to preventing fraudulent activity and improve security.',
      ],
    },
    infoAlert:
      'If you do not wish to receive cookies, you can adjust your browser setting so that your device does not accept them.',
  },
  contacts: {
    title: 'Contacts',
    paragraph1:
      'If you have any questions in relation to this Privacy Policy or your personal data, please contact our data protection officer at <0><strong>{{email}}</strong></0>.',
  },
  revisions: {
    title: 'Changes to this Privacy Policy',
    paragraph1:
      'We may revise this <strong>Privacy Policy</strong> from time to time without any prior notice. Your continued use of our services constitutes your acknowledgement and acceptance of such changes.',
  },
}
