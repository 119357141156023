import {
  ErrorAlert,
  PanelCards,
  UnderlineTabs,
  UnderlineTabsProvider,
} from '@allinfra-ltd/allinfra-design-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PaperBandOffset,
  PaperContentAligner,
  PaperOverlay,
  SmallSpinner,
} from 'src/components'
import { useDealStatsQuery, useSingleDealQuery } from 'src/hooks'
import { getTokenPanelCards } from 'src/pages/Deals/ViewDeal/ActiveDeal/utils'
import { getDealStatsData, getDealTypeData } from 'src/pages/Deals/utils'
import { ApprovalsBaseOverlayProps, Deal, DealState } from 'src/types'

import { useApprovalActions } from '../../context'
import TabsContent from './TabsContent'
import { rejectedDealTabs } from './utils'

function RejectedDeal({ selectedApprovalData }: ApprovalsBaseOverlayProps) {
  const { t } = useTranslation()
  const { isViewingApprovalDetails, toggleViewApprovalDetails } =
    useApprovalActions()
  const { generalInfo } = getDealTypeData(selectedApprovalData.deal)
  const { data: dealData, isError: isErrorLoadingDealData } =
    useSingleDealQuery({
      id: selectedApprovalData.deal.id,
      dealStage: selectedApprovalData.deal.stage,
    })

  return (
    <PaperOverlay
      isOpen={isViewingApprovalDetails}
      onClose={toggleViewApprovalDetails}
      title={generalInfo.useOfProceeds}
      description={generalInfo.description}
    >
      <PaperContentAligner>
        <DealStatsCard selectedDeal={selectedApprovalData.deal} />
        {[DealState.MintRejected, DealState.DealPublishRejected].includes(
          selectedApprovalData.deal.state
        ) ? (
          <ErrorAlert>
            <p className="font-bold">{t('deals.reasonForDecline')}</p>
            <p>{selectedApprovalData.reason || t('deals.toBeDone')}</p>
          </ErrorAlert>
        ) : null}
        <UnderlineTabsProvider tabs={rejectedDealTabs}>
          <UnderlineTabs />
          {dealData ? (
            <TabsContent deal={dealData} />
          ) : (
            <div className="p-4">
              <SmallSpinner />
            </div>
          )}
          {isErrorLoadingDealData ? (
            <div className="py-4">
              <ErrorAlert>{t('approvals.errors.dealDetails')}</ErrorAlert>
            </div>
          ) : null}
        </UnderlineTabsProvider>
      </PaperContentAligner>
      <PaperBandOffset />
    </PaperOverlay>
  )
}

const DealStatsCard = ({ selectedDeal }: { selectedDeal: Deal }) => {
  const { data: dealStats } = useDealStatsQuery({
    id: selectedDeal.id,
    options: {
      refetchOnMount: 'always',
      enabled: !!selectedDeal,
    },
  })

  const tokenCards = useMemo(
    () => getTokenPanelCards(getDealStatsData(dealStats), selectedDeal),
    [dealStats, selectedDeal]
  )

  return (
    <PanelCards
      className="border md:grid-cols-1 gap-px bg-gray-200 mb-4"
      cards={tokenCards}
    />
  )
}

export default RejectedDeal
