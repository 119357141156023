import { InputBase } from '@allinfra-ltd/allinfra-design-system'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { minNrValueErrMessage } from 'src/pages/Deals/CreateDeal/utils'
import { useDealActions } from 'src/pages/Deals/context'
import { getDealTypeData } from 'src/pages/Deals/utils'
import {
  CreateDealFlatDataKeys as DataKeys,
  Deal,
  RegisterOptionsKey,
} from 'src/types'

const IncrementAmountSize = ({ value, id }: { value: string; id: string }) => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext()

  const { state } = useDealActions()
  const { generalInfo } = getDealTypeData(state.selectedDealData as Deal)

  return (
    <InputBase
      label={t('deals.create.specificDetails.bondInputLabel')}
      placeholder={t('deals.create.specificDetails.bondPlaceholder')}
      {...register(id, {
        required: t('deals.create.specificDetails.bondInputError') as string,
        min: 0,
        value,
      })}
      autoFocus
      defaultValue={value}
      error={!!errors[id]}
      inputInfo={`${
        errors[id]?.message ||
        (errors[id]?.type === RegisterOptionsKey.Min && minNrValueErrMessage) ||
        ''
      }`}
      type="number"
      trailingIcon={
        <div className="bg-white px-4 py-2 border rounded-md">
          {t(
            `deals.currencies.${
              getValues(DataKeys.Currency)?.toLowerCase() ||
              generalInfo.currency
            }`
          )}
        </div>
      }
    />
  )
}

export default IncrementAmountSize
