export const organizations = {
  sectionHeader: {
    title: 'Organizations',
    invitationButton: 'Invite user',
  },
  activeUsersTable: {
    header: {
      name: 'Name/Email',
      role: 'Role',
      date: 'Creation date',
      actions: 'Actions',
    },
    actions: {
      remove: 'Remove',
      removeOrganisationTooltip: `The user still has an open Deal <br /> or one of user s invitees has an <br /> open Deal.`,
    },
  },
  pendingUsersTable: {
    header: {
      email: 'Email',
      role: 'Role',
      date: 'Invited on',
      actions: 'Actions',
    },
    actions: {
      view: 'View',
      removeInvitation: 'Remove invitation',
    },
  },
  createOrganisation: {
    title: 'No organisation',
    content:
      'You are not part or you did not create any organisation. If you want to create a new organisation, click on the button below.',
    actionButton: 'Create Organisation',
    outerContent: 'Want to learn more about organisation?',
    outerAction: 'Click here',
  },
  createOrganisationModal: {
    title: 'Create Organisation',
    content: 'Please fill in the details below to create your organisation',
    inputNameLabel: 'Organisation name',
    inputNamePlaceholder: 'Organisation name',
    requiredName: 'Please enter Organisation name',
    inputAddressLabel: 'Business address',
    inputAddressPlaceholder: 'Business address',
    requiredAddress: 'Please enter a valid address',
    inputWebsiteLabel: 'Organisation website',
    inputWebsitePlaceholder: 'https://example.com',
    inputWebsiteCorner: 'Optional',
    actionButton: 'Create organisation',
  },
  editOrganisationModal: {
    title: 'Edit Organisation',
    openButton: 'Edit',
    description: 'Company details',
    successTitle: ' Details Successfully saved!',
    closeButton: 'Close',
    saveButton: 'Save changes',
    discardButton: 'Discard changes',
  },
  declinedOrganisation: {
    title: 'Organisation application declined',
    content: `Your application was declined by an admin, but you can still apply for an organisation. The reason for the declined application is:`,
    defaultReason: `No reason has been provided.`,
    actionButton: `I understand, let me try again`,
  },
  pendingOrganisation: {
    title: 'Organisation application submitted',
    content: `Thank you! We have sent your application to an administrator for approval.`,
    error: `The approval time may take up to 5 business days. You will get an email when the status of your approval changes`,
  },
  removeUserModal: {
    title: 'Remove from organisation',
    description:
      'Are you sure you want to remove the user below from the organisation?',
    cancel: 'Cancel',
    removeActionButton: 'Remove user',
    success: {
      title: 'User successfully removed!',
      goToActionButton: 'Go to active users',
    },
  },
}
