import { ErrorCodes } from 'src/types/errors'

export const singleDealPollingInterval = 5000

export const dealPagePollingInterval = 5000
export const approvalPagePollingInterval = 5000
export const investmentAmountIdPrefix = 'invest-'

export const errorReasonToLocaleKeyMap = Object.values(ErrorCodes).map(
  (code) => {
    return {
      errorReason: code,
      translationKey: `${code.charAt(0).toLowerCase()}${code.slice(1)}`,
    }
  }
)
