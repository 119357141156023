export const shared = {
  capitalizedYes: 'Yes',
  lowerCaseYears: 'years',
  notApplicable: 'N/A',
  investorLowerCase: 'investor',
  investorsLowerCase: 'investors',
  selectedLowerCase: 'selected',
  investorUpperCase: 'Investor',
  addressUpperCase: 'Address',
  copyToClipboardMessage: {
    copied: 'Copied!',
    copyToClipboard: 'Copy to clipboard',
  },
}
