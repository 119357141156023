import { InputBase } from '@allinfra-ltd/allinfra-design-system'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RegisterOptionsKey } from 'src/types'

const Tenor = ({ value, id }: { value: string; id: string }) => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const minNrValueErrMessage = t(
    'deals.create.specificDetails.minValueInputError'
  )
  return (
    <InputBase
      placeholder={t('deals.create.specificDetails.couponPlaceholder')}
      type="number"
      trailingIcon={
        <div className="bg-white px-4 py-2 border rounded-md">%</div>
      }
      {...register(id, {
        required: t('deals.create.specificDetails.couponInputError') as string,
        min: 0,
        max: 100,
        value,
      })}
      autoFocus
      error={!!errors[id]}
      inputInfo={`${
        errors[id]?.message ||
        (errors[id]?.type === RegisterOptionsKey.Min && minNrValueErrMessage) ||
        ''
      }`}
    />
  )
}

export default Tenor
