import { CloseIcon } from '@allinfra-ltd/allinfra-design-system'

type CircularCloseButtonProps = {
  onClick: () => void
}

function CircularCloseButton({ onClick, ...props }: CircularCloseButtonProps) {
  return (
    <div
      className="h-10 w-10 cursor-pointer rounded-full flex items-center justify-center p-2 hover:shadow-sm active:shadow-inner border border-gray-200"
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      {...props}
    >
      <CloseIcon />
    </div>
  )
}

export default CircularCloseButton
