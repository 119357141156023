import { UseMutationOptions, useMutation } from 'react-query'
import { postRegenerateUserWallet } from 'src/api/routes'
import { UserWallet } from 'src/types'

export interface UseRegenerateUserWalletParams {
  options?: Partial<
    Omit<
      UseMutationOptions<
        UserWallet,
        unknown,
        { userId: number },
        (string | number)[]
      >,
      'queryKey' | 'queryFn'
    >
  >
}

const regenerate = async ({ userId }: { userId: number }) =>
  postRegenerateUserWallet({ userId })

export default function useRegenerateUserWallet({
  options,
}: UseRegenerateUserWalletParams) {
  return useMutation(regenerate, {
    ...options,
  })
}
