import { ModalBase, ModalBaseProps } from '@allinfra-ltd/allinfra-design-system'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import InviteForm from 'src/components/Modal/InviteModal/InviteForm'
import InviteSucces from 'src/components/Modal/InviteModal/InviteSuccess'
import {
  useAvailableRoles,
  useUserInvitationMutation,
} from 'src/hooks/Invitation'
import { InviteFormFields } from 'src/types/invite'

function UserInviteModal({ isOpen, setIsOpen }: ModalBaseProps) {
  const navigate = useNavigate()

  const {
    data: availableRoleData,
    error: availableRoleError,
    isLoading: isAvailableRoleLoading,
  } = useAvailableRoles()

  const {
    mutate: inviteMutate,
    error: inviteError,
    isLoading: isInviteLoading,
  } = useUserInvitationMutation()

  const [invitedEmail, setInvitedEmail] = React.useState<string | null>(null)

  const onSubmit = ({ email, roleIndex = 0 }: InviteFormFields) => {
    if (!availableRoleData) return

    const roleId = availableRoleData[roleIndex].id

    inviteMutate(
      {
        email,
        roleId,
      },
      {
        onSuccess: () => {
          setInvitedEmail(email)
        },
      }
    )
  }

  const onGotoPendingUser = () => {
    navigate('pending')
    setIsOpen(false)
  }

  return (
    <ModalBase {...{ isOpen, setIsOpen }}>
      {invitedEmail ? (
        <InviteSucces
          onClose={() => setIsOpen(false)}
          email={invitedEmail}
          onActionBtnClick={onGotoPendingUser}
        />
      ) : (
        <InviteForm
          roles={availableRoleData}
          onClose={() => setIsOpen(false)}
          onSubmit={onSubmit}
          submitError={inviteError?.response?.data?.message}
          fetchError={availableRoleError?.response?.data?.message}
          isFetching={isAvailableRoleLoading}
          isSubmitting={isInviteLoading}
        />
      )}
    </ModalBase>
  )
}

export default React.memo(UserInviteModal)
