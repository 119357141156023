import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { EditOrgPayload, updateSingleOrg } from 'src/api/routes'
import { Organization } from 'src/types'
import { ErrorResponse } from 'src/types/errors'

const editOrganisation = async (payload: EditOrgPayload) => {
  const { data } = await updateSingleOrg(payload)
  return data
}

export function useEditOrganisation() {
  const queryClient = useQueryClient()

  return useMutation<
    Organization,
    AxiosError<ErrorResponse>,
    EditOrgPayload,
    unknown
  >(editOrganisation, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.organizations)
      queryClient.invalidateQueries([queryKeys.myOrganization])
    },
  })
}
