import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import {
  DeleteOrganizationMemberRequestProps,
  deleteOrganizationMember,
} from 'src/api/routes'
import { ErrorResponse } from 'src/types/errors'

const removeMember = async ({
  orgId,
  userId,
}: DeleteOrganizationMemberRequestProps) =>
  deleteOrganizationMember({ orgId, userId })

export default function useRemoveOrganisationMember() {
  const queryClient = useQueryClient()
  return useMutation<
    unknown,
    AxiosError<ErrorResponse>,
    DeleteOrganizationMemberRequestProps,
    unknown
  >(removeMember, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.orgUsers)
    },
  })
}
