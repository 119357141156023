import { useCallback, useState } from 'react'

function useBoolean(initialValue?: boolean) {
  const [value, setValue] = useState(initialValue)

  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])
  const toggle = useCallback(() => setValue(!value), [])

  return {
    value,
    setTrue,
    setFalse,
    toggle,
    setValue,
  }
}

export default useBoolean
