import { NavigateFunction } from 'react-router-dom'
import {
  BrowserRouterSubPaths,
  CreateDealEventWithPayload,
  CreateDealMachineContext,
  CreateDealMachineAction as MachineActions,
  UpdateDealMachineContextEvent,
} from 'src/types'
import { assign } from 'xstate'

import { initialContextState } from './createDealMachine'

export function getActions({
  navigate,
  subPaths,
}: {
  navigate: NavigateFunction
  subPaths: BrowserRouterSubPaths
}) {
  return {
    [MachineActions.NavigateToRootDeals]: () =>
      navigate(`${subPaths.Root}${subPaths.RootDeals}`),
    [MachineActions.SetFlatDealData]: assign({
      flatDealData: (context: CreateDealMachineContext, event) => {
        const { payload } = event as CreateDealEventWithPayload
        return { ...context.flatDealData, ...payload }
      },
    }),
    [MachineActions.ResetDealMachineContext]: assign(initialContextState),
    [MachineActions.UpdateDealMachineContext]: assign(
      (
        context: CreateDealMachineContext,
        event: UpdateDealMachineContextEvent
      ) => ({
        ...context,
        ...event.payload,
      })
    ),
  }
}
