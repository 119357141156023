import {
  EyeOutlineIcon,
  EyeOutlineOffIcon,
  InputBase,
  InputBaseProps,
} from '@allinfra-ltd/allinfra-design-system'
import { useState } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { Validate } from 'react-hook-form/dist/types/validator'
import { useTranslation } from 'react-i18next'

interface PasswordInputProps extends Partial<InputBaseProps> {
  register: UseFormRegister<FieldValues>
  validationRules?: Record<string, Validate<string>>
}

export default function PasswordInput({
  register,
  validationRules,
  onFocus,
  ...props
}: PasswordInputProps) {
  const { t } = useTranslation()
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const togglePasswordVisibility = () =>
    setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible)

  return (
    <InputBase
      onFocus={onFocus}
      type={isPasswordVisible ? 'text' : 'password'}
      placeholder={t('input.password.placeholder')}
      label={props.label || t('input.password.label')}
      trailingIcon={
        <div
          className="cursor-pointer pointer-events-auto"
          onClick={togglePasswordVisibility}
          onKeyPress={togglePasswordVisibility}
          role="button"
          tabIndex={0}
        >
          {isPasswordVisible ? <EyeOutlineOffIcon /> : <EyeOutlineIcon />}
        </div>
      }
      {...register('password', {
        required: t('input.password.required') as string,
        validate: {
          isRequired: (value) =>
            !!value.trim() || (t('input.password.patternMessage') as string),
          ...validationRules,
        },
      })}
      {...props}
    />
  )
}
