import {
  AuthCodeInput,
  Button,
  CloseIcon,
  GreenShieldCircle,
  ModalBase,
  ModalBaseProps,
} from '@allinfra-ltd/allinfra-design-system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoginPayload } from 'src/api/routes'
import { useAuth } from 'src/context'

interface TwoFactorAuthModalProps extends ModalBaseProps {
  payload: LoginPayload
  onRecoverClick: () => void
}

function TwoFactorAuthModal({
  isOpen,
  setIsOpen,
  payload,
  onRecoverClick,
}: TwoFactorAuthModalProps) {
  const { signIn, isLoadingLoginRequest, isErrorOnLogin } = useAuth()
  const { t } = useTranslation()

  const [authCode, setAuthCode] = useState('')
  const authCodeLength = 6

  return (
    <ModalBase {...{ isOpen, setIsOpen }}>
      {' '}
      <div className="px-4">
        <div>
          <div className="ml-auto flex w-full flex-row justify-between">
            <div className=" invisible w-10" />
            <div className="mx-auto flex items-center justify-center h-26 w-26 rounded-full">
              <GreenShieldCircle aria-hidden="true" />
            </div>
            <div className="h-10 w-10 cursor-pointer rounded-full  p-2 hover:shadow-sm active:shadow-inner">
              <CloseIcon onClick={() => setIsOpen(false)} />
            </div>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {t('auth.twoFactorAuthModal.title')}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {t('auth.twoFactorAuthModal.description')}
              </p>
            </div>
          </div>
          <div className="my-10">
            <AuthCodeInput
              length={authCodeLength}
              allowedCharacters="alphanumeric"
              onInputChanges={setAuthCode}
              isError={isErrorOnLogin}
              errorLabel={t('auth.twoFactorAuthModal.authCodeInput.error')}
            />
            <div
              tabIndex={0}
              role="button"
              className="font-semibold  text-right text-blue-600 text-sm cursor-pointer my-2"
              onClick={onRecoverClick}
              onKeyPress={onRecoverClick}
            >
              {t('auth.twoFactorAuthModal.backupCode')}
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <Button
            disabled={authCode.length < authCodeLength || isLoadingLoginRequest}
            classes="w-full"
            onClick={() => signIn({ ...payload, code: authCode })}
          >
            {t('auth.twoFactorAuthModal.button')}
          </Button>
        </div>
      </div>
    </ModalBase>
  )
}

export default React.memo(TwoFactorAuthModal)
