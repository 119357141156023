import { InfoAlert, WarningAlert } from '@allinfra-ltd/allinfra-design-system'
import { MutableRefObject, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Heading, TranslationOffsetUnorderedList } from './utils'

export interface PrivacyContentProps {
  companyName: string
  contacts: { email: string }
}

function PrivacyContent({
  companyName,
  contacts: { email },
}: PrivacyContentProps) {
  const { t } = useTranslation()
  const purposeOfCollectionRef: MutableRefObject<HTMLHeadingElement | null> =
    useRef(null)

  return (
    <>
      <Trans i18nKey="privacy.policyDescription" values={{ companyName }} />
      <Heading>
        <Trans i18nKey="privacy.collectionOfInformation.title" />
      </Heading>
      <Trans i18nKey="privacy.collectionOfInformation.typesOfDataListDescriptor" />
      <TranslationOffsetUnorderedList translationKey="privacy.collectionOfInformation.typesOfDataList" />
      <InfoAlert>
        {t('privacy.collectionOfInformation.infoAlert', {
          companyName,
        })}
      </InfoAlert>
      <br />
      <Trans i18nKey="privacy.collectionOfInformation.dataCollectionMethodsListDescriptor" />
      <TranslationOffsetUnorderedList
        translationKey="privacy.collectionOfInformation.dataCollectionMethodsList"
        values={{ companyName }}
      />
      <WarningAlert>
        {t('privacy.collectionOfInformation.warningAlert')}
      </WarningAlert>
      <Heading ref={purposeOfCollectionRef}>
        <Trans i18nKey="privacy.purposeOfCollection.title" />
      </Heading>
      <Trans i18nKey="privacy.purposeOfCollection.listDescriptor" />
      <TranslationOffsetUnorderedList translationKey="privacy.purposeOfCollection.list" />
      <InfoAlert>{t('privacy.purposeOfCollection.infoAlert')}</InfoAlert>
      <Heading>
        <Trans i18nKey="privacy.disclosureOfPersonalData.title" />
      </Heading>
      <Trans i18nKey="privacy.disclosureOfPersonalData.disclosureDataRecipientsListDescriptor" />
      <ul className="list-disc my-4">
        <li className="ml-6">
          <Trans i18nKey="privacy.disclosureOfPersonalData.disclosureDataRecipientsList.item1.description">
            <span
              onClick={() => purposeOfCollectionRef.current?.scrollIntoView()}
              onKeyDown={() => purposeOfCollectionRef.current?.scrollIntoView()}
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 text-semibold cursor-pointer"
              role="presentation"
            >
              {' '}
            </span>
          </Trans>
          <TranslationOffsetUnorderedList translationKey="privacy.disclosureOfPersonalData.disclosureDataRecipientsList.item1.nestedList" />
        </li>
        <li className="ml-6">
          <Trans i18nKey="privacy.disclosureOfPersonalData.disclosureDataRecipientsList.item2" />
        </li>
        <li className="ml-6">
          <Trans i18nKey="privacy.disclosureOfPersonalData.disclosureDataRecipientsList.item3" />
        </li>
        <li className="ml-6">
          <Trans i18nKey="privacy.disclosureOfPersonalData.disclosureDataRecipientsList.item4" />
        </li>
        <li className="ml-6">
          <Trans i18nKey="privacy.disclosureOfPersonalData.disclosureDataRecipientsList.item5" />
        </li>
      </ul>
      <p className="mb-4">
        <Trans i18nKey="privacy.disclosureOfPersonalData.addition1" />
      </p>
      <Trans i18nKey="privacy.disclosureOfPersonalData.addition2" />
      <Heading>
        <Trans i18nKey="privacy.marketing.title" />
      </Heading>
      <p className="mb-4">
        <Trans i18nKey="privacy.marketing.paragraph1" />
      </p>
      <p className="mb-4">
        <Trans i18nKey="privacy.marketing.paragraph2" />
      </p>
      <p className="mb-4">
        <Trans i18nKey="privacy.marketing.paragraph3" />
      </p>
      <p className="mb-4">
        <Trans i18nKey="privacy.marketing.paragraph4.description" />
      </p>
      <ul className="list-disc my-4">
        <li className="ml-6">
          <Trans i18nKey="privacy.marketing.paragraph4.item1">
            <a
              target="_blank"
              href="https://tools.google.com/dlpage/gaoptout"
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 text-semibold"
              rel="noreferrer"
            >
              {' '}
            </a>
          </Trans>
        </li>
        <li className="ml-6">
          <Trans i18nKey="privacy.marketing.paragraph4.item2">
            <a
              target="_blank"
              href="https://www.hotjar.com/legal/compliance/opt-out"
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 text-semibold"
              rel="noreferrer"
            >
              {' '}
            </a>
          </Trans>
        </li>
      </ul>
      <Heading>
        <Trans i18nKey="privacy.accessAndCorrection.title" />
      </Heading>
      <Trans i18nKey="privacy.accessAndCorrection.paragraph1" />
      <Heading>
        <Trans i18nKey="privacy.security.title" />
      </Heading>
      <Trans i18nKey="privacy.security.paragraph1" />
      <Heading>
        <Trans i18nKey="privacy.cookies.title" />
      </Heading>
      <Trans i18nKey="privacy.cookies.paragraph1.description" />
      <TranslationOffsetUnorderedList translationKey="privacy.cookies.paragraph1.list" />
      <InfoAlert>{t('privacy.cookies.infoAlert')}</InfoAlert>
      <Heading>
        <Trans i18nKey="privacy.contacts.title" />
      </Heading>
      <Trans i18nKey="privacy.contacts.paragraph1" values={{ email }}>
        <a
          target="_blank"
          href={`mailto:${email}`}
          className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600 text-semibold"
          rel="noreferrer"
        >
          {' '}
        </a>
      </Trans>

      <Heading>
        <Trans i18nKey="privacy.revisions.title" />
      </Heading>
      <Trans i18nKey="privacy.revisions.paragraph1" />
    </>
  )
}

export default PrivacyContent
