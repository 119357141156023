import { FullScreenOverlay } from '@allinfra-ltd/allinfra-design-system'
import { useMemo } from 'react'
import { PaperOverlayProps } from 'src/types'

import CircularCloseButton from '../CircularCloseButton/CircularCloseButton'
import PaperContentAligner from './PaperContentAligner'

function PaperOverlay({
  children,
  className = 'bg-white',
  isOpen,
  onClose,
  title,
  description: descriptionProp,
  ...props
}: PaperOverlayProps) {
  const renderDescription = useMemo(() => {
    if (!descriptionProp) return null

    const description =
      typeof descriptionProp === 'string' ? (
        <p className="text-gray-500">{descriptionProp}</p>
      ) : (
        descriptionProp
      )
    return <PaperContentAligner>{description}</PaperContentAligner>
  }, [descriptionProp])

  return (
    <FullScreenOverlay {...{ className, ...props, isOpen }}>
      <div className="w-full">
        <PaperContentAligner wrapperClassNames="flex justify-between items-center m-8">
          <h1 className="text-3xl font-bold text-gray-900">{title}</h1>
          <CircularCloseButton onClick={() => onClose?.()} />
        </PaperContentAligner>
        {renderDescription}
        {children}
      </div>
    </FullScreenOverlay>
  )
}

export default PaperOverlay
