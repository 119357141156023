export const createDealSteps = [
  {
    id: '01',
    name: 'General Information',
    description: 'Step 01',
    path: '',
    disable: true,
  },
  {
    id: '02',
    name: 'Specific Details',
    description: 'Step 02',
    path: '',
    disable: true,
  },
  {
    id: '03',
    name: 'Deal eligibility',
    description: 'Step 03',
    path: '',
    disable: true,
  },
  {
    id: '04',
    name: 'Pre-deal solicitation',
    description: 'Step 04',
    path: '',
    disable: true,
  },
]
