import {
  Button,
  CloseIcon,
  ErrorAlert,
  InputBase,
  ModalBase,
  ModalBaseProps,
} from '@allinfra-ltd/allinfra-design-system'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NewOrgPayload } from 'src/api/routes'
import { SmallSpinner } from 'src/components'

interface CreateOrganisationModalProps extends ModalBaseProps {
  onSubmit: (values: NewOrgPayload) => void
  isSubmitting: boolean
  errorMessage?: string
}

function CreateOrganisationModal({
  errorMessage,
  isOpen,
  setIsOpen,
  onSubmit,
  isSubmitting,
}: CreateOrganisationModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<NewOrgPayload>({
    mode: 'onChange',
  })

  const { t } = useTranslation()

  useEffect(reset, [isOpen])

  return (
    <ModalBase isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="px-4 pt-3 pb-4">
        <div className="flex flex-row items-center content-around">
          <h4 className="text-gray-900 text-lg font-bold">
            {t('organizations.createOrganisationModal.title')}
          </h4>
          <div className="ml-auto h-10 w-10 cursor-pointer rounded-full p-2 hover:shadow-sm active:shadow-inner">
            <CloseIcon
              onClick={() => {
                setIsOpen(false)
              }}
            />
          </div>
        </div>
        <form action="">
          <p className="mt-4 text-gray-500 text-sm font-normal">
            {t('organizations.createOrganisationModal.content')}
          </p>
          <div className="my-8">
            <InputBase
              label={t('organizations.createOrganisationModal.inputNameLabel')}
              placeholder={t(
                'organizations.createOrganisationModal.inputNamePlaceholder'
              )}
              {...register('name', {
                required: t(
                  'organizations.createOrganisationModal.requiredName'
                ),
                validate: (value) =>
                  !!value.trim() ||
                  t('organizations.createOrganisationModal.requiredName'),
              })}
              type="text"
              error={!!errors.name}
              inputInfo={`${errors.name?.message || ''}`}
            />
          </div>

          <div className="my-8">
            <InputBase
              label={t(
                'organizations.createOrganisationModal.inputAddressLabel'
              )}
              placeholder={t(
                'organizations.createOrganisationModal.inputAddressPlaceholder'
              )}
              {...register('address', {
                required: t(
                  'organizations.createOrganisationModal.requiredAddress'
                ),
                validate: (value) =>
                  !!value.trim() ||
                  t('organizations.createOrganisationModal.requiredAddress'),
              })}
              type="text"
              error={!!errors.address}
              inputInfo={`${errors.address?.message || ''}`}
            />
          </div>

          <div className="my-8">
            <InputBase
              label={t(
                'organizations.createOrganisationModal.inputWebsiteLabel'
              )}
              placeholder={t(
                'organizations.createOrganisationModal.inputWebsitePlaceholder'
              )}
              {...register('website')}
              cornerOption={t(
                'organizations.createOrganisationModal.inputWebsiteCorner'
              )}
              type="text"
              error={!!errors.website}
            />
          </div>
          <Button
            type="submit"
            classes="mt-4 w-full text-sm font-bold"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
            endIcon={isSubmitting ? <SmallSpinner fill="#FFFFFF" /> : null}
          >
            {t('organizations.createOrganisationModal.actionButton')}
          </Button>
        </form>
      </div>
      {!!errorMessage && (
        <ErrorAlert>
          <p>{errorMessage}</p>
        </ErrorAlert>
      )}
    </ModalBase>
  )
}

export default React.memo(CreateOrganisationModal)
