import {
  Button,
  ModalCloseButton,
  TextArea,
  useModal,
} from '@allinfra-ltd/allinfra-design-system'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalContent } from 'src/components'
import { ModalContentProps } from 'src/components/Modal/InviteModal/ModalContent'
import { useUpdateApprovalStatus } from 'src/hooks/approvals'
import { AnyFunction } from 'src/utils'

export interface ModalContentApprovalDeclineProps extends ModalContentProps {
  approvalId: number
  textContent?: string
  buttonTextContent?: string
  onClickCloseButton?: AnyFunction
}

function ModalContentApprovalDecline({
  onClickCloseButton,
  textContent,
  buttonTextContent,
  approvalId,
  ...props
}: ModalContentApprovalDeclineProps) {
  const initialReasonState = ''
  const [reason, setReason] = useState(initialReasonState)
  const resetReasonState = () => setReason(initialReasonState)
  const { t } = useTranslation()
  const { declineDeal } = useUpdateApprovalStatus()
  const { enqueueOnCloseCallback } = useModal()
  const buttonDefaultText = t('approvals.buttons.declineWithReason')

  useEffect(() => enqueueOnCloseCallback(resetReasonState), [])

  return (
    <ModalContent {...props}>
      <p className="pt-4 pb-6 text-gray-500 text-sm font-normal">
        {textContent}
      </p>
      <TextArea
        label={t('approvals.deals.decline.reason')}
        placeholder={t('approvals.deals.decline.placeholderReason')}
        onChange={(e) => setReason(e.target.value)}
        value={reason}
      />
      <ModalCloseButton>
        <Button
          onClick={() => {
            declineDeal({ id: approvalId, reason })
            onClickCloseButton?.()
          }}
          classes="w-full mt-8"
        >
          {buttonTextContent || buttonDefaultText}
        </Button>
      </ModalCloseButton>
    </ModalContent>
  )
}

export default ModalContentApprovalDecline
