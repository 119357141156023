import { classNames } from '@allinfra-ltd/allinfra-design-system'
import { PaperContentAlignerProps } from 'src/types'

function PaperContentAligner({
  children,
  className,
  wrapperClassNames,
}: PaperContentAlignerProps) {
  return (
    <div
      className={classNames(
        className,
        'flex flex-1 justify-center items-center py-4 px-4'
      )}
    >
      <div className={classNames(wrapperClassNames, 'w-3/6 p-0')}>
        {children}
      </div>
    </div>
  )
}

export default PaperContentAligner
