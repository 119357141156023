import { PanelCard } from '@allinfra-ltd/allinfra-design-system/dist/components/Cards/PanelCards/PanelCards'
import { t } from 'i18next'
import { DualRowPanelCard } from 'src/components'
import { Deal, DealState, DealStats } from 'src/types'
import {
  addThousandsSeparator,
  filterTruthyValuesAndZero,
  joinWithSpaces,
  pipe,
  toUpperCase,
  trim,
} from 'src/utils'

type PanelCardsByStates = {
  [key in DealState]: PanelCard[]
}

const formatCurrencyText = (value: string) => {
  const [currency, amount] = value.split(' ')

  return amount ? (
    <div className="flex space-x-2 items-end ">
      <div className="text-base font-medium">{currency}</div>
      <div className="truncate">{addThousandsSeparator(amount)}</div>
    </div>
  ) : (
    <div className="truncate">{currency}</div>
  )
}
const formatDealValues = pipe(
  filterTruthyValuesAndZero,
  joinWithSpaces,
  trim,
  toUpperCase,
  formatCurrencyText
)

export function getTokenPanelCards(dealStats: DealStats, deal?: Deal) {
  const defaultGeneralInfoProps = {
    mainSale: { generalInfo: { currency: '', tickerSymbol: '' } },
  }

  const {
    state: dealState = DealState.Unknown,
    mainSale: {
      generalInfo: { currency, tickerSymbol },
    },
  } = {
    ...defaultGeneralInfoProps,
    ...deal,
  }

  const {
    maxInvestmentAmount,
    tokenHolders,
    totalSupply,
    circulatingSupply,
    burnedSupply,
    totalInvestmentAmount,
  } = dealStats

  const panelCards: PanelCardsByStates = {
    [DealState.DealActive]: [
      {
        id: 'token-card-1',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.maxInvestmentSize')}`}
            bottomRowElement={formatDealValues(
              currency,
              addThousandsSeparator(maxInvestmentAmount)
            )}
          />
        ),
      },
      {
        id: 'token-card-2',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.totalInvestmentSize')}`}
            bottomRowElement={formatDealValues(
              currency,
              addThousandsSeparator(totalInvestmentAmount)
            )}
          />
        ),
      },
      {
        id: 'token-card-3',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.currentTokenHolders')}`}
            bottomRowElement={addThousandsSeparator(tokenHolders)}
          />
        ),
      },
      {
        id: 'token-card-4',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.totalSupply')}`}
            bottomRowElement={formatDealValues(
              tickerSymbol,
              addThousandsSeparator(totalSupply)
            )}
          />
        ),
      },
      {
        id: 'token-card-5',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.tokensInCirculation')}`}
            bottomRowElement={formatDealValues(
              tickerSymbol,
              addThousandsSeparator(circulatingSupply)
            )}
          />
        ),
      },
      {
        id: 'token-card-6',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.burnedTokens')}`}
            bottomRowElement={formatDealValues(
              tickerSymbol,
              addThousandsSeparator(burnedSupply)
            )}
          />
        ),
      },
    ],
    [DealState.DealClosed]: [
      {
        id: 'token-card-1',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.maxInvestmentSize')}`}
            bottomRowElement={formatDealValues(
              currency,
              addThousandsSeparator(maxInvestmentAmount)
            )}
          />
        ),
      },
      {
        id: 'token-card-2',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.totalInvestmentSize')}`}
            bottomRowElement={formatDealValues(
              currency,
              addThousandsSeparator(totalInvestmentAmount)
            )}
          />
        ),
      },
      {
        id: 'token-card-3',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.currentTokenHolders')}`}
            bottomRowElement={addThousandsSeparator(tokenHolders)}
          />
        ),
      },
      {
        id: 'token-card-4',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.totalSupply')}`}
            bottomRowElement={formatDealValues(
              tickerSymbol,
              addThousandsSeparator(totalSupply)
            )}
          />
        ),
      },
      {
        id: 'token-card-5',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.tokensInCirculation')}`}
            bottomRowElement={formatDealValues(
              tickerSymbol,
              addThousandsSeparator(circulatingSupply)
            )}
          />
        ),
      },
      {
        id: 'token-card-6',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.burnedTokens')}`}
            bottomRowElement={formatDealValues(
              tickerSymbol,
              addThousandsSeparator(burnedSupply)
            )}
          />
        ),
      },
    ],
    [DealState.MintApproved]: [
      {
        id: 'token-card-1',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.totalInvestmentSize')}`}
            bottomRowElement={formatDealValues(
              currency,
              addThousandsSeparator(totalInvestmentAmount)
            )}
          />
        ),
      },
    ],
    [DealState.MintPendingApproval]: [
      {
        id: 'token-card-1',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.totalInvestmentSize')}`}
            bottomRowElement={formatDealValues(
              currency,
              addThousandsSeparator(totalInvestmentAmount)
            )}
          />
        ),
      },
    ],
    [DealState.DealPublished]: [],
    [DealState.DealPublishRejected]: [],
    [DealState.DealPublishPendingApproval]: [],
    [DealState.DealPublishInProgress]: [],
    [DealState.DealPublishFailed]: [],
    [DealState.MintInProgress]: [],
    [DealState.MintFailed]: [],
    [DealState.MintRejected]: [
      {
        id: 'token-card-1',
        element: (
          <DualRowPanelCard
            topRowElement={`${t('deals.totalInvestmentSize')}`}
            bottomRowElement={formatDealValues(
              currency,
              addThousandsSeparator(totalInvestmentAmount)
            )}
          />
        ),
      },
    ],
    [DealState.Draft]: [],
    [DealState.Unknown]: [],
  }

  return panelCards[dealState] || panelCards[DealState.Unknown]
}
