import { ReactNode } from 'react'

interface OverviewCardProps {
  description: ReactNode
  value: ReactNode
}

function OverviewCard({ description, value }: Partial<OverviewCardProps>) {
  return (
    <div className="bg-white overflow-hidden shadow border rounded-lg">
      <div className="p-3">
        <div className="flex items-center">
          <div className="ml-5 w-0 flex-1">
            <div className=" space-y-4">
              {description ? (
                <div className="text-md font-medium text-gray-500 truncate">
                  {description}
                </div>
              ) : null}
              {value ? (
                <dd>
                  <div className="text-2xl font-bold text-gray-900 tracking-wide">
                    {value}
                  </div>
                </dd>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverviewCard
