export enum RoleEnum {
  'SuperAdmin' = 1,
  'Admin' = 2,
  'Issuer' = 3,
  'Operator' = 4,
  'Regulator' = 5,
  'Investor' = 6,
  'IssuerOrgOwner' = 7,
  'IssuerOrgMember' = 8,
}
