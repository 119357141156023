import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { postUserInvite } from 'src/api/routes'
import { InviteRequestBody } from 'src/types/invite'

const createUserInvitation = async (payload: InviteRequestBody) => {
  const { data } = await postUserInvite(payload)
  return data
}

export function useUserInvitationMutation() {
  const queryClient = useQueryClient()
  return useMutation<any, AxiosError<any>, InviteRequestBody, unknown>(
    createUserInvitation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.pendingUsers)
      },
    }
  )
}
