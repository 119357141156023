import {
  Deal,
  DealStage,
  DealState,
  DealStats,
  EligibleInvestor,
  EligibleInvestorActionStatus,
  EligibleInvestorBurnStatus,
  EligibleInvestorTransferAction,
  KeyOfDealStageData,
  MainSaleEligibleInvestor,
  User,
} from 'src/types'

import { investmentAmountIdPrefix } from './constants'

export function getDealTypeData(deal: Deal) {
  const keyOfDealTypeData = getKeyOfDealTypeData(deal.stage)
  return deal[keyOfDealTypeData]
}

export function getKeyOfDealTypeData(stage: DealStage) {
  return stage === DealStage.MainSale
    ? KeyOfDealStageData.MainSale
    : KeyOfDealStageData.PreDeal
}

export function getInvestorTokenStatuses(investor: EligibleInvestor) {
  const { transferAction, burnStatus, isInitialTransferDone, actionStatus } =
    investor.investorMetadata!

  const isNotTransferred = !isInitialTransferDone
  const isTransferred = isInitialTransferDone
  const isFullyBurned = burnStatus === EligibleInvestorBurnStatus.BURNED
  const isBurning =
    transferAction === EligibleInvestorTransferAction.BURNED &&
    actionStatus === EligibleInvestorActionStatus.PENDING
  const isNotBurned = burnStatus === EligibleInvestorBurnStatus.NOT_BURNED
  const isPartiallyBurned =
    burnStatus === EligibleInvestorBurnStatus.PARTIAL_BURNED
  const isTransferring =
    transferAction === EligibleInvestorTransferAction.TRANSFERRED &&
    actionStatus === EligibleInvestorActionStatus.PENDING
  const isForceTransferring =
    transferAction === EligibleInvestorTransferAction.FORCE_TRANSFERRED &&
    actionStatus === EligibleInvestorActionStatus.PENDING
  const isFullyForceTransferred =
    transferAction === EligibleInvestorTransferAction.FORCE_TRANSFERRED &&
    actionStatus === EligibleInvestorActionStatus.FINISHED
  const isPartiallyForceTransferred =
    transferAction ===
      EligibleInvestorTransferAction.PARTIALLY_FORCE_TRANSFERRED &&
    actionStatus === EligibleInvestorActionStatus.FINISHED

  const isReissuing =
    transferAction === EligibleInvestorTransferAction.REISSUED &&
    actionStatus === EligibleInvestorActionStatus.PENDING

  const isReissued =
    transferAction === EligibleInvestorTransferAction.REISSUED &&
    actionStatus === EligibleInvestorActionStatus.FINISHED

  return {
    isNotTransferred,
    isFullyBurned,
    isTransferring,
    isForceTransferring,
    isPartiallyBurned,
    isTransferred,
    isNotBurned,
    isFullyForceTransferred,
    isPartiallyForceTransferred,
    isBurning,
    isReissuing,
    isReissued,
    isAnyTokenActionInProgress:
      actionStatus === EligibleInvestorActionStatus.PENDING,
  }
}
export const getDealStates = (deal: Deal) => {
  const dealState = deal.state

  return {
    isDraft: dealState === DealState.Draft,
    isDealPublishPendingApproval:
      dealState === DealState.DealPublishPendingApproval,
    isDealPublishRejected: dealState === DealState.DealPublishRejected,
    isDealPublishInProgress: dealState === DealState.DealPublishInProgress,
    isDealPublishFailed: dealState === DealState.DealPublishFailed,
    isDealPublished: dealState === DealState.DealPublished,
    isDealClosed: dealState === DealState.DealClosed,
    isMintPendingApproval: dealState === DealState.MintPendingApproval,
    isMintRejected: dealState === DealState.MintRejected,
    isMintApproved: dealState === DealState.MintApproved,
    isMintInProgress: dealState === DealState.MintInProgress,
    isMintFailed: dealState === DealState.MintFailed,
    isDealActive: dealState === DealState.DealActive,
    isUnknown: dealState === DealState.Unknown,
  }
}

export const getStateMatchInAnyDeal = (deals: Deal[]) =>
  deals.reduce(
    (acc, currentDeal) => {
      const {
        isDealActive,
        isMintInProgress,
        isDealPublishInProgress,
        isDealClosed,
      } = getDealStates(currentDeal)

      return {
        ...acc,
        isAnyDealStateInProgress:
          acc.isAnyDealStateInProgress ||
          [isMintInProgress, isDealPublishInProgress].some(Boolean),
        isAnyDealStateActive: acc.isAnyDealStateActive || isDealActive,
        isAnyDealStateClosed: acc.isAnyDealStateClosed || isDealClosed,
      }
    },
    {
      isAnyDealStateInProgress: false,
      isAnyDealStateActive: false,
      isAnyDealStateClosed: false,
    }
  )

export const getDealStateMatchInEveryDeal = (deals: Deal[]) =>
  deals.reduce(
    (acc, currentDeal) => {
      const {
        isDealActive,
        isMintInProgress,
        isDealClosed,
        isDealPublishInProgress,
      } = getDealStates(currentDeal)

      return {
        ...acc,
        isEveryDealStateInProgress:
          acc.isEveryDealStateInProgress &&
          [isMintInProgress, isDealPublishInProgress].some(Boolean),
        isEveryDealStateActive: acc.isEveryDealStateActive && isDealActive,
        isEveryDealStateClosed: acc.isEveryDealStateClosed && isDealClosed,
      }
    },
    {
      isEveryDealStateInProgress: true,
      isEveryDealStateActive: true,
      isEveryDealStateClosed: true,
    }
  )

export const getTokenStatusMatchInAnyInvestor = (deal: Deal) => {
  return normalizeEligibleInvestorData(deal).reduce(
    (acc, currentInvestor) => {
      const {
        isTransferring,
        isForceTransferring,
        isAnyTokenActionInProgress,
        isBurning,
      } = getInvestorTokenStatuses(currentInvestor)

      return {
        ...acc,
        isAnyTokenActionInProgress:
          acc.isAnyTokenActionInProgress || isAnyTokenActionInProgress,
        isAnyTokenBurnInProgress: acc.isAnyTokenBurnInProgress || isBurning,
        isAnyTypeOfTokenTransferInProgress:
          acc.isAnyTypeOfTokenTransferInProgress ||
          [isForceTransferring, isTransferring].some(Boolean),
        isAnyRegularTransferTokenInProgress:
          acc.isAnyRegularTransferTokenInProgress || isTransferring,
        isAnyForceTransferTokenInProgress:
          acc.isAnyForceTransferTokenInProgress || isForceTransferring,
      }
    },
    {
      isAnyTokenActionInProgress: false,
      isAnyTokenBurnInProgress: false,
      isAnyTypeOfTokenTransferInProgress: false,
      isAnyRegularTransferTokenInProgress: false,
      isAnyForceTransferTokenInProgress: false,
    }
  )
}

export const normalizeEligibleInvestorData = (
  deal: Deal
): EligibleInvestor[] => {
  const dealTypeData = getDealTypeData(deal)

  const eligibleInvestors =
    deal.stage === DealStage.MainSale
      ? (dealTypeData.eligibleInvestors as MainSaleEligibleInvestor[]).map(
          ({ eligibleInvestor, ...investorMetadata }) => ({
            ...eligibleInvestor,
            investorMetadata,
          })
        )
      : (dealTypeData.eligibleInvestors as User[]).map((user) => ({
          ...user,
          investorMetadata: null,
        }))

  return eligibleInvestors as EligibleInvestor[]
}

export const getDealStatsData = (dealStats?: DealStats): DealStats => {
  return (
    dealStats || {
      totalInvestmentAmount: 0,
      maxInvestmentAmount: 0,
      tokenHolders: 0,
      circulatingSupply: 0,
      totalSupply: 0,
      burnedSupply: 0,
    }
  )
}

export const getPredefinedInvestorId = (id?: number) => {
  return id ? `${investmentAmountIdPrefix}${id}` : investmentAmountIdPrefix
}
