import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { getMultipleOrganizationInvites } from 'src/api/routes'
import { defaultTablePageSize } from 'src/utils'

type OrgUsersRequestParams = { id: number; status: string; pageSize?: number }

export default function useOrgUsersPage({
  id,
  status,
  pageSize = defaultTablePageSize,
}: OrgUsersRequestParams) {
  const [page, setPage] = useState(1)
  const qb = RequestQueryBuilder.create()

  const queryString = qb
    .setPage(page)
    .setLimit(pageSize)
    .setFilter({ field: 'status', operator: '$eq', value: status })
    .query()

  return {
    setPage,
    ...useQuery([queryKeys.orgUsers, queryString], () =>
      getMultipleOrganizationInvites({ id, queryString })
    ),
  }
}
