import { DealState } from 'src/types'

import { useApprovalActions } from '../context'
import { ApprovedDeal } from './ApprovedDeal'
import { PendingApproval } from './PendingApproval'
import { RejectedDeal } from './RejectedDeal'

function ViewApproval() {
  const { selectedApprovalData } = useApprovalActions()

  if (!selectedApprovalData) return null

  type StatusOverlayOptions = {
    [key in DealState]: JSX.Element | null
  }

  const StatusOverlay = () => {
    const dealStatus = selectedApprovalData?.deal.state
    const statusDependantOverlayMap: StatusOverlayOptions = {
      [DealState.DealPublishPendingApproval]: (
        <PendingApproval selectedApprovalData={selectedApprovalData} />
      ),
      [DealState.DealActive]: (
        <ApprovedDeal selectedApprovalData={selectedApprovalData} />
      ),
      [DealState.DealPublished]: (
        <ApprovedDeal selectedApprovalData={selectedApprovalData} />
      ),
      [DealState.DealPublishRejected]: (
        <RejectedDeal selectedApprovalData={selectedApprovalData} />
      ),
      [DealState.DealPublishInProgress]: null,
      [DealState.DealPublishFailed]: (
        <RejectedDeal selectedApprovalData={selectedApprovalData} />
      ),
      [DealState.DealClosed]: (
        <ApprovedDeal selectedApprovalData={selectedApprovalData} />
      ),
      [DealState.MintInProgress]: null,
      [DealState.MintFailed]: (
        <RejectedDeal selectedApprovalData={selectedApprovalData} />
      ),
      [DealState.MintPendingApproval]: (
        <PendingApproval selectedApprovalData={selectedApprovalData} />
      ),
      [DealState.MintApproved]: (
        <ApprovedDeal selectedApprovalData={selectedApprovalData} />
      ),
      [DealState.MintRejected]: (
        <RejectedDeal selectedApprovalData={selectedApprovalData} />
      ),
      [DealState.Draft]: null,
      [DealState.Unknown]: null,
    }

    return statusDependantOverlayMap[dealStatus || DealState.Unknown]
  }

  return selectedApprovalData ? <StatusOverlay /> : null
}

export default ViewApproval
