import {
  Button,
  CheckedDocument,
  CloseIcon,
} from '@allinfra-ltd/allinfra-design-system'
import { useTranslation } from 'react-i18next'

export interface InviteSuccessProps {
  email?: string
  onActionBtnClick: () => void
  onClose: () => void
}

const InviteSuccess = ({
  onClose,
  onActionBtnClick,
  email,
}: InviteSuccessProps) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="ml-auto flex w-full flex-row justify-between">
        <div className=" invisible w-10" />
        <div className="mx-auto flex items-center justify-center h-26 w-26 rounded-full">
          <CheckedDocument aria-hidden="true" />
        </div>
        <div className="h-10 w-10 cursor-pointer rounded-full  p-2 hover:shadow-sm active:shadow-inner">
          <CloseIcon onClick={onClose} />
        </div>
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <h3 className="text-lg leading-6 font-bold text-gray-900">
          {t('invite.success.title')}
        </h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            {t('invite.success.descriptionFirstLine')} <b>{email}</b>
            <br />
            {t('invite.success.descriptionSecondLine')}
          </p>
        </div>
        <div className="mt-5 sm:mt-6">
          <Button classes="w-full" onClick={onActionBtnClick}>
            {t('invite.success.button')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default InviteSuccess
