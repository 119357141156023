import { classNames } from '@allinfra-ltd/allinfra-design-system'

interface CardProps {
  children: React.ReactNode
  classes?: string
}

function Card({ children, classes, ...props }: CardProps) {
  return (
    <div
      className={classNames(classes, 'relative overflow-hidden rounded-lg p-8')}
      {...props}
    >
      {children}
    </div>
  )
}

export default Card
