import {
  GetOrganizationMembersQueryParams,
  OrganizationInvitesQueryParams,
} from './types'

export const BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1`
export const path = (root: string, subLink: string) =>
  `${BASE_URL}${root}${subLink}`

export const ROOTS = {
  auth: '/auth',
  iam: '/iam',
  invites: '/invites',
  deals: '/deals',
  dealsMisc: '/deals/misc',
  approvals: '/approvals',
  apiKeys: '/keys',
}

export const apiUrl = {
  login: path(ROOTS.auth, '/login'),
  logout: path(ROOTS.auth, '/logout'),
  refresh: path(ROOTS.auth, '/refresh'),
  signUp: path(ROOTS.auth, '/signup'),
  password: path(ROOTS.auth, '/password'),
  get recoverAccount() {
    return `${this.twoFactorAuth}/recover`
  },
  twoFactorAuth: path(ROOTS.auth, '/2fa'),
  get twoFactorAuthSecret() {
    return `${this.twoFactorAuth}/secret`
  },
  get forgotPassword() {
    return `${this.password}/forgot`
  },
  get resetPassword() {
    return `${this.password}/reset`
  },
  get resetTwoFactor() {
    return `${this.twoFactorAuth}/reset`
  },
  invite(id: string) {
    return `${this.invites}${id}`
  },
  invites: path(ROOTS.invites, '/'),
  invitesQuery(queryString: string) {
    return `${this.invites}?${queryString}`
  },
  availableRoles: path(ROOTS.invites, '/available-roles'),
  roles: path(ROOTS.iam, '/roles'),
  organizations: path(ROOTS.iam, '/organizations'),
  organization(id: number) {
    return `${this.organizations}/${id}`
  },
  organizationInvites(orgId: number) {
    return `${this.organizations}/${orgId}/invites`
  },
  organizationInvite(orgId: number, inviteId: number) {
    return `${this.organizationInvites(orgId)}/${inviteId}`
  },
  organizationMembers(orgId: number) {
    return `${this.organizations}/${orgId}/members`
  },
  organizationMember(orgId: number, memberId: number) {
    return `${this.organizationMembers(orgId)}/${memberId}`
  },
  get myOrganization() {
    return `${this.organizations}/me`
  },
  myOrganizationQuery(queryString: string) {
    return `${this.myOrganization}?${queryString}`
  },
  users: path(ROOTS.iam, '/users'),
  get loggedInUser() {
    return `${this.users}/me`
  },
  usersQuery(queryString: string) {
    return `${this.users}?${queryString}`
  },
  user(id: string) {
    return `${this.users}/${id}`
  },
  userStatus(id: string) {
    return `${this.user(id)}/status`
  },
  regenerateUserWallet(id: number) {
    return `${this.users}/${id}/wallet/regenerate`
  },
  organizationInvitesQuery({
    id,
    queryString,
  }: OrganizationInvitesQueryParams) {
    return `${this.organizationInvites(id)}?${queryString}`
  },
  organizationMembersQuery({
    id,
    queryString,
  }: GetOrganizationMembersQueryParams) {
    return `${this.organizationMembers(id)}?${queryString}`
  },
  deals: path(ROOTS.deals, ''),
  dealsQuery(queryString: string) {
    return `${this.deals}?${queryString}`
  },
  singleDeal(id: number) {
    return path(ROOTS.deals, `/${id}`)
  },
  singleDealQuery({ id, queryString }: { id: number; queryString: string }) {
    return `${this.singleDeal(id)}?${queryString}`
  },
  dealStats(id: number) {
    return path(ROOTS.deals, `/${id}/stats`)
  },
  dealsCurrencies: path(ROOTS.dealsMisc, '/currencies'),
  dealsRanking: path(ROOTS.dealsMisc, '/ranking'),
  dealsCouponPeriod: path(ROOTS.dealsMisc, '/coupon-period'),
  dealsMainSale: path(ROOTS.deals, '/main-sale'),
  dealsPreDealSolicitation: path(ROOTS.deals, '/pre-deal'),

  dealTransactions(id: number, queryString: string) {
    return `${this.deals}/${id}/transactions?${queryString}`
  },

  dealPublish(id: number) {
    return `${this.deals}/pre-deal/${id}/publish`
  },

  dealMint(id: number) {
    return `${this.deals}/${id}/mint`
  },

  dealRequestMint(id: number) {
    return `${this.dealMint(id)}/request`
  },

  dealMainState(id: number) {
    return `${this.deals}/${id}/main-sale`
  },

  dealTokensTransfer(id: number) {
    return `${this.deals}/${id}/transfer`
  },

  dealTokensForceTransfer(id: number) {
    return `${this.dealTokensTransfer(id)}/force`
  },

  dealTokensTransferAdditional(id: number) {
    return `${this.dealTokensTransfer(id)}/additional`
  },

  dealTokensBurn(id: number) {
    return `${this.deals}/${id}/burn`
  },

  dealTokenReissue(id: number) {
    return `${this.deals}/${id}/reissue`
  },

  approvals: path(ROOTS.approvals, ''),
  approvalsTypeQuery(queryString: string) {
    return `${this.approvals}?${queryString}`
  },
  approvalsUpdateStatus(id: number) {
    return `${this.approvals}/${id}/status`
  },
  apiKeys: path(ROOTS.apiKeys, ''),
  apiKeysQuery(queryString: string) {
    return `${this.apiKeys}?${queryString}`
  },
  apiKeyRevoke(id: number) {
    return `${this.apiKeys}/${id}/revoke`
  },
}

export const publicApi = [
  apiUrl.login,
  apiUrl.signUp,
  apiUrl.password,
  apiUrl.twoFactorAuth,
  apiUrl.twoFactorAuthSecret,
  apiUrl.forgotPassword,
  apiUrl.resetPassword,
  apiUrl.recoverAccount,
  apiUrl.resetTwoFactor,
]
