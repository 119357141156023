import { useMutation } from 'react-query'
import { postDealTokensTransfer } from 'src/api/routes'

const postTransfer = ({
  dealId,
  body,
}: {
  dealId: number
  body: { investors: number[] }
}) => postDealTokensTransfer({ dealId, body })

export function useDealTokensTransfer() {
  return useMutation(postTransfer)
}
