import {
  InputBase,
  InputBaseProps,
  MagnifierTiltedRightIcon,
} from '@allinfra-ltd/allinfra-design-system'

function SearchInput(props: Partial<InputBaseProps>) {
  return <InputBase {...props} leadingIcon={<MagnifierTiltedRightIcon />} />
}

export default SearchInput
