import { apiUrl } from 'src/api'
import { Approvals, UpdateApprovalStatusProps } from 'src/types'

import { api } from '../axios-base-instance'

export const getApprovals = () => api.get<Approvals>(apiUrl.approvals)

export async function getApprovalsType(queryString: string) {
  const { data } = await api.get<Approvals>(
    apiUrl.approvalsTypeQuery(queryString)
  )
  return data
}

export async function updateApproval({
  id,
  status,
  reason = '',
}: UpdateApprovalStatusProps) {
  const { data } = await api.put(apiUrl.approvalsUpdateStatus(id), {
    status,
    reason,
  })
  return data
}
