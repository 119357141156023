import {
  Avatar,
  Button,
  CheckedDocument,
  CloseIcon,
  ErrorAlert,
  ModalBase,
} from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import { useForm } from 'react-hook-form'
import { useRemoveOrganisationMember } from 'src/hooks'
import { User } from 'src/types'

import { getRoleName } from '../Users/utils'

export interface RemoveUserModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  selectedUser: User
  orgId: number
}

function RemoveUserModal({
  isOpen,
  setIsOpen,
  selectedUser,
  orgId,
}: RemoveUserModalProps) {
  const { handleSubmit } = useForm({ mode: 'onTouched' })
  const {
    isLoading,
    isSuccess,
    isError,
    error,
    mutate: deleteMember,
  } = useRemoveOrganisationMember()

  const handleRemoveUser = () => {
    if (selectedUser && selectedUser.id)
      deleteMember({ orgId, userId: selectedUser.id })
  }

  return (
    <ModalBase {...{ isOpen, setIsOpen }}>
      {isSuccess ? (
        <>
          <div className="ml-auto flex w-full flex-row justify-between">
            <div className=" invisible w-10" />
            <div className="mx-auto flex items-center justify-center h-26 w-26 rounded-full">
              <CheckedDocument aria-hidden="true" />
            </div>
            <div className="h-10 w-10 cursor-pointer rounded-full  p-2 hover:shadow-sm active:shadow-inner">
              <CloseIcon onClick={() => setIsOpen(false)} />
            </div>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-bold text-gray-900">
              {`${t('organizations.removeUserModal.success.title')}`}
            </h3>
            <div className="mt-5 sm:mt-6">
              <Button classes="w-full" onClick={() => setIsOpen(false)}>
                {`${t(
                  'organizations.removeUserModal.success.goToActionButton'
                )}`}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="px-4 pt-3 pb-4">
          <div className="ml-auto flex w-full flex-row justify-between items-center">
            <h4 className="text-gray-900 text-lg font-bold">
              {`${t('organizations.removeUserModal.title')}`}
            </h4>
            <div className="h-10 w-10 cursor-pointer rounded-full  p-2 hover:shadow-sm active:shadow-inner">
              <CloseIcon
                onClick={() => {
                  setIsOpen(false)
                }}
              />
            </div>
          </div>
          <p className="my-8 text-gray-500 text-sm font-normal">
            {`${t('organizations.removeUserModal.description')}`}
          </p>
          <div className="my-4">
            <div className="p-4 border rounded-lg border-gray-200 flex justify-center">
              <Avatar size="large" shape="circular">
                {`${selectedUser.firstName?.substring(
                  0,
                  1
                )} ${selectedUser.lastName?.substring(0, 1)}`}
              </Avatar>
              <div className="flex justify-between mx-4 w-4/5">
                <div className="flex flex-col">
                  <span className="font-normal">{`${selectedUser.firstName} ${selectedUser.lastName}`}</span>
                  <span className="text-gray-500 text-xs font-normal">
                    {selectedUser && getRoleName(selectedUser.role)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {isError ? (
            <ErrorAlert>{error?.response?.data?.message}</ErrorAlert>
          ) : null}
          <form onSubmit={handleSubmit(handleRemoveUser)} noValidate>
            <div className="my-4 flex flex-row justify-between">
              <Button
                size="medium"
                variant="alternative"
                onClick={() => setIsOpen(false)}
              >
                {`${t('organizations.removeUserModal.cancel')}`}
              </Button>
              <Button
                size="medium"
                variant="danger"
                classes="w-2/3"
                disabled={isLoading}
                type="submit"
              >
                {`${t('organizations.removeUserModal.removeActionButton')}`}
              </Button>
            </div>
          </form>
        </div>
      )}
    </ModalBase>
  )
}

export default RemoveUserModal
