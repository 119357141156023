import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import {
  DealDetailsPreviewContextValue,
  DealDetailsPreviewHeader,
  DealDetailsPreviewRowEditing,
  DealDetailsPreviewState,
} from 'src/types'

const DealDetailsPreviewContext =
  createContext<DealDetailsPreviewContextValue | null>(null)

const DealDetailsPreviewReducer = (
  state: DealDetailsPreviewState,
  payload: Partial<DealDetailsPreviewState>
) => ({
  ...state,
  ...payload,
})

const initialState = {
  editingRows: [],
}

export const DealDetailsPreviewProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [state, dispatch] = useReducer(DealDetailsPreviewReducer, initialState)

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return (
    <DealDetailsPreviewContext.Provider value={value}>
      {children}
    </DealDetailsPreviewContext.Provider>
  )
}

export const useDealDetailsPreview = () => {
  const context = useContext(DealDetailsPreviewContext)

  if (!context) {
    throw new Error(
      'useDealDetailsPreview must be used within a DealDetailsPreviewProvider'
    )
  }

  const { state, dispatch } = context

  const setEditingRow = useCallback(
    (row: DealDetailsPreviewRowEditing) => {
      if (state.editingRows?.length < 1) {
        dispatch({
          editingRows: [...(state.editingRows || []), row],
        })
      } else {
        dispatch({
          editingRows: state.editingRows?.filter(
            (editingRow) => JSON.stringify(editingRow) !== JSON.stringify(row)
          ),
        })
      }
    },
    [state, dispatch]
  )

  /**
   * @description check if the current row is editing
   * @param id row id
   * @param headerName name of the header. eg: General Information
   */
  const isCurrentRowEditing = (
    id: string,
    headerName: DealDetailsPreviewHeader
  ) =>
    state.editingRows?.find((editingRow) => {
      return editingRow.rowId === id && editingRow.headerName === headerName
    })

  return {
    state,
    isCurrentRowEditing,
    setEditingRow,
  }
}
