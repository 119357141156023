export const recover = {
  modal: {
    title: 'Recover your account',
    description: 'Enter the backup code and regain access to your account',
  },

  button: {
    back: 'Back',
    recover: 'Recover Account',
  },

  twoFactor: {
    title: 'Looks like you lost your access',
    description:
      'You used one of your backup verification codes. This means that you no longer have access to your authentication device. Please re-enable you two-factor using a device you have access to.',
  },

  recoveryCode: { button: 'Finish Recovery' },
}
