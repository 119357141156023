import {
  AuthCodeInput,
  Button,
  ErrorAlert,
} from '@allinfra-ltd/allinfra-design-system'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Secret } from '../types'

interface TwoFactorFormProps {
  secret?: Secret
  isLoading: boolean
  isError: boolean
  errorMessage: string
  onClickBack?: () => void
  resetError: () => void
}

const TwoFactorForm = ({
  secret,
  isLoading,
  isError,
  errorMessage,
  onClickBack,
  resetError,
}: TwoFactorFormProps) => {
  const { t } = useTranslation()
  const {
    formState: { errors },
    setValue,
    control,
  } = useFormContext()

  const copySecret = () => {
    if (!secret?.secret) return
    navigator.clipboard.writeText(secret?.secret)
  }

  return (
    <>
      <div className="rounded-lg border border-gray-300 bg-gray-100 shadow-sm my-4">
        <div className="flex justify-between p-4">
          <img
            src={secret?.qrCodeImage}
            alt=""
            className="h-120 w-120 mr-[16px]"
            width="120"
            height="120"
          />
          <p className="text-sm text-gray-500 grow">
            {t('auth.signUp.stepTwo.form.qrCard.title')}
          </p>
        </div>
        <div className="border-t-2 border-gray-300 flex justify-between p-4">
          <div className="text-gray-900 font-semibold">{secret?.secret}</div>
          <Button
            type="button"
            onClick={() => copySecret()}
            variant="text"
            customSizeClasses="py-0 px-2"
            size="medium"
          >
            {t('auth.signUp.stepTwo.form.qrCard.button')}
          </Button>
        </div>
      </div>

      <div className="my-4">
        <h3 className="text-gray-500 my-4">
          {t('auth.signUp.stepTwo.form.codeInput.label')}
        </h3>
        <Controller
          name="code"
          control={control}
          rules={{
            required: t(
              'auth.signUp.stepTwo.form.codeInput.required'
            ) as string,
            minLength: {
              value: 6,
              message: t(
                'auth.signUp.stepTwo.form.codeInput.lengthErrorMessage'
              ) as string,
            },
            maxLength: {
              value: 6,
              message: t(
                'auth.signUp.stepTwo.form.codeInput.lengthErrorMessage'
              ) as string,
            },
          }}
          render={({ field: { onChange } }) => (
            <AuthCodeInput
              length={6}
              isError={!!errors.code}
              errorLabel={`${errors.code?.message || ''}`}
              onInputChanges={(code) => {
                setValue('code', code)
                resetError()
                onChange(code)
              }}
            />
          )}
        />
      </div>
      {isError && (
        <ErrorAlert>
          <p>{errorMessage}</p>
        </ErrorAlert>
      )}
      <div className="flex justify-between my-4">
        {onClickBack ? (
          <Button
            type="button"
            size="medium"
            classes="w-1/3"
            variant="alternative"
            onClick={onClickBack}
            disabled={isLoading}
          >
            {t('auth.signUp.stepTwo.form.codeInput.backButton')}
          </Button>
        ) : null}
        <Button
          type="submit"
          classes={onClickBack ? 'w-3/5' : 'w-full'}
          disabled={isLoading}
        >
          {t('auth.signUp.stepTwo.form.codeInput.submitButton')}
        </Button>
      </div>
    </>
  )
}

export default TwoFactorForm
