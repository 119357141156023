export enum ErrorCodes {
  BadRequestDealTokensLeft = 'BadRequestDealTokensLeft',
  BadRequestInvitationCodeInvalid = 'BadRequestInvitationCodeInvalid',
  BadRequestOrganisationInactive = 'BadRequestOrganisationInactive',
  BadRequestOrganisationUserExists = 'BadRequestOrganisationUserExists',
  BadRequestDealInvestorNotFound = 'BadRequestDealInvestorNotFound',
  BadRequestDealInvestorsNotFound = 'BadRequestDealInvestorsNotFound',
  BadRequestDealIdNotNumber = 'BadRequestDealIdNotNumber',
  BadRequestIdNotNumber = 'BadRequestIdNotNumber',
  UnauthorizedInvalidToken = 'UnauthorizedInvalidToken',
  UnauthorizedInvalidCode = 'UnauthorizedInvalidCode',
  UnauthorizedExpiredToken = 'UnauthorizedExpiredToken',
  UnauthorizedNotEnabled2FA = 'UnauthorizedNotEnabled2FA',
  UnauthorizedIncorrectEmailPassword = 'UnauthorizedIncorrectEmailPassword',
  ForbiddenInviteProcessed = 'ForbiddenInviteProcessed',
  ForbiddenClosedPreDealSolicitation = 'ForbiddenClosedPreDealSolicitation',
  ForbiddenNotApprovedPreDealSolicitation = 'ForbiddenNotApprovedPreDealSolicitation',
  ForbiddenDealNotInPreDeal = 'ForbiddenDealNotInPreDeal',
  ForbiddenDealNotActive = 'ForbiddenDealNotActive',
  ForbiddenDealNotMintApproved = 'ForbiddenDealNotMintApproved',
  ForbiddenClaimAccess = 'ForbiddenClaimAccess',
  ForbiddenDealAction = 'ForbiddenDealAction',
}

export interface ErrorResponse {
  statusCode: number
  message: string[]
  error: string
}
