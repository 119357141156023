import { DataGrid } from '@allinfra-ltd/allinfra-design-system'
import { useMemo } from 'react'
import { Deal } from 'src/types'

import { getColumnsAndData } from './utils'

function InvestorListPreview({ deal }: { deal: Deal }) {
  const { columnHeaders, rowData } = useMemo(
    () => getColumnsAndData(deal),
    [deal]
  )

  return (
    <div>
      <DataGrid
        customStyles={{
          mainWrapper: 'h-[30rem]',
        }}
        columns={columnHeaders}
        data={rowData}
      />
    </div>
  )
}

export default InvestorListPreview
