import { useMutation } from 'react-query'
import { api, apiUrl } from 'src/api'
import { queryClient } from 'src/api/query-client'
import { queryKeys } from 'src/api/query-keys'
import { ErrorResponse } from 'src/types'

function deleteInvite(id: string) {
  return api.delete(apiUrl.invite(id))
}

export function useDeleteInvite() {
  return useMutation<unknown, ErrorResponse, string, unknown>(deleteInvite, {
    onSuccess: () => queryClient.invalidateQueries([queryKeys.pendingUsers]),
  })
}
