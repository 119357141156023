import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import {
  getDealsCouponPeriod,
  getDealsCurrencies,
  getDealsQuery,
  getDealsRanking,
} from 'src/api/routes'
import { defaultTablePageSize } from 'src/utils'

const getCurrencies = async () => {
  const { data } = await getDealsCurrencies()
  return data
}

export function useDealsCurrencies() {
  return useQuery([queryKeys.dealsCurrency], getCurrencies)
}

const getRankings = async () => {
  const { data } = await getDealsRanking()
  return data
}

export function useDealsRanking() {
  return useQuery([queryKeys.dealsRanking], getRankings)
}

const getCouponsPeriod = async () => {
  const { data } = await getDealsCouponPeriod()
  return data
}

export function useCouponPeriod() {
  return useQuery([queryKeys.dealsCouponPeriod], getCouponsPeriod)
}

export function useDeals() {
  const [page, setPage] = useState(1)
  const qb = RequestQueryBuilder.create()
  const queryString = qb
    .setPage(page)
    .setLimit(defaultTablePageSize)
    .setJoin([
      {
        field: 'token',
      },
      {
        field: 'createdBy',
        select: ['id', 'firstName', 'lastName', 'updatedAt', 'organizationId'],
      },
      {
        field: 'mainSale',
        select: ['id', 'generalInfo', 'eligibleInvestors'],
      },
      {
        field: 'mainSale.generalInfo',
      },
      {
        field: 'mainSale.specificDetails',
      },
      {
        field: 'mainSale.eligibleInvestors',
      },
      {
        field: 'mainSale.eligibleInvestors.eligibleInvestor',
      },
      {
        field: 'mainSale.eligibleInvestors.eligibleInvestor.currentWallet',
      },
      {
        field: 'approvals',
      },
    ])
    .query()

  return {
    setPage,
    ...useQuery(
      [queryKeys.dealsList, queryString],
      () => getDealsQuery(queryString),
      {
        keepPreviousData: true,
      }
    ),
  }
}
