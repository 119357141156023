import { BrowserRouterSubPaths } from 'src/types'
import { capitalize } from 'src/utils'

interface SchemaEntry {
  path: string
  children?: Record<string, SchemaEntry>
}

export const subPathSchema: Record<string, SchemaEntry> = {
  root: {
    path: '/',
    children: {
      login: {
        path: 'login',
      },
      forgotPassword: {
        path: 'forgot-password',
      },
      checkInbox: {
        path: 'check-inbox',
      },
      resetPassword: {
        path: 'reset-password',
      },
      createAccount: {
        path: 'create-account',
      },
      recoverAccount: {
        path: 'recover-account',
      },
      users: {
        path: 'users',
        children: {
          active: {
            path: 'active',
          },
          pending: {
            path: 'pending',
          },
          disabled: {
            path: 'disabled',
          },
        },
      },
      organizations: {
        path: 'organizations',
        children: {
          active: {
            path: 'active',
          },
          pending: {
            path: 'pending',
          },
        },
      },
      approvals: {
        path: 'approvals',
        children: {
          organizations: {
            path: 'organizations',
          },
          deals: {
            path: 'deals',
          },
        },
      },
      deals: {
        path: 'deals',
      },
    },
  },
}

function extractSchemaSubPaths(
  schema: Record<string, SchemaEntry>,
  prefix = ''
) {
  return Object.entries(schema).reduce((acc, [pathKey, value]) => {
    const subPathKeyName = prefix + capitalize(pathKey)
    const { children: childrenOfCurrentPath, path: currentPath } = value
    const childrenSubPaths: Record<string, string> = childrenOfCurrentPath
      ? extractSchemaSubPaths(childrenOfCurrentPath, subPathKeyName)
      : {}

    return { ...acc, [subPathKeyName]: currentPath, ...childrenSubPaths }
  }, {})
  // Recursively extract sub paths from schema in a flat object
  // e.g. extractSchemaSubPaths({root: {path: '/', children: {login: {path: 'login'}}}}) => {Root: '/', RootLogin: 'login'}
}

export const subPaths: BrowserRouterSubPaths = extractSchemaSubPaths(
  subPathSchema
) as BrowserRouterSubPaths
