import axios from 'axios'
import setRefreshTokenInterceptor, {
  isPublic,
} from 'src/api/axios-auth-interceptor'

import { BASE_URL, apiUrl } from './api-paths'

const instance = axios.create({ baseURL: BASE_URL })

instance.interceptors.request.use(
  (config) => {
    if (config.url && !isPublic(config.url)) {
      const token =
        apiUrl.refresh === config.url
          ? window.localStorage.getItem('refreshToken')
          : window.localStorage.getItem('authToken')

      if (token)
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          Authorization: `Bearer ${JSON.parse(token)}`,
          Accept: 'application/json',
          'Accept-Language': 'en',
        }
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

setRefreshTokenInterceptor(instance)

export { instance as api }
