import { useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { getLoggedInUserData } from 'src/api/routes'
import { useAuth } from 'src/context/auth-context'
import { User } from 'src/types'

export function useUser() {
  const { isLoggedIn, authToken } = useAuth()

  return useQuery<User>(
    [queryKeys.loggedInUser, { authToken }],
    getLoggedInUserData,
    {
      enabled: isLoggedIn && !!authToken,
    }
  )
}
