import { SectionHeaderProps } from 'src/types'

function SectionHeader({
  actions,
  children,
  sectionTitle,
}: Partial<SectionHeaderProps>) {
  return (
    <div className="relative pb-5">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold mb-6 text-gray-900">
          {sectionTitle}
        </h1>
      </div>
      <div className="mt-4">
        <div className="flex justify-between items-center">
          {children}
          <div>{actions}</div>
        </div>
      </div>
    </div>
  )
}

export default SectionHeader
