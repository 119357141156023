export const navigation = {
  sidebar: {
    approvals: 'Approvals',
    users: 'Users',
    organisations: 'Organisations',
    deals: 'Deals',
  },
  status: {
    active: 'Active',
    pending: 'Pending',
    disabled: 'Disabled',
  },
}
