import { t } from 'i18next'

export const pendingApprovalTabs = [
  {
    id: '1',
    name: t('deals.tabNames.dealDetails'),
    isActive: true,
  },
  {
    id: '2',
    name: t('deals.tabNames.investors'),
  },
]
