import { Column, Row } from 'react-table'

export function applyGlobalFilterMetadata(
  rows: Row<{ globalFilterMetadata: string }>[],
  _columnIds: Column[],
  globalFilterValue: string
) {
  return rows.filter((row) => {
    return row.original.globalFilterMetadata
      ?.toLowerCase()
      .includes(globalFilterValue.toLowerCase())
  })
}
