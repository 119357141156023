/* eslint-disable import/no-cycle */
import { Claim } from './claims'
import { Deal } from './deals'

export type RoleId = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export enum UserStatus {
  'Enabled' = 1,
  'Pending' = 2,
  'Disabled' = 3,
}
export interface User {
  id: number
  parentId: number
  externalId: string
  email: string
  firstName: string
  lastName: string
  organizationId: number | null
  status: UserStatus
  createdAt: string
  updatedAt: string
  deletedAt?: string | null
  role: Role
  currentWallet?: UserWallet
  wallets?: UserWallet[]
  createdDeals?: Deal[]
  actions: UserActionGuards
}

export interface UserActionGuards {
  enable: boolean
  disable: boolean
  edit: boolean
}

export interface UserWallet {
  id: number
  ethAddress: string
  kmsKeyId: string
  userId: number
  createdAt: Date
  updatedAt: Date
}

export interface Role {
  id: RoleId
  claims: Claim[]
  name: string
  createdAt: string
  updatedAt: string
}

export enum UserRole {
  'SuperAdmin' = 1,
  'Admin' = 2,
  'Issuer' = 3,
  'Operator' = 4,
  'Regulator' = 5,
  'Investor' = 6,
  'IssuerOrgOwner' = 7,
  'IssuerOrgMember' = 8,
}
