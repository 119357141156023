import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { getInvites } from 'src/api/routes'
import { defaultTablePageSize } from 'src/utils'

export default function usePendingUsersPage(pageSize = defaultTablePageSize) {
  const [page, setPage] = useState(1)
  const qb = RequestQueryBuilder.create()

  const queryString = qb
    .setPage(page)
    .setLimit(pageSize)
    .setFilter({ field: 'status', operator: '$eq', value: 'pending' })
    .query()

  return {
    setPage,
    ...useQuery([queryKeys.pendingUsers, queryString], () =>
      getInvites(queryString)
    ),
  }
}
