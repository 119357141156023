export const disclaimer = {
  title: 'Disclaimer',
  importantNotice: {
    title: 'Important Notice',
    paragraph1:
      'By accessing and using the {{companyName}} website (this <strong>“Website”</strong>), you agree to the following <strong>terms</strong> of use as they may be modified, changed, supplemented or updated from time to time (collectively, these “terms”), as well as all applicable laws and regulations. Please read the following terms carefully. If you do not agree to all of these terms, please do not use this Website or any information, links or content contained on this Website. Your access to and use of this Website constitutes your acceptance of and agreement to abide by each of the terms set forth below including our Privacy Policy which is hereby incorporated in these terms by reference. If you are using our Website on behalf of your organisation, that organisation accepts these terms.',
    paragraph2:
      'THIS WEBSITE AND ALL INFORMATION, PRODUCTS AND SERVICES PROVIDED THROUGH THIS WEBSITE ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATIONS, WARRANTIES, PROMISES OR GUARANTEES WHATSOEVER OF ANY KIND INCLUDING, WITHOUT LIMITATION, ANY REPRESENTATIONS, WARRANTIES, PROMISES OR GUARANTEES REGARDING THE ACCURACY, CURRENCY, COMPLETENESS, ADEQUACY, AVAILABILITY, SUITABILITY OR OPERATION OF THIS WEBSITE, ANY PRODUCTS OR SERVICES WE MAY PROVIDE THROUGH IT OR THE INFORMATION OR MATERIAL IT CONTAINS.',
    paragraph3:
      'IN NO EVENT WILL {{companyName}} LTD, ITS DIRECTORS, ADVISERS OR REPRESENTATIVES (THE {{companyName}} PARTIES”) BE RESPONSIBLE OR LIABLE FOR ANY CLAIMS, DAMAGES, LIABILITIES, LOSSES, COSTS OR EXPENSES OF ANY KIND, WHETHER DIRECT OR INDIRECT, CONSEQUENTIAL, COMPENSATORY, INCIDENTAL, ACTUAL, PUNITIVE OR SPECIAL (INCLUDING DAMAGES FOR LOSS OF BUSINESS, REVENUES, PROFITS, DATA, USE, GOODWILL OR OTHER INTANGIBLE LOSSES) REGARDLESS OF WHETHER THE {{companyName}} PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LIABILITIES, LOSSES, COSTS OR EXPENSES, ARISING OUT OF OR IN CONNECTION WITH:',
    paragraph3List: [
      '(A) THE USE OR PERFORMANCE OF THIS WEBSITE;',
      '(B) ANY PROVISION OF OR FAILURE TO PROVIDE THIS WEBSITE OR ITS SERVICES (INCLUDING WITHOUT LIMITATION ANY LINKS ON OUR WEBSITE);',
      '(C) ANY INFORMATION AVAILABLE FROM THIS WEBSITE;',
      '(D) ANY CONDUCT OF, OR CONTENT FROM, ANY THIRD PARTY;',
      '(E) UNAUTHORISED ACCESS, USE OR ALTERATION OF THE TRANSMISSION OF DATA OR CONTENT TO OR FROM US; OR',
      '(F) THE FAILURE TO RECEIVE IN ANY WAY THE TRANSMISSION OF ANY DATA, CONTENT, FUNDS OR PROPERTY FROM YOU.',
    ],
    paragraph4:
      'This Website may not be copied, reproduced or redistributed, in whole or in part, to any other person in any manner without {{companyName}}’s written consent. The access of this Website in certain jurisdictions may be restricted by law and recipients should inform themselves about and observe any such restrictions.',
  },
  noOffering: {
    title: 'No Offering',
    paragraph1:
      'This Website contains information about the general activities of {{companyName}}. It does not purport to present a comprehensive overview of, or contain all the information necessary to evaluate an investment in, {{companyName}} or any affiliated entity (together <strong>“{{companyName}} Issuers”</strong>) or any non-affiliated entity (<strong>“Third Party Issuers”</strong>) which may offer Tokens (as defined below) on this Website and does not constitute a recommendation to invest in any {{companyName}} Issuer, any Third Party Issuer or otherwise.',
    paragraph2:
      'This Website is for information purposes only and is not a prospectus, disclosure document or other offering document under any law, nor does it constitute or form part of any present or future offer, invitation or inducement to sell or issue, or any invitation, inducement, recommendation or solicitation of an offer to purchase or subscribe for, any tokens developed or produced by any {{companyName}} Issuer or any Third Party Issuer (<strong>“Tokens”</strong>) in any jurisdiction. No part of this Website, nor the fact of its distribution, should form the basis of, or be relied on in connection with any contract or commitment or investment decision whatsoever.',
    paragraph3:
      'Any offering of any Token will be made pursuant to separate and distinct offering documentation, and in such case the information on this Website will be superseded in its entirety by any such offering documentation in final form. In addition, as this Website only contains general, summary and selected information about any {{companyName}} Issuer or Third Party Issuer, it may omit material information about that {{companyName}} Issuer or Third Party Issuer and is not a complete description of such {{companyName}} Issuer’s or Third Party Issuer’s business and the risks relating to it. Therefore, this Website should not form the basis of any investment decision to purchase or sell any Token. Any decision to purchase Tokens in the context of an offering of Tokens (if any) should be made solely on the basis of information contained in the offering documentation published in relation to such offering.',
    paragraph4:
      'The information in this Website does not constitute financial advice (nor investment, tax, accounting or legal advice) and does not take into account an investor’s individual investment objectives, including the merits and risks involved in an investment in any {{companyName}} Issuer, any Third Party Issuer or any of their securities, or an investor’s financial situation, tax position or particular needs. No recommendation is made as to how investors should exercise any investment decision.',
  },
  informationDisclaimer: {
    title: 'Information Disclaimer',
    paragraph1:
      'This Website has been prepared by {{companyName}} based on information and data which {{companyName}} considers reliable, but {{companyName}} makes no representation or warranty, express or implied, as to and no reliance should be placed on, the fairness, accuracy, completeness or correctness of the information contained herein or any statement made in this Website. Without limitation of the foregoing, {{companyName}} undertakes no obligation to maintain the fairness, accuracy, completeness or correctness of this Website on an ongoing basis and under no circumstances shall the availability of this Website create any implication that the information contained herein is fair, accurate, complete or correct as of the date on which this Website is accessed. This Website has not been independently verified.',
  },
  futurePerformance: {
    title: 'Future Performance',
    paragraph1:
      'This Website contains “forward-looking statements”. Such forward-looking statements involve known and unknown risks, uncertainties and other important factors beyond {{companyName}}’s control that could cause the actual results, performance or achievements of {{companyName}} to be materially different from future results, performance or achievements expressed or implied by such forward-looking statements. Attendees are cautioned not to place undue reliance on forward looking statements. No representation, warranty or assurance (express or implied) is given that the occurrence of the events expressed or implied in any forward-looking statements in this Website will actually occur.',
  },
  impliedAgreement: {
    title:
      'By accessing this Website, users of this Website agree to be bound by the foregoing limitations and obligations.',
  },
}
