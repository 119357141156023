import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { Role, getRoles } from 'src/api/routes'

const queryRoles = async () => {
  const { data } = await getRoles()
  return data
}

export function useRoles() {
  return useQuery<Role[], AxiosError<any>, Role[], string[]>(
    [queryKeys.roles],
    queryRoles
  )
}
