import { useMemo } from 'react'
import {
  CreateDealFlatDataKeys as DataKeys,
  Deal,
  DealDetailsPreviewHeader,
} from 'src/types'

import DealDetailsGrid from './DealDetailsGrid'
import { DealDetailsPreviewProvider } from './context/deal-details-preview-context'
import {
  formatDealDetails,
  getGridColumns,
  getStatusValueAndColor,
} from './utils'

function DealDetailsEditablePreview({
  selectedDealData,
}: {
  selectedDealData: Deal
}) {
  const { state } = selectedDealData || {}
  const { generalInfo, specificDetails, smartContractDetails } = useMemo(
    () => formatDealDetails(selectedDealData),
    [selectedDealData]
  )

  const generalInfoData = useMemo(
    () => [
      {
        key: 'Status',
        value: getStatusValueAndColor(state),
        id: DataKeys.Status,
      },
      ...Object.values(generalInfo),
    ],
    [selectedDealData]
  )

  const smartContractDetailsData = useMemo(
    () => Object.values(smartContractDetails),
    [selectedDealData]
  )

  const isMintPreview = !!selectedDealData?.token

  return (
    <DealDetailsPreviewProvider>
      <div className="mt-8 space-y-10">
        {isMintPreview ? (
          <DealDetailsGrid
            columns={getGridColumns(
              DealDetailsPreviewHeader.smartContractDetails
            )}
            data={smartContractDetailsData}
          />
        ) : null}
        <DealDetailsGrid
          columns={getGridColumns(
            DealDetailsPreviewHeader.generalInfo,
            !isMintPreview
          )}
          data={generalInfoData}
        />

        <DealDetailsGrid
          columns={getGridColumns(
            DealDetailsPreviewHeader.specificDetails,
            !isMintPreview
          )}
          data={Object.values(specificDetails)}
        />
      </div>
    </DealDetailsPreviewProvider>
  )
}

export default DealDetailsEditablePreview
