import React from 'react'
import { useTranslation } from 'react-i18next'

interface HeadingProps {
  children: React.ReactNode
}
export const Heading = React.forwardRef<
  HTMLHeadingElement | null,
  HeadingProps
>(({ children }, ref) => (
  <h2 ref={ref} className="text-2xl font-semibold my-6">
    {children}
  </h2>
))

export const TranslationOffsetUnorderedList = ({
  translationKey,
  values,
}: {
  translationKey: string
  values?: { [key: string]: string }
}) => {
  const { t } = useTranslation()
  const translationKeyList: string[] = t(translationKey, {
    returnObjects: true,
    ...values,
  })

  return (
    <ul className="list-disc my-4">
      {translationKeyList.map((key) => (
        <li
          className="ml-6" // this offset is required to make the li dots visible
          key={key}
        >
          {key}
        </li>
      ))}
    </ul>
  )
}
