import {
  CheckBoxOutlineIcon,
  DollarReceiptOutlineIcon,
  TernaryTreeIcon,
  TwoPeopleIcon,
} from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import { subPaths } from 'src/routes/subpath-schema-tree'
import { Claim } from 'src/types'

export function getNavLinksByUserRole(userClaims: Claim[] = []) {
  const claimsExcludedFromPermissionsCheck = ['Users-Read-Me']
  const filteredUserClaims = userClaims.filter(
    (claim) => !claimsExcludedFromPermissionsCheck.includes(claim.name)
  )
  const userPermissions = Object.keys(
    extractPermissionsAndRightsFromClaims(filteredUserClaims)
  )

  const { RootApprovals, RootOrganizations, RootDeals } = subPaths

  const navItemsConfig = [
    {
      name: t('navigation.sidebar.approvals'),
      path: RootApprovals,
      icon: CheckBoxOutlineIcon,
    },
    { name: t('navigation.sidebar.users'), path: 'users', icon: TwoPeopleIcon },
    {
      name: t('navigation.sidebar.organisations'),
      path: RootOrganizations,
      icon: TernaryTreeIcon,
    },
    {
      name: t('navigation.sidebar.deals'),
      path: RootDeals,
      icon: DollarReceiptOutlineIcon,
    },
  ]

  return navItemsConfig.filter((navItem) =>
    userPermissions
      .map((permissionName) => permissionName.toLowerCase())
      .includes(navItem.path)
  )
}

function extractPermissionsAndRightsFromClaims(claims: Claim[]) {
  return claims.reduce((acc, claim) => {
    const [permission, ...rest]: string[] = claim.name.split('-')
    const accessRight: string = rest.join('-')
    const existingPermission = acc[permission] as
      | { rights: string[] }
      | undefined

    if (existingPermission) {
      existingPermission.rights = [...existingPermission.rights, accessRight]

      return acc
    }

    return { ...acc, [permission]: { rights: [accessRight] } }
  }, {} as { [key: string]: { rights: string[] } })
}
