import { t } from 'i18next'
import { RegisterOptionsKey } from 'src/types'

export function isDateIsLaterThanToday(date: Date) {
  const queriedDate = new Date(date)
  const today = new Date()
  return queriedDate > today
}

export function validateMaxIssueSize(
  maxIssueSize = 0,
  incrementAmountSize = 0
) {
  return maxIssueSize % incrementAmountSize === 0
}

export function validateMinInvestmentSize(
  minInvestmentSize = 0,
  incrementAmountSize = 0
) {
  return (
    minInvestmentSize >= incrementAmountSize &&
    minInvestmentSize % incrementAmountSize === 0
  )
}

export const minNrValueErrMessage = t(
  'deals.create.specificDetails.minValueInputError'
)

export const multipleErrorMessage = t(
  'deals.create.specificDetails.multipleOfAmount'
)

export function getMaxIssueSizeErrorMessage({
  message,
  type,
}: {
  message?: string
  type?: string
}) {
  if (message || type === RegisterOptionsKey.Min) {
    return minNrValueErrMessage
  }

  if (type === RegisterOptionsKey.Validate) {
    return multipleErrorMessage
  }

  return null
}

export function getMinInvestmentSizeErrorMessage({
  message,
  type,
}: {
  message?: string
  type?: string
}) {
  if (message || type === RegisterOptionsKey.Min) {
    return minNrValueErrMessage
  }

  if (type === RegisterOptionsKey.Validate) {
    return multipleErrorMessage
  }

  return null
}
