import { apiUrl } from 'src/api'

import { api } from '../axios-base-instance'

export interface Role {
  id: number
  name: string
}

export const getRoles = () => api.get<Role[]>(apiUrl.roles)
