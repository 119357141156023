import { classNames } from '@allinfra-ltd/allinfra-design-system'
import React from 'react'

import PaperContentAligner from './PaperContentAligner'

interface PaperBandProps {
  children: React.ReactNode
  fullWidthWrapperClasses?: string
  alignedContentWrapperClasses?: string
}

function PaperBand({
  children,
  fullWidthWrapperClasses,
  alignedContentWrapperClasses = 'flex justify-between',
  ...props
}: PaperBandProps) {
  return (
    <div
      className={classNames(
        'min-w-full backdrop-blur-md border-y',
        fullWidthWrapperClasses
      )}
      {...props}
    >
      <PaperContentAligner wrapperClassNames={alignedContentWrapperClasses}>
        {children}
      </PaperContentAligner>
    </div>
  )
}

export function PaperBandOffset() {
  return <div className="h-24" />
}

export default PaperBand
