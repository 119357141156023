import { InputBase } from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import { useFormContext } from 'react-hook-form'

const TickerSymbol = ({ value, id }: { value: string; id: string }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  return (
    <InputBase
      defaultValue={value}
      {...register(id, {
        required: t('deals.create.generalInfo.symbolInputError') as string,
      })}
      autoFocus
      error={!!errors[id]}
      inputInfo={`${errors[id]?.message || ''}`}
    />
  )
}

export default TickerSymbol
