import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { getUsersQuery } from 'src/api/routes'
import { RoleId } from 'src/types'

function useUsersByRoleId({ roleId }: { roleId: RoleId }) {
  const qb = RequestQueryBuilder.create()
  const queryString = qb
    .setFilter({ field: 'roleId', operator: '$eq', value: roleId })
    .query()

  return useQuery([queryKeys.activeUsers], () => getUsersQuery(queryString))
}

export default useUsersByRoleId
