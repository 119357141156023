import { useMemo } from 'react'
import { useUser } from 'src/hooks/auth/useUser'
import { getDealStateMatchInEveryDeal } from 'src/pages/Deals/utils'
import { getUserRoleMatch } from 'src/pages/Users/utils'
import { Deal, User } from 'src/types'

const defaultPermissions = {
  isTokenEditEnabled: false,
  isApiKeyEnabled: false,
  isOrganizationEditEnabled: false,
  isRemoveOrgUserEnabled: () => false,
  isEditDealEnabled: () => false,
}

export interface UsePermissionsReturn {
  isTokenEditEnabled: boolean
  isApiKeyEnabled: boolean
  isOrganizationEditEnabled: boolean
  isRemoveOrgUserEnabled: (targetUser: User) => boolean
  isEditDealEnabled: (deal: Deal) => boolean
}

export function usePermissions(): UsePermissionsReturn {
  const { data: loggedInUser } = useUser()

  if (!loggedInUser) return defaultPermissions

  const { isAdmin, isIssuerOrgOwner } = useMemo(() => {
    return {
      ...getUserRoleMatch(loggedInUser),
    }
  }, [loggedInUser])

  return {
    isTokenEditEnabled: isAdmin,
    isApiKeyEnabled: isAdmin,
    isOrganizationEditEnabled: isIssuerOrgOwner,
    isRemoveOrgUserEnabled: getRemoveOrgUserPermissions(loggedInUser),
    isEditDealEnabled: getEditDealPermissions(loggedInUser),
  }
}

const getEditDealPermissions = (loggedInUser: User) => {
  const { isIssuerOrgOwner, isIssuer, isIssuerOrgMember } = useMemo(() => {
    return {
      ...getUserRoleMatch(loggedInUser),
    }
  }, [loggedInUser])

  return (deal: Deal) => {
    const {
      createdBy: { organizationId, id: dealCreatorId },
    } = deal

    if (isIssuer) return loggedInUser.id === dealCreatorId

    return (
      (isIssuerOrgOwner || isIssuerOrgMember) &&
      loggedInUser.organizationId === organizationId
    )
  }
}

const getRemoveOrgUserPermissions =
  (loggedInUser: User) => (targetUser: User) => {
    if (targetUser.id === loggedInUser.id) return false

    const { isEveryDealStateClosed } = targetUser.createdDeals
      ? getDealStateMatchInEveryDeal(targetUser.createdDeals)
      : { isEveryDealStateClosed: true }

    return [isEveryDealStateClosed].every(Boolean)
  }
