import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { getMyOrganizationQuery } from 'src/api/routes'

const queryMyOrganization = async (queryString: string) => {
  const { data } = await getMyOrganizationQuery(queryString)
  return data
}

export function useMyOrganization() {
  const qb = RequestQueryBuilder.create()
  const queryString = qb.setJoin([{ field: 'approvals' }]).query()
  return useQuery([queryKeys.myOrganization], () =>
    queryMyOrganization(queryString)
  )
}
