import {
  Badge,
  Button,
  ControlledPaginatedTable,
  DollarReceiptOutlineIcon,
  Modal,
  ModalOpenButton,
  TextAvatar,
  Tokens,
} from '@allinfra-ltd/allinfra-design-system'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncDebounce } from 'react-table'
import { SearchInput, SmallSpinner } from 'src/components'
import { useInterval } from 'src/hooks'
import {
  useApprovalsDealsPage,
  useUpdateApprovalStatus,
} from 'src/hooks/approvals'
import {
  approvalPagePollingInterval,
  getDealTypeData,
  getStateMatchInAnyDeal,
} from 'src/pages/Deals/utils'
import {
  Approval,
  ApprovalStatus,
  ApprovalsTypeEnum,
  DealState,
} from 'src/types'
import { blue500, defaultTablePageSize } from 'src/utils'

import { mapBadgeToStatus } from './ApprovalBadgeUtil'
import ApprovalsEmptyState from './ApprovalsEmptyState'
import {
  ModalContentApprovalDecline,
  getDeclineApprovalTextContent,
} from './Modals'
import { ViewApproval as ViewApprovalOverlay } from './ViewApproval'
import { useApprovalActions } from './context'

export default function DealsApprovals() {
  const pageSize = defaultTablePageSize
  const { t } = useTranslation()
  const { isLoading: isLoadingStatus, approveDeal } = useUpdateApprovalStatus()
  const { setSelectedApprovalData, toggleViewApprovalDetails } =
    useApprovalActions()

  const onSearch = useAsyncDebounce((value = '') => {
    const val = value.trim()
    setSearch(val)
  })

  const { data, isSuccess, isLoading, refetch, setPage, setSearch } =
    useApprovalsDealsPage({
      pageSize,
    })

  const flattenData = ({ data }: { data: Approval[] } = { data: [] }) => data
  const tableData = flattenData(data)
  const isAnyDealInProgress = useMemo(() => {
    const deals = tableData.map((tData) => tData.deal)
    return getStateMatchInAnyDeal(deals).isAnyDealStateInProgress
  }, [tableData])
  useInterval({
    callback: refetch,
    delay: isAnyDealInProgress ? approvalPagePollingInterval : null,
  })
  const getRowActionButtons = useCallback(
    (approval: Approval) => {
      const { title: declineModalTitle, description: declineModalDescription } =
        getDeclineApprovalTextContent(approval)
      const actionButtons = {
        approve: {
          match: approval.status === ApprovalStatus.Pending,
          component: (
            <Button
              key={`${approval.id} approve`}
              variant="primary"
              size="small"
              onClick={() => {
                approveDeal(approval.id)
              }}
              disabled={isLoadingStatus}
            >
              {t('approvals.buttons.approve')}
            </Button>
          ),
        },
        decline: {
          match: approval.status === ApprovalStatus.Pending,
          component: (
            <Modal key={`${approval.id} decline`}>
              <ModalOpenButton>
                <Button
                  key={`${approval.id} decline`}
                  variant="alternative"
                  size="small"
                  disabled={isLoadingStatus}
                >
                  {t('approvals.buttons.decline')}
                </Button>
              </ModalOpenButton>
              <ModalContentApprovalDecline
                approvalId={approval.id}
                title={declineModalTitle}
                textContent={declineModalDescription}
              />
            </Modal>
          ),
        },
        view: {
          match: ![
            DealState.DealPublishInProgress,
            DealState.MintInProgress,
          ].includes(approval.deal.state),
          component: (
            <Button
              key={`${approval.id} view`}
              variant="text"
              size="small"
              onClick={() => {
                setSelectedApprovalData(approval)
                toggleViewApprovalDetails()
              }}
              disabled={false}
            >
              {t('approvals.buttons.view')}
            </Button>
          ),
        },
      }

      return Object.values(actionButtons).map(({ match, component }) =>
        match ? component : null
      )
    },
    [isLoadingStatus]
  )

  const columnData = useMemo(
    () =>
      tableData.map((approval: Approval) =>
        mapServerData({
          approval,
          getRowActionButtons,
          t,
        })
      ),
    [isLoadingStatus, tableData, t]
  )

  const columns = useMemo(
    () => [
      { Header: 'Approval type', accessor: 'type' },
      { Header: 'Description / Name', accessor: 'description' },
      { Header: 'Creator', accessor: 'creator' },
      { Header: 'Status', accessor: 'status' },
      { Header: '', accessor: 'actions' },
    ],
    []
  )

  const renderList = () => {
    if (isSuccess) {
      return tableData.length ? (
        <ControlledPaginatedTable
          columns={columns}
          data={columnData}
          initialState={{ pageSize }}
          isLoading={isLoading}
          pageCount={data?.pageCount}
          fetchData={({ pageIndex }) => {
            setPage(pageIndex + 1)
          }}
        />
      ) : (
        <div className="h-3/4 flex justify-center items-center">
          <ApprovalsEmptyState
            title={t('approvals.emptyApprovalsDealCard.title')}
            description={t('approvals.emptyApprovalsDealCard.description')}
          />
        </div>
      )
    }
    return (
      <div className="h-3/4 flex justify-center items-center">
        <SmallSpinner />
      </div>
    )
  }

  return (
    <>
      <div className="w-2/4">
        <SearchInput
          autoFocus
          placeholder="Search approval"
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      {renderList()}
      <ViewApprovalOverlay />
    </>
  )
}

const mapServerData = ({
  approval,
  getRowActionButtons,
  t,
}: {
  approval: Approval
  getRowActionButtons: (approval: Approval) => (JSX.Element | null)[]
  t: (val: string) => string
}) => {
  const { generalInfo } = getDealTypeData(approval.deal)

  return {
    type: (
      <TextAvatar
        avatar={
          <div className="bg-blue-100 rounded-full p-2">
            {approval.type === ApprovalsTypeEnum.TokenMint ? (
              <Tokens fill={blue500} />
            ) : (
              <DollarReceiptOutlineIcon fill={blue500} />
            )}
          </div>
        }
        avatarShape="circular"
        secondaryContent={t(`approvals.deals.types.${approval.type}`)}
        size="large"
      />
    ),
    creator: (
      <div className="flex flex-col">
        {approval.createdBy ? (
          <>
            <p className="text-sm font-medium leading-normal text-gray-700">
              {approval.createdBy.firstName} {approval.createdBy.lastName}
            </p>
            <p className="text-sm font-medium leading-normal text-gray-500">
              {approval.createdBy.email}
            </p>
          </>
        ) : null}
      </div>
    ),
    description: (
      <div>
        <p className="text-sm font-medium leading-normal text-gray-700">
          {generalInfo.useOfProceeds}
        </p>
        <p className="text-sm font-medium leading-normal text-gray-500">
          {generalInfo.description}
        </p>
      </div>
    ),
    status: (
      <Badge color={mapBadgeToStatus(approval.status).color}>
        {t(mapBadgeToStatus(approval.status).label)}
      </Badge>
    ),
    actions: (
      <div className="w-full flex justify-end space-x-10">
        {getRowActionButtons(approval)}
      </div>
    ),
  }
}
