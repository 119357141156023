import { AlertBase } from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import { Card } from 'src/components'

export default function PendingApplicationCard() {
  return (
    <Card classes="bg-white border border-slate-200">
      <div className="flex flex-col justify-center items-left text-left m-auto w-96 align-middle">
        <h2 className="text-gray-900 text-lg font-bold my-4">
          {`${t('organizations.pendingOrganisation.title')}`}
        </h2>
        <p className="text-gray-500 text-sm font-normal mb-4">
          {`${t('organizations.pendingOrganisation.content')}`}
        </p>
        <AlertBase variant="warning" classes="text-amber-500">
          {`${t('organizations.pendingOrganisation.error')}`}
        </AlertBase>
      </div>
    </Card>
  )
}
