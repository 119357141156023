import {
  ErrorAlert,
  PanelCards,
  UnderlineTabs,
  UnderlineTabsProvider,
} from '@allinfra-ltd/allinfra-design-system'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PaperBandOffset,
  PaperContentAligner,
  PaperOverlay,
  SmallSpinner,
} from 'src/components'
import { useDealStatsQuery, useSingleDealQuery } from 'src/hooks'
import { getTokenPanelCards } from 'src/pages/Deals/ViewDeal/ActiveDeal/utils'
import { getDealStatsData, getDealTypeData } from 'src/pages/Deals/utils'
import { ApprovalsBaseOverlayProps, DealState } from 'src/types'

import { useApprovalActions } from '../../context'
import TabsContent from './TabsContent'
import { approvedDealTabs } from './utils'

function ApprovedDeal({ selectedApprovalData }: ApprovalsBaseOverlayProps) {
  const { t } = useTranslation()
  const { isViewingApprovalDetails, toggleViewApprovalDetails } =
    useApprovalActions()
  const { generalInfo } = getDealTypeData(selectedApprovalData.deal)
  const { data: dealData, isError: isErrorLoadingDealData } =
    useSingleDealQuery({
      id: selectedApprovalData.deal.id,
      dealStage: selectedApprovalData.deal.stage,
    })
  const { data: dealStats } = selectedApprovalData.deal.id
    ? useDealStatsQuery({
        id: selectedApprovalData.deal.id,
      })
    : { data: getDealStatsData() }

  const tokenCards = useMemo(
    () => getTokenPanelCards(getDealStatsData(dealStats), dealData),
    [dealData]
  )

  return (
    <PaperOverlay
      isOpen={isViewingApprovalDetails}
      onClose={toggleViewApprovalDetails}
      title={generalInfo.useOfProceeds}
      description={generalInfo.description}
    >
      <PaperContentAligner>
        {dealData && tokenCards.length > 0 ? (
          <PanelCards
            className={
              [DealState.MintApproved].includes(dealData.state)
                ? 'border md:grid-cols-1 gap-px bg-gray-200'
                : undefined
            }
            cards={tokenCards}
          />
        ) : null}
        <UnderlineTabsProvider tabs={approvedDealTabs}>
          <UnderlineTabs />
          {dealData ? (
            <TabsContent deal={dealData} />
          ) : (
            <div className="p-4">
              <SmallSpinner />
            </div>
          )}
          {isErrorLoadingDealData ? (
            <div className="py-4">
              <ErrorAlert>{t('approvals.errors.dealDetails')}</ErrorAlert>
            </div>
          ) : null}
        </UnderlineTabsProvider>
      </PaperContentAligner>
      <PaperBandOffset />
    </PaperOverlay>
  )
}

export default ApprovedDeal
