import { apiUrl } from 'src/api'
import {
  CreateDealMainSale,
  CreateDealPreDeal,
  Deal,
  DealCouponPeriod,
  DealCurrency,
  DealRanking,
  DealStats,
  Deals,
  RequestDealMintRequestBody,
  RequestMintTokenBody,
} from 'src/types'
import { RecursivePartial } from 'src/types/utils'

import { api } from '../axios-base-instance'

export const getDealsCurrencies = () =>
  api.get<DealCurrency[]>(apiUrl.dealsCurrencies)

export const getDealsRanking = () => api.get<DealRanking[]>(apiUrl.dealsRanking)

export const getDealsCouponPeriod = () =>
  api.get<DealCouponPeriod[]>(apiUrl.dealsCouponPeriod)

export const postCreateDealMainSale = async (
  body: RecursivePartial<CreateDealMainSale>
) => {
  const { data } = await api.post<RecursivePartial<CreateDealMainSale>>(
    apiUrl.dealsMainSale,
    body
  )
  return data
}

export const putUpdateDealMainSale = async (
  id: number,
  body: RecursivePartial<CreateDealMainSale>
) => {
  const { data } = await api.put(apiUrl.dealMainState(id), body)
  return data
}

export const postCreatePreDealSolicitation = async (
  body: RecursivePartial<CreateDealPreDeal>
) => {
  const { data } = await api.post<RecursivePartial<CreateDealPreDeal>>(
    apiUrl.dealsPreDealSolicitation,
    body
  )
  return data
}

export const getDealsQuery = async (queryString: string) => {
  const { data } = await api.get<Deals>(apiUrl.dealsQuery(queryString))
  return data
}

export const mintDealToken = async (id: number) => {
  const { data } = await api.post<RecursivePartial<Deal>>(apiUrl.dealMint(id))
  return data
}

export const postRequestMintToken = async ({
  id,
  body,
}: {
  id: number
  body: RequestMintTokenBody
}) => {
  const { data } = await api.post<RecursivePartial<Deal>>(
    apiUrl.dealRequestMint(id),
    body
  )

  return data
}

export const putRequestMintToken = async ({
  id,
  body,
}: {
  id: number
  body: RequestDealMintRequestBody
}) => {
  const { data } = await api.put(apiUrl.dealRequestMint(id), body)
  return data
}

export const getSingleDealQuery = async ({
  id,
  queryString,
}: {
  id: number
  queryString: string
}) => {
  const { data } = await api.get<Deal>(
    apiUrl.singleDealQuery({ id, queryString })
  )
  return data
}

export const getDealStats = async ({ id }: { id: number }) => {
  const { data } = await api.get<DealStats>(apiUrl.dealStats(id))
  return data
}

export const getDealTransactions = async ({
  dealId,
  queryString,
}: {
  dealId: number
  queryString: string
}) => {
  const { data } = await api.get(apiUrl.dealTransactions(dealId, queryString))
  return data
}

export const postDealTokensTransfer = async ({
  dealId,
  body,
}: {
  dealId: number
  body: { investors: number[] }
}) => {
  const { data } = await api.post(apiUrl.dealTokensTransfer(dealId), body)
  return data
}

export const postDealTokensForceTransfer = async ({
  dealId,
  body,
}: {
  dealId: number
  body: {
    forceTransfers: { investorId: number; amount: number; reason: string }[]
  }
}) => {
  const { data } = await api.post(apiUrl.dealTokensForceTransfer(dealId), body)

  return data
}

export const postDealTokensTransferAdditional = async ({
  dealId,
  body,
}: {
  dealId: number
  body: { additionalTransfers: { investorId: number; amount: number }[] }
}) => {
  const { data } = await api.post(
    apiUrl.dealTokensTransferAdditional(dealId),
    body
  )

  return data
}

export const postDealTokensBurn = async ({
  dealId,
  body,
}: {
  dealId: number
  body: { investors: { userId: number; amount: number }[] }
}) => {
  const { data } = await api.post(apiUrl.dealTokensBurn(dealId), body)
  return data
}

export const postDealTokenReissue = async ({
  dealId,
  body,
}: {
  dealId: number
  body: {
    investorId: number
    burn: boolean
    amount: number
  }
}) => {
  const { data } = await api.post(apiUrl.dealTokenReissue(dealId), body)
  return data
}
