import { useState } from 'react'
import { NewOrgPayload } from 'src/api/routes'
import { useEditOrganisation } from 'src/hooks'
import useCreateNewOrgMutation from 'src/hooks/Invitation/useCreateNewOrganization'
import CreateOrganisationModal from 'src/pages/Organisation/CreateOrganisationModal'
import { Organization } from 'src/types'

import CreateOrganisationCard from './CreateOrganisationCard'
import DeclinedApplicationCard from './DeclinedApplicationCard'
import PendingApplicationCard from './PendingApplicationCard'
import { getOrganizationStatuses } from './utils'

export interface OrgEmptyStateProps {
  organization?: Organization
}

function OrgEmptyState({ organization }: OrgEmptyStateProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {
    error: editOrganizationError,
    isLoading: isPendingEditOrganization,
    mutate: editOrganizationMutation,
  } = useEditOrganisation()
  const modalOpen = () => {
    setIsModalOpen(true)
  }

  const {
    mutate: mutateSubmitNewOrg,
    isLoading: isSubmittingNewOrg,
    error: errorMessage,
    isSuccess,
  } = useCreateNewOrgMutation()

  if (!organization) {
    const createNewOrganization = ({ name, address, website }: any) => {
      let payload = {}
      if (!website.length) {
        payload = { name: name.trim(), address: address.trim() }
      } else {
        payload = {
          name: name.trim(),
          address: address.trim(),
          website: website.trim(),
        }
      }
      mutateSubmitNewOrg(payload as NewOrgPayload, {
        onSuccess: () => {
          setIsModalOpen(false)
        },
      })
    }

    return (
      <div>
        <CreateOrganisationCard onButtonClick={modalOpen} />
        <CreateOrganisationModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onSubmit={createNewOrganization}
          isSubmitting={isSubmittingNewOrg}
          errorMessage={errorMessage?.response?.data?.message}
        />
      </div>
    )
  }

  const { isOrganizationPending, isOrganizationDeclined } =
    getOrganizationStatuses(organization)

  const renderCard = () => {
    if (isOrganizationPending || isSuccess) return <PendingApplicationCard />

    if (isOrganizationDeclined)
      return <DeclinedApplicationCard onButtonClick={modalOpen} />

    return null
  }

  const editOrganization = ({ website, ...formValues }: NewOrgPayload) => {
    editOrganizationMutation(
      {
        id: organization.id,
        ...(website ? { website, ...formValues } : formValues),
      },
      {
        onSuccess: () => {
          setIsModalOpen(false)
        },
      }
    )
  }

  return (
    <div>
      {renderCard()}
      <CreateOrganisationModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onSubmit={editOrganization}
        isSubmitting={isPendingEditOrganization}
        errorMessage={editOrganizationError?.response?.data?.message?.[0]}
      />
    </div>
  )
}

export default OrgEmptyState
