import { DatePicker } from '@allinfra-ltd/allinfra-design-system'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { isDateIsLaterThanToday } from '../../../CreateDeal/utils'
import { formatDate } from '../data-formatter'

const AnticipatedIssuanceDate = ({
  value,
  id,
}: {
  value: string
  id: string
}) => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext()
  const { t } = useTranslation()
  return (
    <Controller
      name={id}
      control={control}
      rules={{
        required: t(
          'deals.create.generalInfo.datePicker.requiredAnticipated'
        ) as string,
        validate: (value) =>
          isDateIsLaterThanToday(value || new Date()) ||
          t('deals.create.generalInfo.datePicker.dateValidation'),
      }}
      render={({ field: { onChange, ref }, fieldState: { error } }) => (
        <DatePicker
          ref={ref}
          calendarPlacement="left"
          initialInputValue={formatDate(getValues(id) || value).toString()}
          inputProps={{
            placeholder: t(
              'deals.create.generalInfo.anticipatedIssuanceDatePlaceholder'
            ),
            error: !!error,
            inputInfo: `${errors[id]?.message || ''}`,
          }}
          onSelectDateChange={onChange}
          tooltipProps={{
            className:
              'before:bg-white bg-white p-0 shadow-xl hover:shadow-2xl hover:shadow-blue-500/10',
            wrapperClasses: 'w-full z-10 px-0',
          }}
        />
      )}
    />
  )
}

export default AnticipatedIssuanceDate
