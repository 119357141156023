import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { getDealTransactions } from 'src/api/routes'
import { defaultTablePageSize } from 'src/utils'

export function useDealTransactions({
  dealId,
  pageSize = defaultTablePageSize,
}: {
  dealId: number
  pageSize?: number
}) {
  const [page, setPage] = useState(1)
  const qb = RequestQueryBuilder.create()
  const queryString = qb
    .setPage(page)
    .setLimit(pageSize)
    .setJoin([
      {
        field: 'recipient',
      },
      {
        field: 'sender',
      },
      {
        field: 'signer',
      },
      {
        field: 'recipientWallet',
      },
      {
        field: 'senderWallet',
      },
      {
        field: 'signerWallet',
      },
    ])
    .query()

  return {
    setPage,
    ...useQuery(
      [queryKeys.dealsTransactions, queryString],
      () => getDealTransactions({ dealId, queryString }),
      {
        keepPreviousData: true,
      }
    ),
  }
}
