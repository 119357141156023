import { RouteObject } from 'react-router-dom'
import { getPrivateRoutes } from 'src/routes/private-routes'
import { getPublicRoutes } from 'src/routes/public-routes'

import { subPaths } from './subpath-schema-tree'

export { subPaths, subPathSchema } from './subpath-schema-tree'
export const routesConfig: RouteObject[] = [
  ...getPublicRoutes(subPaths),
  ...getPrivateRoutes(subPaths),
]
