import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { postRequestMintToken } from 'src/api/routes'
import { RequestMintTokenBody } from 'src/types'

const requestMintToken = ({
  id,
  body,
}: {
  id: number
  body: RequestMintTokenBody
}) => postRequestMintToken({ id, body })

export const useRequestMintDeal = () => {
  const queryClient = useQueryClient()

  return useMutation(requestMintToken, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.dealsList)
    },
  })
}
