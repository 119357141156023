import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import { Deal, DealActionsContextValue, DealActionsState } from 'src/types'

const DealActionsContext = createContext<DealActionsContextValue | null>(null)

const dealActionsReducer = (
  state: DealActionsState,
  payload: Partial<DealActionsState>
) => ({
  ...state,
  ...payload,
})

const initialState = {
  isDealMinting: false,
  isViewingDealDetails: false,
}

export const DealActionsProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(dealActionsReducer, initialState)

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])

  return (
    <DealActionsContext.Provider value={value}>
      {children}
    </DealActionsContext.Provider>
  )
}

export const useDealActions = () => {
  const context = useContext(DealActionsContext)

  if (!context) {
    throw new Error('useDealActions must be used within a DealActionsProvider')
  }

  const { state, dispatch } = context

  const toggleDealMinting = useCallback(
    () => dispatch({ isDealMinting: !state.isDealMinting }),
    [state, dispatch]
  )

  const toggleViewDealDetails = useCallback(
    () => dispatch({ isViewingDealDetails: !state.isViewingDealDetails }),
    [state, dispatch]
  )

  const setSelectedDealData = useCallback(
    (selectedDealData: Deal) => dispatch({ selectedDealData }),
    [dispatch]
  )

  const reset = useCallback(() => dispatch(initialState), [dispatch])

  return {
    state,
    isDealMinting: state.isDealMinting,
    isViewingDealDetails: state.isViewingDealDetails,
    toggleDealMinting,
    toggleViewDealDetails,
    setSelectedDealData,
    reset,
  }
}
