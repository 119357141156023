import { useUnderlineTabs } from '@allinfra-ltd/allinfra-design-system'
import { DealDetailsPreview } from 'src/pages/Deals/DealDetailsPreview'
import { InvestorListPreview } from 'src/pages/Deals/InvestorListPreview'
import { Deal } from 'src/types'

function TabsContent({ deal }: { deal: Deal }) {
  const { firstActiveTabIndex: activeTabIndex } = useUnderlineTabs()

  const tabs = {
    dealDetails: {
      match: activeTabIndex === 0,
      component: (
        <DealDetailsPreview key="dealDetails" selectedDealData={deal} />
      ),
    },
    investors: {
      match: activeTabIndex === 1,
      component: <InvestorListPreview key="investors" deal={deal} />,
    },
  }

  return (
    <>{Object.values(tabs).map((tab) => (tab.match ? tab.component : null))}</>
  )
}

export default TabsContent
