import { Organization, OrganizationStatus } from 'src/types'

export function getOrganizationStatuses(organization: Organization) {
  const organizationStatus = organization.status

  return {
    isOrganizationActive: organizationStatus === OrganizationStatus.Active,
    isOrganizationPending: organizationStatus === OrganizationStatus.Pending,
    isOrganizationDeclined: organizationStatus === OrganizationStatus.Declined,
  }
}
