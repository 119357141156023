import {
  Button,
  ControlledPaginatedTable,
  ControlledTooltip,
  TextAvatar,
  UserFlipCard,
} from '@allinfra-ltd/allinfra-design-system'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { EmptyStateCard, SmallSpinner } from 'src/components'
import { useOrganisationMembersPage } from 'src/hooks'
import { UsePermissionsReturn, usePermissions } from 'src/hooks/auth'
import { Organization, User } from 'src/types'
import { defaultTablePageSize } from 'src/utils'

import { getRoleName } from '../Users/utils'
import RemoveUserModal from './RemoveUserModal'

function ActiveOrgUsersList() {
  const { orgData } = useOutletContext<{ orgData: Organization }>()
  const [selectedUser, setSelectedUser] = useState<User>()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const userPermissions = usePermissions()

  const { data, isLoading, setPage, isSuccess } = useOrganisationMembersPage({
    id: orgData.id,
  })
  const flattenData = ({ data } = { data: [] }) => data
  const tableData = flattenData(data)
  const columnData = useMemo(
    () =>
      tableData.map((user: User) =>
        mapServerData({
          user,
          setSelectedUser,
          setIsOpenModal,
          userPermissions,
        })
      ),
    [tableData]
  )

  const columns = useMemo(() => {
    return [
      {
        Header: t('organizations.activeUsersTable.header.name'),
        accessor: 'name',
      },
      {
        Header: t('organizations.activeUsersTable.header.role'),
        accessor: 'role',
      },
      {
        Header: (
          <span className="ml-2">{`${t(
            'users.activeUsersTable.header.date'
          )}`}</span>
        ),
        accessor: 'creationDate',
      },
      ...(userPermissions.isOrganizationEditEnabled
        ? [
            {
              Header: (
                <span className="mx-auto">
                  {`${t('organizations.activeUsersTable.header.actions')}`}
                </span>
              ),
              accessor: 'actions',
            },
          ]
        : []),
    ]
  }, [userPermissions.isOrganizationEditEnabled])

  if (isLoading) return <SmallSpinner />
  if (isSuccess) {
    return tableData.length ? (
      <>
        <ControlledPaginatedTable
          columns={columns}
          data={columnData}
          initialState={{ pageSize: defaultTablePageSize }}
          fetchData={({ pageIndex }) => {
            setPage(pageIndex + 1)
          }}
          isLoading={isLoading}
          pageCount={data?.pageCount}
        />
        {selectedUser ? (
          <RemoveUserModal
            orgId={orgData.id}
            selectedUser={selectedUser}
            isOpen={isOpenModal}
            setIsOpen={setIsOpenModal}
          />
        ) : null}
      </>
    ) : (
      <div className="flex justify-center items-center">
        <EmptyStateCard
          svgImage={<UserFlipCard />}
          title={t('users.activeUsersEmptyCard.title')}
          text={t('users.activeUsersEmptyCard.content')}
        />
      </div>
    )
  }
  return <SmallSpinner />
}

function mapServerData({
  user,
  setSelectedUser,
  setIsOpenModal,
  userPermissions,
}: {
  user: User
  setSelectedUser: (user: User) => void
  setIsOpenModal: (isOpen: boolean) => void
  userPermissions: UsePermissionsReturn
}) {
  const { isOrganizationEditEnabled, isRemoveOrgUserEnabled } = userPermissions
  const isRemoveDisabled = !isRemoveOrgUserEnabled(user)

  return {
    name: (
      <TextAvatar
        primaryContent={`${user.firstName} ${user.lastName}`}
        secondaryContent={`${user.email}`}
      />
    ),
    role: getRoleName(user.role),
    creationDate: (
      <TextAvatar
        avatar={<div />}
        primaryContent={dayjs(user.createdAt).format('MMMM D, YYYY')}
        secondaryContent={dayjs(user.createdAt).format('h:mm A')}
      />
    ),
    actions: isOrganizationEditEnabled ? (
      <div className="space-x-2 flex justify-center w-full">
        <ControlledTooltip
          content={
            isRemoveDisabled ? (
              <p>
                <Trans
                  i18nKey="organizations.activeUsersTable.actions.removeOrganisationTooltip"
                  components={[<br />]}
                />
              </p>
            ) : null
          }
          placement="left"
        >
          <div>
            <Button
              variant="text"
              size="small"
              disabled={isRemoveDisabled}
              onClick={() => {
                setSelectedUser(user)
                setIsOpenModal(true)
              }}
            >
              {t('organizations.activeUsersTable.actions.remove')}
            </Button>
          </div>
        </ControlledTooltip>
      </div>
    ) : null,
  }
}

export default ActiveOrgUsersList
