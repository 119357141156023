import {
  Button,
  CloseIcon,
  ErrorAlert,
} from '@allinfra-ltd/allinfra-design-system'
import React, { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Role } from 'src/api/routes'
import { EmailInput } from 'src/components/Input'
import { SmallSpinner } from 'src/components/Loaders'
import { Select } from 'src/components/Select'
import { InviteFormFields } from 'src/types/invite'

interface InviteFormProps {
  roles?: Role[]
  showRoles?: boolean
  onClose: () => void
  submitError: string
  fetchError: string
  isFetching: boolean
  isSubmitting: boolean
  onSubmit: (values: InviteFormFields) => void
}

function InviteForm({
  onClose,
  roles,
  showRoles = true,
  submitError,
  fetchError,
  isFetching,
  isSubmitting,
  onSubmit,
}: InviteFormProps) {
  const { t } = useTranslation()
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<InviteFormFields>({
    mode: 'onTouched',
  })

  const getRoleOptions = useCallback(() => {
    if (!roles) return []

    return roles?.map((role) => {
      return {
        primaryLabel: role.name,
      }
    })
  }, [roles])

  const renderError = (errorMsg: string) =>
    errorMsg ? <ErrorAlert>{errorMsg}</ErrorAlert> : null

  return (
    <>
      <div className="px-4 pt-3 pb-4">
        {isFetching ? (
          <div className="flex justify-center my-4">
            <SmallSpinner />
          </div>
        ) : (
          <>
            <div className="ml-auto flex w-full flex-row justify-between items-center">
              <h4 className="text-gray-900 text-lg font-bold">
                {t('invite.form.title')}
              </h4>
              <div className="h-10 w-10 cursor-pointer rounded-full  p-2 hover:shadow-sm active:shadow-inner">
                <CloseIcon onClick={onClose} />
              </div>
            </div>
            <p className="mt-4 text-gray-500 text-sm font-normal">
              {t('invite.form.description')}
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 my-4">
              <EmailInput
                register={register}
                error={!!errors.email}
                inputInfo={errors.email && errors.email.message}
              />
              {showRoles ? (
                <div className="my-4">
                  <Controller
                    control={control}
                    name="roleIndex"
                    rules={{
                      required: t('invite.form.role.required') as string,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Select
                        label="Role"
                        options={getRoleOptions()}
                        selectedIndex={value}
                        onChange={onChange}
                        placeholderLabel={t('invite.form.role.placeholder')}
                        error={error?.message}
                      />
                    )}
                  />
                </div>
              ) : null}
              {renderError(submitError)}
              <Button classes="w-full" type="submit" disabled={isSubmitting}>
                {isSubmitting ? <SmallSpinner /> : t('invite.form.submit')}
              </Button>
            </form>
          </>
        )}
      </div>
      {renderError(fetchError)}
    </>
  )
}

export default React.memo(InviteForm)
