import {
  CheckboxInput,
  CheckboxInputProps,
} from '@allinfra-ltd/allinfra-design-system'

interface CheckboxMessageCardProps extends Partial<CheckboxInputProps> {
  message?: string
}

function CheckboxMessageCard({
  children,
  message,
  ...props
}: CheckboxMessageCardProps) {
  return (
    <div className="border border-gray-300 rounded-md">
      <div className="m-6">
        <CheckboxInput {...props} />
      </div>
      {message ? (
        <p className="text-gray-500 text-sm font-normal bg-gray-50 p-6 border-t">
          {message}
        </p>
      ) : null}
    </div>
  )
}

export default CheckboxMessageCard
