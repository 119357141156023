import {
  Button,
  CheckMarkIcon,
  CloseIcon,
} from '@allinfra-ltd/allinfra-design-system'
import { t } from 'i18next'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { CellProps, ColumnWithLooseAccessor } from 'react-table'
import {
  DealDetailDataMapOriginalValue,
  DealDetailsPreviewHeader,
} from 'src/types'

import { useDealDetailsPreview } from '../context/deal-details-preview-context'
import { formatCellData } from './data-formatter'
import editableColumns from './editable-columns'

const wrapThContent = (key: string) => (
  <div className="text-left p-4 text-gray-900 font-medium">{key}</div>
)

const getEditableColumns = (headerName: DealDetailsPreviewHeader) => {
  return [
    {
      Header: '',
      accessor: 'value',
      Cell: ({ value, row }: CellProps<{}, string>) => {
        const { id: dataKey } = row.original as DealDetailDataMapOriginalValue
        const { isCurrentRowEditing } = useDealDetailsPreview()
        const { getValues } = useFormContext()

        const isRowAllowedToEdit = editableColumns.find(
          (column) => column?.id === dataKey
        )
        if (isRowAllowedToEdit && isCurrentRowEditing(row.id, headerName)) {
          return (
            <div className="w-full">
              {editableColumns
                .find((column) => column?.id === dataKey)
                ?.render({ value })}
            </div>
          )
        }

        return (
          <div>
            {`${formatCellData(
              getValues(dataKey) !== undefined ? getValues(dataKey) : value,
              dataKey
            )}`}
          </div>
        )
      },
    },
    {
      Header: '',
      accessor: 'button',
      Cell: ({ row }: CellProps<{}>) => {
        const { id: dataKey, value: dataValue } =
          row.original as DealDetailDataMapOriginalValue
        const { setEditingRow, isCurrentRowEditing } = useDealDetailsPreview()
        const {
          setValue,
          getValues,
          formState: { errors },
        } = useFormContext()
        const [initialValue, setInitialValue] = useState(dataValue)

        const isRowAllowedToEdit = editableColumns.find(
          (column) => column?.id === dataKey
        )

        const onUpdate = () => {
          setEditingRow({ rowId: row.id, headerName })
        }
        if (isRowAllowedToEdit)
          return isCurrentRowEditing(row.id, headerName) ? (
            <div className="flex flex-row">
              <Button
                type="button"
                size="small"
                variant="text"
                onClick={() => {
                  setValue(dataKey, initialValue, {
                    shouldDirty: initialValue !== dataValue,
                  })
                  onUpdate()
                }}
                customSizeClasses="px-3"
              >
                <CloseIcon fill="" className="fill-red-500" />
              </Button>
              <Button
                type="button"
                size="small"
                variant="text"
                onClick={() => {
                  if (!errors[dataKey]) onUpdate()
                }}
                customSizeClasses="px-3"
              >
                <CheckMarkIcon fill="" className="fill-blue-400" />
              </Button>
            </div>
          ) : (
            <Button
              type="button"
              size="small"
              variant="text"
              onClick={() => {
                setInitialValue(getValues(dataKey))
                onUpdate()
              }}
            >
              {t('deals.update.updateButtonText')}
            </Button>
          )

        return null
      },
    },
  ]
}

export const getGridColumns = (
  columnHeader: DealDetailsPreviewHeader,
  isEditable: boolean = false
) => {
  const arr: ColumnWithLooseAccessor[] = [
    {
      Header: wrapThContent(t(`deals.create.${columnHeader}.title`)),
      accessor: 'key',
    },
  ]

  if (isEditable) {
    getEditableColumns(columnHeader).map((item) => arr.push(item))
  } else {
    arr.push({
      Header: '',
      accessor: 'value',
      Cell: ({ value, row }: CellProps<{}, string>) => {
        const { id: dataKey } = row.original as DealDetailDataMapOriginalValue
        return <div>{formatCellData(value, dataKey)}</div>
      },
    })
  }

  return arr
}
