import { SideBar } from '@allinfra-ltd/allinfra-design-system'
import { Suspense, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { SmallSpinner } from 'src/components'
import { UserDropdownAvatar } from 'src/components/UserDropdownAvatar'
import { useUser } from 'src/hooks/auth'
import { DashboardFooter } from 'src/pages/DashboardLayout/DashboardFooter'

import { getNavLinksByUserRole } from './utils'

export default function DashboardLayout() {
  const { isSuccess, data } = useUser()
  const navLinks = useMemo(
    () => (data ? getNavLinksByUserRole(data.role.claims) : []),
    [data]
  )

  return isSuccess ? (
    <div className="flex h-screen flex-row">
      <SideBar navItemsConfig={navLinks} />
      <div className="w-full bg-gray-50 h-screen flex flex-col justify-between">
        <main className="flex-1 overflow-scroll">
          <div className="absolute right-12 top-10 z-10">
            <UserDropdownAvatar />
          </div>
          <div className="pt-10 px-12 h-full m-h-full">
            <Suspense fallback={<SmallSpinner />}>
              <Outlet />
            </Suspense>
          </div>
        </main>
        <DashboardFooter />
      </div>
    </div>
  ) : null
}
