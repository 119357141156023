import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { NewOrgPayload, createSingleOrg } from 'src/api/routes'
import { Organization } from 'src/types'

const createNewOrganization = async (payload: NewOrgPayload) => {
  const { data } = await createSingleOrg(payload)
  return data
}

export default function useCreateNewOrgMutation() {
  const queryClient = useQueryClient()
  return useMutation<Organization, AxiosError<any>, NewOrgPayload, unknown>(
    createNewOrganization,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.organizations)
        queryClient.invalidateQueries([queryKeys.myOrganization])
      },
    }
  )
}
