import { Trans, useTranslation } from 'react-i18next'

import { Heading, TranslationOffsetUnorderedList } from './utils'

export interface DisclaimerContentProps {
  companyName: string
}

function DisclaimerContent({ companyName }: DisclaimerContentProps) {
  const { t } = useTranslation()

  return (
    <>
      <Heading>{t('disclaimer.importantNotice.title')}</Heading>
      <Trans
        i18nKey="disclaimer.importantNotice.paragraph1"
        values={{ companyName }}
      />
      <p className="my-6 uppercase">
        <Trans
          i18nKey="disclaimer.importantNotice.paragraph2"
          values={{ companyName }}
        />
      </p>
      <p className="my-6 uppercase">
        <Trans
          i18nKey="disclaimer.importantNotice.paragraph3"
          values={{ companyName }}
        />
      </p>
      <TranslationOffsetUnorderedList translationKey="disclaimer.importantNotice.paragraph3List" />
      <p className="my-6">
        <Trans
          i18nKey="disclaimer.importantNotice.paragraph4"
          values={{ companyName }}
        />
      </p>
      <Heading>{t('disclaimer.noOffering.title')}</Heading>
      <p className="my-6">
        <Trans
          i18nKey="disclaimer.noOffering.paragraph1"
          values={{ companyName }}
        />
      </p>
      <p className="my-6">
        <Trans
          i18nKey="disclaimer.noOffering.paragraph2"
          values={{ companyName }}
        />
      </p>
      <p className="my-6">
        <Trans
          i18nKey="disclaimer.noOffering.paragraph3"
          values={{ companyName }}
        />
      </p>
      <p className="my-6">
        <Trans
          i18nKey="disclaimer.noOffering.paragraph4"
          values={{ companyName }}
        />
      </p>
      <Heading>{t('disclaimer.informationDisclaimer.title')}</Heading>
      <p className="my-6">
        <Trans
          i18nKey="disclaimer.informationDisclaimer.paragraph1"
          values={{ companyName }}
        />
      </p>
      <Heading>{t('disclaimer.impliedAgreement.title')}</Heading>
    </>
  )
}

export default DisclaimerContent
