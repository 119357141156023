import { ModalBase, ModalBaseProps } from '@allinfra-ltd/allinfra-design-system'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import InviteForm from 'src/components/Modal/InviteModal/InviteForm'
import InviteSuccess from 'src/components/Modal/InviteModal/InviteSuccess'
import { RoleEnum } from 'src/enum/role'
import { useOrgInvitationMutation, useRoles } from 'src/hooks/Invitation'
import { InviteFormFields } from 'src/types/invite'

interface OrganizationInviteModalProps extends ModalBaseProps {
  orgId: number
}

function OrganizationInviteModal({
  isOpen,
  setIsOpen,
  orgId,
}: OrganizationInviteModalProps) {
  const navigate = useNavigate()

  const {
    mutate: submitMutate,
    error: submitError,
    isLoading: isSubmitLoading,
  } = useOrgInvitationMutation(orgId)

  const {
    data: rolesData,
    error: rolesError,
    isLoading: isRolesLoading,
  } = useRoles()

  const [invitedEmail, setInvitedEmail] = React.useState<string | null>(null)

  const roles = rolesData?.filter(
    (r) => r.id === RoleEnum.Issuer || r.id === RoleEnum.Regulator
  )

  const onGotoPendingUser = () => {
    navigate('pending')
    setIsOpen(false)
  }

  const onSubmit = ({ email }: InviteFormFields) => {
    if (!roles) return

    submitMutate(
      {
        email,
      },
      {
        onSuccess: () => {
          setInvitedEmail(email)
        },
      }
    )
  }

  return (
    <ModalBase {...{ isOpen, setIsOpen }}>
      {invitedEmail ? (
        <InviteSuccess
          onClose={() => setIsOpen(false)}
          email={invitedEmail}
          onActionBtnClick={onGotoPendingUser}
        />
      ) : (
        <InviteForm
          roles={roles}
          showRoles={false}
          onClose={() => setIsOpen(false)}
          submitError={submitError?.response?.data?.message}
          fetchError={rolesError?.response?.data?.message}
          onSubmit={onSubmit}
          isFetching={isRolesLoading}
          isSubmitting={isSubmitLoading}
        />
      )}
    </ModalBase>
  )
}

export default React.memo(OrganizationInviteModal)
