import { ErrorAlert } from '@allinfra-ltd/allinfra-design-system'
import { Transition } from '@headlessui/react'

type FadeInErrorAlertProps = {
  children: React.ReactNode
  isVisible: boolean
}

function FadeInErrorAlert({ children, isVisible }: FadeInErrorAlertProps) {
  return (
    <Transition
      show={isVisible}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <ErrorAlert>{children}</ErrorAlert>
    </Transition>
  )
}

export default FadeInErrorAlert
