export const auth = {
  login: {
    rightContent: {
      title: 'Allinfra Digital',
      titleLineBreak: 'Management Platform',
    },
    form: {
      title: 'Sign in to your account',
      emailCornerOption: 'Forgot password?',
      error: {
        incorrectCredentials:
          'Incorrect email or password. Please double check your credentials and try again.',
      },
      submitButton: 'Sign in',
    },
  },
  twoFactorAuthModal: {
    title: 'Two-factor authentication',
    description: 'Enter the 6 digit code from your authenticator app',
    authCodeInput: {
      error: 'Invalid code. Please try again',
    },
    backupCode: 'I have a backup code',
    button: 'Authorize',
  },
  forgotPassword: {
    form: {
      title: 'Forgot your password?',
      error: {
        notFound: 'This email was not found on our servers.',
        notFoundLineBreak: 'Please doublecheck your email and try again.',
      },
      submitButton: 'Recover password',
      backNavigation: 'Back to Sign In',
    },
  },
  createNewPassword: {
    stepOne: {
      title: 'Create new password',
      submitButton: 'Change password',
      passwordTooltip: {
        numberUpper: 'Contains at least a number and an uppercase',
        symbol: 'Contains at least a symbol',
        minLength: 'Contains at least 8 characters',
      },
    },
    success: {
      title: 'Password changed!',
      message:
        'You have successfully changed your password. You can now sign in using your new password.',
      submitButton: 'Go to Sign In',
    },
  },
  signUp: {
    error: {
      missingToken: 'Missing token',
    },
    header: { step: 'Step' },
    stepOne: {
      title: 'Create new account',
      form: {
        firstName: {
          placeholder: 'First Name',
          label: 'First Name',
          required: 'Please enter your first name',
          validate: 'First name cannot be empty',
        },
        lastName: {
          placeholder: 'Last Name',
          label: 'Last Name',
          required: 'Please enter your last name',
          validate: 'Last name cannot be empty',
        },
        passwordTooltip: {
          numberUpper: 'Contains at least a number and an uppercase',
          symbol: 'Contains at least a symbol',
          minLength: 'Contains at least 8 characters',
        },
        submitButton: 'Continue',
        bottomSection: {
          text: 'You already have an account?',
          link: 'Sign in',
        },
      },
    },
    stepTwo: {
      title: 'Two Factor authentication',
      form: {
        qrCard: {
          title: `Scan the QR code on the left with your authenticator app (such as
            Google Authenticator or Authy). If that does not work, manually
            enter the key below in the app.`,
          button: 'Copy',
        },
        codeInput: {
          label: ' Input the 6 digit code generated by the authenticator',
          required: 'Please enter the code',
          submitButton: 'Continue',
          backButton: 'Back',
          lengthErrorMessage: 'The code must be 6 digits',
        },
      },
    },
    stepThree: {
      title: 'Backup verification codes',
      instructions: `If you loose access to your authenticator app, you can restore access
        with any of the following one-time use codes. Please save these codes in
        a safe place.`,
      downloadButton: 'Download',
      printButton: 'Print',
      submitButton: 'Finish Registration',
      checkBoxInputLabel: 'I have stored the backup codes in a safe place',
    },
  },
}
