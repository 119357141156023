import { CreateDealFlatDataKeys as DataKeys } from 'src/types'

import AnticipatedIssuanceDate from './AnticipatedIssuanceDate'
import BaseIssueSize from './BaseIssueSize'
import Coupon from './Coupon'
import CouponPeriod from './CouponPeriod'
import Currencies from './Currencies'
import IncrementAmountSize from './IncrementAmountSize'
import MaxIssueSize from './MaxIssueSize'
import MinInvestSize from './MinInvestSize'
import NegativePledge from './NegativePledge'
import Ranking from './Ranking'
import Tenor from './Tenor'
import TickerSymbol from './TickerSymbols'

interface ColumnProps {
  id: DataKeys
  render: (props: { value: string }) => JSX.Element | null
}

export default [
  DataKeys.Currency,
  DataKeys.TickerSymbol,
  DataKeys.AnticipatedIssuanceDate,
  DataKeys.Tenor,
  DataKeys.Ranking,
  DataKeys.NegativePledge,
  DataKeys.Coupon,
  DataKeys.CouponPeriod,
  DataKeys.IncrementAmountSize,
  DataKeys.BaseIssueSize,
  DataKeys.MaxIssueSize,
  DataKeys.MinInvestSize,
].map((key) => {
  switch (key) {
    case DataKeys.Currency:
      return {
        id: key,
        render: ({ value }) => {
          return <Currencies value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.AnticipatedIssuanceDate:
      return {
        id: key,
        render: ({ value }) => {
          return <AnticipatedIssuanceDate value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.TickerSymbol:
      return {
        id: key,
        render: ({ value }) => {
          return <TickerSymbol value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.Tenor:
      return {
        id: key,
        render: ({ value }) => {
          return <Tenor value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.Ranking:
      return {
        id: key,
        render: ({ value }) => {
          return <Ranking value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.Coupon:
      return {
        id: key,
        render: ({ value }) => {
          return <Coupon value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.CouponPeriod:
      return {
        id: key,
        render: ({ value }) => {
          return <CouponPeriod value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.MaxIssueSize:
      return {
        id: key,
        render: ({ value }) => {
          return <MaxIssueSize value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.IncrementAmountSize:
      return {
        id: key,
        render: ({ value }) => {
          return <IncrementAmountSize value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.MinInvestSize:
      return {
        id: key,
        render: ({ value }) => {
          return <MinInvestSize value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.BaseIssueSize:
      return {
        id: key,
        render: ({ value }) => {
          return <BaseIssueSize value={value} id={key} />
        },
      } as ColumnProps
    case DataKeys.NegativePledge:
      return {
        id: key,
        render: ({ value }) => {
          return <NegativePledge value={value} id={key} />
        },
      } as ColumnProps
    default:
      return {
        id: key,
        render: ({ value }) => {
          return <TickerSymbol value={value} id={key} />
        },
      } as ColumnProps
  }
})
