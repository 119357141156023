import { useMutation } from 'react-query'
import { postDealTokensTransferAdditional } from 'src/api/routes'

const postTransfer = ({
  dealId,
  body,
}: {
  dealId: number
  body: { additionalTransfers: { investorId: number; amount: number }[] }
}) =>
  postDealTokensTransferAdditional({
    dealId,
    body,
  })

export function useTransferAdditionalTokens() {
  return useMutation(postTransfer)
}
