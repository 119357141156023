import {
  ChevronDownIcon,
  SelectBase,
} from '@allinfra-ltd/allinfra-design-system'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Dot } from 'src/components'
import { useDealsCurrencies } from 'src/hooks'
import { currenciesMock } from 'src/mocks'

const Currencies = ({ value, id }: { value: string; id: string }) => {
  const { data: currencies, isLoading } = useDealsCurrencies()
  const { t } = useTranslation()
  const { control, getValues } = useFormContext()
  return currencies ? (
    <Controller
      control={control}
      name={id}
      rules={{
        required: t('deals.create.generalInfo.currencySelectError') as string,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <SelectBase
          placeholderLeadingIcon={<Dot size="large" />}
          placeholderLabel={(getValues(id) || value).toUpperCase()}
          placeholderTrailingIcon={<ChevronDownIcon />}
          onChange={(idx) => {
            onChange((currencies || currenciesMock)[idx].toUpperCase())
          }}
          selectedIndex={(currencies || currenciesMock).findIndex(
            (currency) => currency === (getValues(id) || value).toLowerCase()
          )}
          options={(currencies || currenciesMock).map((currency) => ({
            primaryLabel: t(`deals.currencies.${currency}`),
            leadingIcon: <Dot size="large" />,
            disabled: false,
          }))}
          error={error?.message}
          disabled={isLoading}
        />
      )}
    />
  ) : null
}

export default Currencies
