import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import {
  approvals,
  auth,
  deals,
  disclaimer,
  footer,
  input,
  invite,
  navigation,
  organizations,
  privacy,
  recover,
  shared,
  users,
} from './en'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          approvals,
          auth,
          navigation,
          users,
          organizations,
          input,
          invite,
          recover,
          deals,
          shared,
          footer,
          privacy,
          disclaimer,
        },
      },
    },
  })

export default i18n
