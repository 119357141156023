import { apiUrl } from 'src/api'
import {
  GetMultipleOrganizationInvitesQueryParams,
  GetOrganizationMembersQueryParams,
} from 'src/api/types'
import { Organization } from 'src/types'
import { InviteRequestBody } from 'src/types/invite'

import { api } from '../axios-base-instance'

export const postOrganizationInvite = (
  orgId: number,
  body: InviteRequestBody
) => api.post(apiUrl.organizationInvites(orgId), body)

export const deleteOrganizationInvite = ({
  orgId,
  inviteId,
}: {
  orgId: number
  inviteId: number
}) => api.delete(apiUrl.organizationInvite(orgId, inviteId))

export const getMyOrganization = () =>
  api.get<Organization>(apiUrl.myOrganization)

export const getMyOrganizationQuery = (queryString: string) =>
  api.get<Organization>(apiUrl.myOrganizationQuery(queryString))

export interface NewOrgPayload {
  name: string
  address: string
  website?: string
}

export const createSingleOrg = (body: NewOrgPayload) =>
  api.post<Organization>(apiUrl.organizations, body)

export interface EditOrgPayload extends NewOrgPayload {
  id: number
}

export const updateSingleOrg = ({ id, ...body }: EditOrgPayload) =>
  api.put<Organization>(apiUrl.organization(id), body)

export async function getMultipleOrganizationInvites({
  id,
  queryString,
}: GetMultipleOrganizationInvitesQueryParams) {
  const { data } = await api.get(
    apiUrl.organizationInvitesQuery({ id, queryString })
  )

  return data
}

export async function getOrganizationMembers({
  id,
  queryString,
}: GetOrganizationMembersQueryParams) {
  const { data } = await api.get(
    apiUrl.organizationMembersQuery({ id, queryString })
  )

  return data
}

export interface DeleteOrganizationMemberRequestProps {
  orgId: number
  userId: number
}

export const deleteOrganizationMember = ({
  orgId,
  userId,
}: DeleteOrganizationMemberRequestProps) =>
  api.delete(apiUrl.organizationMember(orgId, userId))
