export const input = {
  email: {
    label: 'Email',
    placeholder: 'Email address',
    required: 'Email required',
    patternMessage: 'Incorrect email address',
  },
  password: {
    label: 'Password',
    placeholder: 'Password',
    required: 'Please enter your password',
    patternMessage: 'Password cannot be empty',
    validationError: 'Check password tooltip requirements',
  },
  tooltipValidation: {
    passwordLabel: 'New password',
    passwordError: 'New Password related error',
  },
}
