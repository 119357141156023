import { useMutation, useQueryClient } from 'react-query'
import { queryKeys } from 'src/api/query-keys'
import { putUpdateDealMainSale } from 'src/api/routes'
import { CreateDealMainSale } from 'src/types'
import { RecursivePartial } from 'src/types/utils'

const requestUpdateMainSale = ({
  id,
  body,
}: {
  id: number
  body: RecursivePartial<CreateDealMainSale>
}) => putUpdateDealMainSale(id, body)

export const useUpdateMainSale = () => {
  const queryClient = useQueryClient()

  return useMutation(requestUpdateMainSale, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKeys.dealsList)
    },
  })
}
